import env from "../../config/env.json";
import { ScenarioType } from "./unit.ts";

export type ModifiedScenarioType = ScenarioType & {
    specific_competences_ids: string[],
    global_competences_ids: string[]
}
export const UpdateScenarioAPI = async (id: string, scenario: ScenarioType): Promise<ModifiedScenarioType> => {
    var req = await fetch(env.backend_host + "/scenario/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify(scenario),
        "mode": "cors",
        "credentials": "include"
    });
    var status = req.status;
    // TODO - handle errors??
    var data = req.json()
    return data
}

export const DeleteScenarioAPI = async (id: string): Promise<void> => {
    var req = await fetch(env.backend_host + "/scenario/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "DELETE",
        "mode": "cors",
        "credentials": "include"
    });
    var data = req.status;
}

export const CreateScenarioAPI = async (unit_id: string): Promise<ModifiedScenarioType> => {
    var req = await fetch(env.backend_host + "/scenario/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({ unit_id }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = req.json();

    return data
}

export const GetScenarioAPI = async (scenario_id: string): Promise<ModifiedScenarioType> => {
    var req = await fetch(env.backend_host + "/scenario/" + scenario_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    if (data.specfic_competences_ids === undefined && data.global_competences_ids === undefined) {
        data.specific_competences_ids = []
        data.global_competences_ids = []
    }


    return data
}