import env from "../../config/env.json";
import { ResearchProgressType } from './student_scenario'

export const CreateResearchProgressAPI = async (scenario_id: string): Promise<ResearchProgressType> => {
    var req = await fetch(env.backend_host + "/student_research/" + scenario_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });

    var data = await req.json();

    return data;
}