import "./ActivityProgress.css";
import { useAppSelector, useAppDispatch } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { motion } from "framer-motion";
import { BundleType } from "../../../../_newapios/user/class.ts";
import { StudentType } from "../../../../_newapios/user/student";
import Checkbox from "../../../../assets/professor/checkbox.svg";
import CheckboxNotChecked from "../../../../assets/professor/checkbox-intermediate.svg";
import { StudentScenarioPoints } from "../../../../_newapios/progress/student_course_points.ts";
import {
  selectedStudent,
  setEvaluationMenu,
  submenu,
  updateSelectedScenario,
} from "../../../../reducers/teacherSlice.ts";
import { setSelectedCourseId } from "../../../../reducers/mainSlice.ts";
import { ModifiedScenarioType } from "../../../../_newapios/content/scenario.ts";

const ActivityProgress = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector((state) => state.main);
  const students = mainState.students;
  const bundle: BundleType = props.bundle;
  const scenarios: ModifiedScenarioType[] = props.scenarios;
  const [scenarioPoints] = useState<StudentScenarioPoints>(
    props.scenarioPoints
  );
  const [isHovered, setIsHovered] = useState("");

  const showScenarioProgressHandler = (
    student: StudentType,
    scenario: ModifiedScenarioType
  ) => {
    dispatch(submenu("evaluation"));
    dispatch(setEvaluationMenu("scenarioInfo"));
    dispatch(selectedStudent(student));
    dispatch(setSelectedCourseId(props.courseId));
    dispatch(updateSelectedScenario(scenario.id));
  };

  const ActivityProgressTop = () => {
    return (
      <div className="professor_activity_progress_top">
        <div />
        <div className="center_container">
          <div className="professor_activity_progress_top_background_unit">
            <div className="text_black_super_small_bold center_container">
              {t("unit_programmed")}
            </div>
          </div>
        </div>
        <div
          className="professor_activity_progress_top_content"
          style={{
            gridTemplateColumns: "repeat(" + scenarios.length + ", 25%)",
          }}
        >
          {scenarios.map((scenario: ModifiedScenarioType, index: number) => {
            const scenarioImage = scenario.image.url;
            return (
              <div className="center_container">
                <div className="professor_activity_progress_top_background">
                  <div className=" center_container">
                    <img
                      className="professor_activity_progress_top_image"
                      src={scenarioImage}
                      alt="scenario_image"
                    />
                  </div>
                  <div className=" center_container">
                    <div className="text_black_super_small_bold_activity_progress">
                      {scenario.know_hows[0]}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStudentProgress = (student: StudentType) => {
    const BUNDLE_HAS_UNIT =
      bundle !== undefined && bundle.unit !== undefined && bundle.unit !== null;
    return (
      <motion.div
        className="professor_activity_progress_body_student"
        whileHover={{ boxShadow: "0px 0px 10px 0px #5090F080" }}
      >
        <div className="left_container">
          <div className="professor_credentials_menu_student_icon">
            <div className="center_container">
              <div className="text_white_small_bold">
                {student.username.charAt(0).toUpperCase()}
              </div>
            </div>
          </div>
          <div
            className="text_black_very_small_bold"
            style={{ marginLeft: "2%" }}
          >
            {student.username}
          </div>
        </div>
        <div className="center_container">
          <img
            className="image"
            src={BUNDLE_HAS_UNIT ? Checkbox : CheckboxNotChecked}
            alt="checkbox"
          />
        </div>
        <div
          className="professor_activity_progress_body_student_content"
          style={{
            gridTemplateColumns: "repeat(" + scenarios.length + ", 25%)",
          }}
        >
          {scenarios.map((scenario, index) => {
            return (
              <div className="center_container">
                <motion.div
                  className="professor_activity_progress_body_student_content_data"
                  whileHover={
                    BUNDLE_HAS_UNIT
                      ? { scale: 1.1, textShadow: "#5090F0 1px 0 10px" }
                      : {}
                  }
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 10,
                  }}
                  style={{
                    cursor: BUNDLE_HAS_UNIT
                      ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
                      : 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
                  }}
                  onClick={() => showScenarioProgressHandler(student, scenario)}
                >
                  <div className="text_black_small_bold center_container">
                    {BUNDLE_HAS_UNIT && scenarioPoints[student.id] !== undefined
                      ? (scenarioPoints[student.id][scenario.id] || 0) + "/100"
                      : "N/A"}
                  </div>
                </motion.div>
              </div>
            );
          })}
        </div>
      </motion.div>
    );
  };

  return (
    <div className="professor_activity_progress">
      <ActivityProgressTop />
      <div className="professor_activity_progress_body">
        {students?.map((student, index) => {
          return renderStudentProgress(student);
        })}
      </div>
    </div>
  );
};

export default ActivityProgress;
