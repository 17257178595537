import { useAppSelector } from "../../../../hooks/hooks.ts";
import { useTexture, useCursor, useGLTF } from "@react-three/drei";
import { MeshPhongMaterial, Color, SRGBColorSpace } from "three";
import { useEffect, useState } from "react";
import { GetStudentHouseAllAPI, StudentHouseAndUpgradeType } from "../../../../_newapios/progress/student_house.ts";
import { DreiGLTF } from "../../../../interfaces.ts";
import { convertToEuler, convertToVector3 } from "../../../../utils/R3F.ts";

const MejorasCasas = ({ ...props }) => {
  const studentState = useAppSelector((state) => state.student);

  const [workshopProgress, setWorkshopProgress] = useState<StudentHouseAndUpgradeType[]>([]);
  const challenge = studentState.challenge;

  useEffect(() => {
    loadProgress();
  }, [])

  const loadProgress = async () => {
    var studentId
    if (props.studentId != null) {
      studentId = props.studentId;
    } else {
      studentId = "me";
    }
    var temp_progress = await GetStudentHouseAllAPI(studentId);
    setWorkshopProgress(temp_progress);
  }

  const [hovered, hover] = useState(false);

  const hoverFunc = (isover: boolean) => {
    hover(isover);
  };

  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );

  const isChallenge1Open = () => {
    return challenge !== undefined
  };

  const challenge1Handler = (position: number) => {
    return props.houseMains(position);
  };

  const challengeMaterial = (position: number) => {
    const challenge1 = studentState.challengeBlackOut;
    if (isChallenge1Open()) {
      if (challenge1[position - 1] == 2) {
        return bakedAssetsMaterialChallBlue;
      } else {
        return bakedAssetsMaterialChallRed;
      }
    } else {
      return null;
    }
  };

  const challengeHouse = (position: number) => {
    const challenge1 = studentState.challengeBlackOut;
    if (isChallenge1Open()) {
      if (challenge1[position - 1] == 0) {
        return challenge1Handler(position);
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const bakedAssetsTexture1 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-new2.png"
  );
  const bakedAssetsTexture2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-specular.png"
  );
  var bakedAssetsTexture3 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-emission.png"
  );
  bakedAssetsTexture1.colorSpace = SRGBColorSpace;
  bakedAssetsTexture1.flipY = false;
  bakedAssetsTexture2.colorSpace = SRGBColorSpace;
  bakedAssetsTexture2.flipY = false;
  bakedAssetsTexture3.colorSpace = SRGBColorSpace;
  bakedAssetsTexture3.flipY = false;

  if (props.funkyMode) {
    bakedAssetsTexture3 = bakedAssetsTexture1;
  }

  const bakedAssetsMaterialChallBlue = new MeshPhongMaterial({
    map: bakedAssetsTexture1,
    emissive: new Color(0x57bdff),
    specularMap: bakedAssetsTexture2,
    emissiveIntensity: 0.15,
    specular: 0xffffff,
    fog: true,
  });

  const bakedAssetsMaterialChallRed = new MeshPhongMaterial({
    map: bakedAssetsTexture1,
    emissive: new Color(0xff4444),
    specularMap: bakedAssetsTexture2,
    emissiveIntensity: 0.25,
    specular: 0xffffff,
    fog: true,
  });

  return (
    <group {...props} dispose={null}>
      {workshopProgress.map((house) => {
        return (
          <LoadHouse 
            house={house} 
            renderShadow={() => props.renderShadow()} 
            challengeHouse={(position) => challengeHouse(position)} 
            challengeMaterial={(position) => challengeMaterial(position)}
            isChallenge1Open={() => isChallenge1Open()} 
            hoverFunc={(isover) => hoverFunc(isover)} 
          />
        )
      })}
    </group>
  );
};

export default MejorasCasas;


function LoadHouse(props: {house: StudentHouseAndUpgradeType, renderShadow: () => boolean, challengeHouse: (position: number) => void | null, challengeMaterial: (position: number) => MeshPhongMaterial | null, isChallenge1Open: () => boolean, hoverFunc: (isover: boolean) => void } ) {
  
  const houseGLTF = useGLTF(props.house.house_upgrade.asset.gltf.url) as DreiGLTF;

  const challengeMaterial = props.challengeMaterial(props.house.house_upgrade.position)

  return (
    <mesh
      castShadow={props.renderShadow()}
      receiveShadow={props.renderShadow()}
      geometry={houseGLTF.nodes[props.house.house_upgrade.asset.gltf.geometry[0]].geometry}
      material={challengeMaterial !== null ? challengeMaterial : houseGLTF.materials[props.house.house_upgrade.asset.gltf.material[0]]}
      position={convertToVector3(props.house.house_upgrade.asset.position)}
      rotation={convertToEuler(props.house.house_upgrade.asset.rotation)}
      scale={convertToVector3(props.house.house_upgrade.asset.scale)}
      onClick={() => props.challengeHouse(props.house.house_upgrade.position)}
      onPointerOver={props.isChallenge1Open() ? () => props.hoverFunc(true) : () => {}}
      onPointerOut={props.isChallenge1Open() ? () => props.hoverFunc(false) : () => {}}
    />
  )
}