import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TeacherType } from '../_newapios/user/teacher';
import { ClassType } from '../_newapios/user/class'
import { userLicenseType } from '../_newapios/user/user_license';
import { StudentType } from '../_newapios/user/student';
import { ModifiedScenarioType } from '../_newapios/content/scenario';

export interface TeacherState {
    sidebarOpen: boolean,
    fullName: string,
    isAdmin: boolean,
    userLicense: userLicenseType | null,
    menu: string,
    submenu: string,
    evaluationMenu: string,
    selectedStudent: StudentType | null,
    isStudentFree: boolean,
    classes: ClassType[],
    selectedClass: ClassType | null,
    selectedCourse: number,
    createClassStep: number,
    selectedCompetence: string | null,
    selectedScenario: string | null,
    availableThemes: any,
    authors: TeacherType[]
}

export const initialState: TeacherState = {
    sidebarOpen: true,
    fullName: "",
    isAdmin: false,
    userLicense: null,
    menu: 'welcome',
    submenu: 'activities',
    evaluationMenu: 'studentList',
    selectedStudent: null,
    isStudentFree: false,
    classes: [],
    selectedClass: null,
    selectedCourse: 0,
    createClassStep: 0,
    selectedCompetence: null,
    selectedScenario: null,
    availableThemes: null,
    authors: []
}

export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        sidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.sidebarOpen = action.payload
        },
        teacherInfo: (state, action: PayloadAction<TeacherType>) => {
            state.isAdmin = action.payload.admin
            state.fullName = action.payload.fullname
        },
        setLicense: (state, action: PayloadAction<userLicenseType>) => {
            state.userLicense = action.payload
        },
        menu: (state, action: PayloadAction<string>) => {
            state.menu = action.payload
        },
        submenu: (state, action: PayloadAction<string>) => {
            state.submenu = action.payload
        },
        setEvaluationMenu: (state, action: PayloadAction<string>) => {
            state.evaluationMenu = action.payload
        },
        selectedStudent: (state, action: PayloadAction<StudentType>) => {
            state.selectedStudent = action.payload
        },
        updateIsStudentFree: (state, action: PayloadAction<boolean>) => {
            state.isStudentFree = action.payload
        },
        classes: (state, action: PayloadAction<Array<ClassType>>) => {
            state.classes = action.payload
        },
        updateSelectedClass: (state, action: PayloadAction<ClassType | null>) => {
            state.selectedClass = action.payload
        },
        updateSelectedCourse: (state, action: PayloadAction<number>) => {
            state.selectedCourse = action.payload
        },
        changeStepClassCreate: (state, action: PayloadAction<number>) => {
            state.createClassStep = action.payload
        },
        selectedCompetence: (state, action: PayloadAction<string | null>) => {
            state.selectedCompetence = action.payload
        },
        updateSelectedScenario: (state, action: PayloadAction<string | null>) => {
            state.selectedScenario = action.payload
        },
        changeAvailableThemes: (state, action: PayloadAction<any>) => {
            state.availableThemes = action.payload
        },
        setAuthors: (state, action: PayloadAction<TeacherType[]>) => {
            state.authors = action.payload
        }
    },
});

export const { sidebarOpen, teacherInfo, setLicense, menu, submenu, setEvaluationMenu, selectedStudent, updateIsStudentFree, classes, updateSelectedClass, updateSelectedCourse, changeStepClassCreate, selectedCompetence, updateSelectedScenario, changeAvailableThemes, setAuthors } = teacherSlice.actions;

export default teacherSlice.reducer;