import "./ProfessorEvaluationStudentInfo.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import PDF from "../../exports/pdfExport";
import ExportExcel from "../../exports/excelExport.tsx";
import ProfessorEvaluationGeneralInfo from "./ProfessorEvaluationGeneralInfo";
import ProfessorEvaluationGlobalCompetences from "./ProfessorEvaluationGlobalCompetences";
import ProfessorEvaluationGlobalSubcompetences from "./ProfessorEvaluationGlobalSubcompetences.tsx";
import ProfessorEvaluationCurricularContent from "./ProfessorEvaluationCurricularContent";
import ProfessorEvaluationScenario from "./ProfessorEvaluationScenario";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { m, LazyMotion, color } from "framer-motion";

import { useEffect, useRef, useState } from "react";
import { classes, setEvaluationMenu } from "../../../../reducers/teacherSlice";
import BackArrow from "../../../../assets/exercises/back_arrow.tsx";
import Loading from "../../../loading_screen/LoadingDots.tsx";
import { ModifiedBundleType } from "../../../../_newapios/user/class.ts";
import { GetStudentBundlesAPI } from "../../../../_newapios/content/bundle.ts";
import {
  GetClassGlobalCompetencesAPI,
  GetStudentGlobalCompetencesAPI,
  GlobalCompetencePoints,
} from "../../../../_newapios/progress/student_global_competences.ts";
import {
  GetStudentCoursePointsAPI,
  GetStudentScenarioPointsAPI,
  StudentCoursePoints,
} from "../../../../_newapios/progress/student_course_points.ts";
import { StudentType } from "../../../../_newapios/user/student.ts";
import BasicDocument from "../../exports/pdfExport";
import logo from "../../../../assets/professor/Eutopia_Logo_FInal_2 1.png";
import _ from "lodash";
import {
  GetClassSpecificCompetencesAPI,
  GetStudentSpecificCompetencesAPI,
  SpecificCompetencePoints,
} from "../../../../_newapios/progress/student_specific_competences.ts";
import { CourseType } from "../../../../_newapios/content/course.ts";
import {
  GetUnitAPI,
  ModifiedUnitType,
} from "../../../../_newapios/content/unit.ts";
import RadarChart from "./RadarChart.tsx";
import { getGlobalCompetenceAggregates } from "../../../utils/evaluation.ts";
import { simpleGlobalCompetences } from "../../../../constants.ts";
import { Spinner } from "@chakra-ui/react";

const ProfessorEvaluationStudentInfo = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const professorState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);

  const student = professorState.selectedStudent as StudentType;
  const evaluationMenu = professorState.evaluationMenu;
  const selectedClass = professorState.selectedClass;
  const teacherMenu = professorState.submenu;
  const courses = mainState.courses;
  const regulations = mainState.regulations;

  // For the PDF
  const trueGlobalCompetences = mainState.globalCompetences;
  const restrictGlobalComp =
    !professorState.userLicense?.license.allow_global_competences;

  const [studentCourses, setStudentCourses] = useState<CourseType[]>([]);
  const [studentBundles, setStudentBundles] = useState<ModifiedBundleType[]>(
    []
  );

  // const [showPDFViewer, setShowPDFViewer] = useState<boolean>(false);

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [bundleStudentList, setBundleStudentList] = useState<
    ModifiedBundleType[]
  >([]);
  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [schoolCompetences, setSchoolCompetences] =
    useState<GlobalCompetencePoints>({});
  const [coursePoints, setCoursePoints] = useState<StudentCoursePoints>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [studentSpecificCompetences, setStudentSpecificCompetences] = useState<{
    [key: string]: SpecificCompetencePoints;
  }>({});

  const [classSpecificCompetences, setClassSpecificCompetences] = useState<{
    [key: string]: SpecificCompetencePoints;
  }>({});

  // For the PDF (Radar Charts)
  const chartRefs = useRef<(HTMLDivElement | null)[]>([]);
  const generalInfoRef = useRef<HTMLDivElement | null>(null);
  const [chartBase64s, setChartBase64s] = useState<{ [key: string]: string }>(
    {}
  );

  const [studentData, setStudentData] = useState<{ [key: string]: number[] }>(
    {}
  );
  const [classData, setClassData] = useState<{ [key: string]: number[] }>({});
  const [labels, setLabels] = useState<{ [key: string]: string[] }>({});
  const [unitData, setUnitData] = useState<ModifiedUnitType[]>([]); // State to store unit data

  // For PDF (scenarioPoints)
  const [scenarioPoints, setScenarioPoints] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    loadScenarioPoints();
    loadUnits(findStudentCourses(bundleStudentList));
  }, [bundleStudentList]);

  const getScenarioPoints = async (scenario_id: string) => {
    const newScenarioPoints = await GetStudentScenarioPointsAPI(
      student.id || "",
      scenario_id
    );
    return newScenarioPoints || 0;
  };

  const loadScenarioPoints = async () => {
    var newScenarioPoints: { [key: string]: number } = {};
    bundleStudentList.map((bundle) => {
      bundle.unit?.scenarios.map((scenario) => {
        getScenarioPoints(scenario.id).then((points) => {
          newScenarioPoints[bundle.id + "_" + scenario.id] = points;
        });
      });
    });
    setScenarioPoints(newScenarioPoints);
  };

  const findStudentCourses = (studentBundles: ModifiedBundleType[]) => {
    if (studentBundles.length > 0) {
      var newStudentCourses: CourseType[] = [];
      for (let i = 0; i < studentBundles.length; i++) {
        var newStudentCourse = courses.find(
          (course) => course.id === studentBundles[i].unit?.course_id
        );
        if (
          newStudentCourse !== undefined &&
          newStudentCourses.indexOf(newStudentCourse) === -1
        ) {
          newStudentCourses.push(newStudentCourse);
        }
      }
      setStudentCourses(newStudentCourses);
      return newStudentCourses;
    } else {
      return null;
    }
  };
  const loadSpecificCompetences = async (
    studentCourses: CourseType[] | null
  ) => {
    if (studentCourses === null) return;
    const updatedSpecificCompetences = { ...studentSpecificCompetences };

    await Promise.all(
      studentCourses.map(async (course) => {
        try {
          const studentSpComp = await GetStudentSpecificCompetencesAPI(
            student?.id || "",
            course.id
          );
          // Add the specific competences for this course to the updated object
          updatedSpecificCompetences[course.id] = studentSpComp;
        } catch (error) {
          console.error(
            `Error loading specific competences for course ${course.id}:`,
            error
          );
        }
      })
    );
    setStudentSpecificCompetences(updatedSpecificCompetences);
  };

  const loadClassSpecificCompetences = async (
    studentCourses: CourseType[] | null
  ) => {
    if (!studentCourses) return; // Check for null or undefined

    // Create a copy of the current specific competences
    const updatedSpecificCompetences = { ...classSpecificCompetences };

    try {
      await Promise.all(
        studentCourses.map(async (course) => {
          try {
            const classSpComp = await GetClassSpecificCompetencesAPI(
              selectedClass?.id || "",
              course.id
            );
            // Add the specific competences for this course to the updated object
            updatedSpecificCompetences[course.id] = classSpComp;
          } catch (error) {
            console.error(
              `Error loading specific competences for course ${course.id}:`,
              error
            );
          }
        })
      );
      // Update the state after all the specific competences are loaded
      setClassSpecificCompetences(updatedSpecificCompetences);
    } catch (error) {
      console.error("Error loading class-specific competences:", error);
    }
  };

  const fetchUnitData = async (unitId: string) => {
    try {
      const data = await GetUnitAPI(unitId);
      setUnitData((prevUnitData) => {
        console.log(prevUnitData);
        return [...prevUnitData, data];
      });
    } catch (error) {
      console.error("Error fetching unit data:", error);
    }
  };

  const loadUnits = (studentCourses: CourseType[] | null) => {
    if (studentCourses === null) return;
    studentCourses.forEach((course: CourseType) => {
      bundleStudentList
        .filter((bun) => bun.unit?.course_id === course.id)
        .forEach(async (studentBundle, i) => {
          const unitId = studentBundle.unit?.id;
          if (unitId) {
            await fetchUnitData(unitId);
          }
        });
      console.log("Acabando asignatura");
    });
  };
  // useEffect(() => {
  //   setLoading(true);

  //   const getBundleData = async () => {
  //     var bundle = await GetStudentBundlesAPI(student.id);
  //     setBundleStudentList(bundle);
  //     return bundle
  //   };

  //   var promises = [
  //     getBundleData().then((bun) => {
  //       console.log(bun)
  //       console.log(findStudentCourses(bun))
  //       loadSpecificCompetences(findStudentCourses(bun))

  //     }),
  //     //getStudentEvaluation(),
  //     GetStudentGlobalCompetencesAPI(student.id).then((chartStudentData) =>
  //       setGlobalCompetences(chartStudentData)
  //     ),
  //     GetClassGlobalCompetencesAPI(student.class_id).then((chartSchoolData) =>
  //       setSchoolCompetences(chartSchoolData)
  //     ),
  //     GetStudentCoursePointsAPI(student.id).then((course_points) =>
  //       setCoursePoints(course_points)
  //     ),
  //     // getTeacherClassCompetencesScore(student.class_id).then(
  //     //   (chartSchoolData) => schoolDataHandler(chartSchoolData)
  //     // ),

  //   ];

  //   Promise.all(promises).then(() => {
  //     setLoading(false);
  //   });
  // }, [student, teacherMenu]);

  useEffect(() => {
    setLoading(true);

    const getBundleData = async () => {
      var bundle = await GetStudentBundlesAPI(student.id);
      setBundleStudentList(bundle);
      return bundle;
    };

    const fetchData = async () => {
      try {
        // Fetch bundle data first
        const bundle = await getBundleData();

        // Once bundle data is fetched, proceed with other API calls
        const promises = [
          loadSpecificCompetences(findStudentCourses(bundle)),
          GetStudentGlobalCompetencesAPI(student.id).then((chartStudentData) =>
            setGlobalCompetences(chartStudentData)
          ),
          GetClassGlobalCompetencesAPI(student.class_id).then(
            (chartSchoolData) => setSchoolCompetences(chartSchoolData)
          ),
          GetStudentCoursePointsAPI(student.id).then((course_points) =>
            setCoursePoints(course_points)
          ),
          loadClassSpecificCompetences(findStudentCourses(bundle)),
          // Add other API calls here
        ];

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Once all promises are resolved, setLoading to false
        setLoading(false);
        console.log("Cargado");
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading state is set to false even if there's an error
      }
    };

    fetchData();
  }, [student, teacherMenu]);

  useEffect(() => {
    const extractChartUrls = () => {
      studentCourses.forEach((course, index) => {
        const radarLabels = course.specific_competences.map((sc) =>
          t(course.acronym + sc.number + "_label", { ns: "evaluation" })
        );
        const radarStudentData: number[] = !_.isEmpty(
          studentSpecificCompetences[course.id]
        )
          ? Object.values(studentSpecificCompetences[course.id])
          : _.fill(Array(labels.length), 0);
        const radarClassData: number[] = !_.isEmpty(
          classSpecificCompetences[course.id]
        )
          ? Object.values(classSpecificCompetences[course.id])
          : _.fill(Array(labels.length), 0);
        setStudentData((prevState) => ({
          ...prevState,
          [course.id]: radarStudentData,
        }));
        setClassData((prevState) => ({
          ...prevState,
          [course.id]: radarClassData,
        }));
        setLabels((prevState) => ({ ...prevState, [course.id]: radarLabels }));
        if (chartRefs.current[index]) {
          const chartElement = chartRefs.current[index]?.querySelector(
            ".radar_chart canvas"
          ) as HTMLCanvasElement;
          if (chartElement) {
            const chartUrl = chartElement.toDataURL("image/png");
            setChartBase64s((prevState) => ({
              ...prevState,
              [course.id]: chartUrl,
            }));
          } else {
            console.error(`Canvas element not found for course ${course.id}`);
          }
        } else {
          console.error(`Chart ref not found for index ${index}`);
        }
      });
    };
    extractChartUrls();

    if (generalInfoRef.current) {
      const generalInfoChartElement = generalInfoRef.current.querySelector(
        ".radar_chart canvas"
      ) as HTMLCanvasElement;
      console.log(generalInfoChartElement);
      if (generalInfoChartElement) {
        const generalInfoChartUrl =
          generalInfoChartElement.toDataURL("image/png");
        setChartBase64s((prevState) => ({
          ...prevState,
          generalInfo: generalInfoChartUrl,
        }));
      } else {
        console.error("Canvas element not found for general info radar chart");
      }
    }
  }, [
    studentCourses,
    studentSpecificCompetences,
    classSpecificCompetences,
    generalInfoRef.current,
    chartRefs.current,
  ]);

  const ArrayDivider = (originalArray: string[]): string[][] => {
    let dividedArrays: string[][] = [];

    let i: number;

    originalArray.forEach((item) => {
      var splitItem = item.split(" ");
      var charLength = 0;
      var arrayThatTurnsIntoString: string[] = [];
      var stringArray: string[] = [];
      for (i = 0; i < splitItem.length; i++) {
        charLength += splitItem[i].length;
        if (charLength > 10) {
          arrayThatTurnsIntoString.push(splitItem[i]);
          stringArray.push(arrayThatTurnsIntoString.join(" "));
          arrayThatTurnsIntoString = [];
          charLength = 0;
        } else {
          arrayThatTurnsIntoString.push(splitItem[i]);
          if (i + 1 === splitItem.length) {
            stringArray.push(arrayThatTurnsIntoString.join(" "));
          }
        }
      }
      dividedArrays.push(stringArray);
    });
    return dividedArrays;
  };

  const backHandler = () => {
    dispatch(setEvaluationMenu("studentList"));
  };

  // const handleOpenPDFViewer = () => {
  //   setShowPDFViewer(!showPDFViewer);
  // };

  const exportPDF = (dummyData: ModifiedUnitType[]) => {
    return (
      <PDFDownloadLink
        document={
          <BasicDocument
            student={student}
            selectedClass={selectedClass}
            globalCompetences={globalCompetences}
            schoolCompetences={schoolCompetences}
            studentCourses={studentCourses}
            studentBundles={bundleStudentList}
            courses={courses}
            regulations={regulations}
            studentSpecificCompetences={studentSpecificCompetences}
            classSpecificCompetences={classSpecificCompetences}
            radarChartUrl={chartBase64s}
            studentData={studentData}
            classData={classData}
            labels={labels}
            trueGlobalCompetences={trueGlobalCompetences}
            scenarioPoints={scenarioPoints}
            unitData={dummyData}
          />
        }
        fileName={t("evaluation") + student.username + ".pdf"}
      >
        <m.div
          className="professor_evaluation_student_info_pdf_button"
          // onClick={() => handleOpenPDFViewer()}
          whileHover={{
            scale: 1.1,
          }}
          transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
        >
          <div className="center_container">
            <img
              className="icon"
              src={
                "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/pdf.svg"
              }
              alt="pdf"
            />
          </div>
          <div className="center_container">
            <div className="text_black_super_small_bold">{t("export_pdf")}</div>
          </div>
        </m.div>
      </PDFDownloadLink>
    );
  };

  const renderTop = (dummyData: ModifiedUnitType[]) => {
    return (
      <div className="professor_evaluation_student_info_top">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_evaluation_student_info_top_left"
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => {
              backHandler();
            }}
          >
            <BackArrow color={"black"} />
            <div className="text_black_medium_bold">{student.username}</div>
          </m.div>

          {/* <m.div
            className="center_container"
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >

            {!loading &&

              (
                <MyPDFExport
                  student={student}
                  selectedClass={selectedClass}
                  globalCompetences={globalCompetences}
                  schoolCompetences={schoolCompetences}
                />
              )}
          </m.div> */}
          {restrictGlobalComp === false &&
            !loading &&
            Object.keys(chartBase64s).length === studentCourses.length + 1 &&
            bundleStudentList.length === dummyData.length ? (
            exportPDF(dummyData)
          ) : (
            <div className="center_container">
              <Spinner color="red.500" />
            </div>
          )}

          <m.div
            className="center_container"
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >
            {!loading && !restrictGlobalComp ? (
              <ExportExcel
                globalCompetences={globalCompetences}
                coursePoints={coursePoints}
                studentBundles={bundleStudentList}
                courseBundles={bundleStudentList.filter(
                  (bun) => bun.unit?.course_id === mainState.selectedCourseId
                )}
                studentSpecificCompetences={studentSpecificCompetences}
              />
            ) : (
              <div></div>
            )}
          </m.div>

          <div className="professor_evaluation_student_info_top_right">
            <div className="professor_evaluation_student_info_top_right_element">
              <div className="center_container">
                <div className="professor_evaluation_student_info_top_right_element_circle_blue" />
              </div>
              <div className="left_container">
                <div className="text_blue_super_small_bold">
                  {student.username}
                </div>
              </div>
            </div>
            <div className="professor_evaluation_student_info_top_right_element">
              <div className="center_container">
                <div className="professor_evaluation_student_info_top_right_element_circle_green" />
              </div>
              <div className="left_container">
                <div className="text_light_green_super_small_bold">
                  {t("center_mean")}
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderRadarChartSpComp = (course: CourseType, index: number) => {
    const labels = course.specific_competences.map((sc) =>
      t(course.acronym + sc.number + "_label", { ns: "evaluation" })
    );
    const studentDataSpComp: number[] = !_.isEmpty(
      studentSpecificCompetences[course.id]
    )
      ? Object.values(studentSpecificCompetences[course.id])
      : _.fill(Array(labels.length), 0);
    const classDataSpComp: number[] = !_.isEmpty(
      classSpecificCompetences[course.id]
    )
      ? Object.values(classSpecificCompetences[course.id])
      : _.fill(Array(labels.length), 0);

    let baselinedStudSpecComp: number[] = [];
    let baselinedClassSpecComp: number[] = [];

    studentDataSpComp.forEach((spComp) => {
      if (spComp > 750) {
        baselinedStudSpecComp.push(749);
      } else {
        baselinedStudSpecComp.push(spComp);
      }
    });

    classDataSpComp.forEach((spComp) => {
      if (spComp > 750) {
        baselinedClassSpecComp.push(749);
      } else {
        baselinedClassSpecComp.push(spComp);
      }
    });

    return (
      <div
        ref={(el) => (chartRefs.current[index] = el)}
        style={{
          position: "absolute",
          left: "-9999px",
          width: "500px",
          height: "400px",
        }} // Position off-screen
      >
        <RadarChart
          max={750}
          full={false}
          labels={ArrayDivider(labels)}
          studentData={baselinedStudSpecComp}
          schoolData={baselinedClassSpecComp}
          fontSize={11}
        />
      </div>
    );
  };

  const renderRadarChartGlobalCompetences = () => {
    const labelsGlobal = !_.isEmpty(globalCompetences)
      ? Object.keys(getGlobalCompetenceAggregates(globalCompetences)).map(
        (str) => t(str + "_label", { ns: "evaluation" })
      )
      : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_label", { ns: "evaluation" })
      );
    const studentDataGlobal = !_.isEmpty(globalCompetences)
      ? Object.values(getGlobalCompetenceAggregates(globalCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    const schoolDataGlobal = !_.isEmpty(schoolCompetences)
      ? Object.values(getGlobalCompetenceAggregates(schoolCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    let baselinedStudGlComp: number[] = [];
    let baselinedSchoolGlComp: number[] = [];

    studentDataGlobal.forEach((spComp) => {
      if (spComp > 12500) {
        baselinedStudGlComp.push(12499);
      } else {
        baselinedStudGlComp.push(spComp);
      }
    });

    schoolDataGlobal.forEach((spComp) => {
      if (spComp > 12500) {
        baselinedSchoolGlComp.push(12499);
      } else {
        baselinedSchoolGlComp.push(spComp);
      }
    });
    return (
      <div
        className="professor_evaluation_general_info_left_octagon"
        style={{
          position: "absolute",
          left: "-9999px",
          width: "400px",
          height: "400px",
        }}
        ref={generalInfoRef}
      >
        <RadarChart
          max={12500}
          full={false}
          labels={labelsGlobal}
          studentData={baselinedStudGlComp}
          schoolData={baselinedSchoolGlComp}
          fontSize={11}
        />
      </div>
    );
  };
  console.log(unitData);

  return (
    <div className="professor_evaluation_student_info">
      {renderTop(unitData)}
      {!loading && renderRadarChartGlobalCompetences()}
      {studentCourses.map((course: CourseType, index: number) => (
        <>{!loading && renderRadarChartSpComp(course, index)}</>
      ))}
      {
        <>
          {loading && <Loading />}
          {!loading && evaluationMenu === "generalInfo" && (
            <ProfessorEvaluationGeneralInfo
              globalCompetences={globalCompetences}
              schoolCompetences={schoolCompetences}
              coursePoints={coursePoints}
              studentBundles={bundleStudentList}
            />
          )}
          {!loading && evaluationMenu === "globalCompetences" && (
            <ProfessorEvaluationGlobalCompetences
              globalCompetences={globalCompetences}
              schoolCompetences={schoolCompetences}
            />
          )}
          {!loading && evaluationMenu === "globalSubcompetences" && (
            <ProfessorEvaluationGlobalSubcompetences
              studentBundles={bundleStudentList}
              globalCompetences={globalCompetences}
              schoolCompetences={schoolCompetences}
            />
          )}
          {!loading && evaluationMenu === "curricularContent" && (
            <ProfessorEvaluationCurricularContent
              courseBundles={bundleStudentList.filter(
                (bun) => bun.unit?.course_id === mainState.selectedCourseId
              )}
              coursePoints={coursePoints}
            />
          )}
          {!loading && evaluationMenu === "scenarioInfo" && (
            <ProfessorEvaluationScenario selectedUnits={bundleStudentList} />
          )}
        </>
      }
    </div>
  );
};
export default ProfessorEvaluationStudentInfo;
