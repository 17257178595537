import "./ProfessorCalendarMenu.css";
import "../class_creation/ClassCalendar.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Calendar12 from "../Calendar12/Calendar12.tsx";
import CalendarMonth from "../CalendarMonth/CalendarMonth.tsx";
import CalendarWeek from "../CalendarWeek/CalendarWeek.tsx";
import leftArrow from "../../../assets/professor/left_arrow_black.svg";
import rightArrow from "../../../assets/professor/right_arrow_black.svg";
import Loading from "../../loading_screen/LoadingDots.tsx";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import { LazyMotion, m, motion } from "framer-motion";
import { submenu } from "../../../reducers/teacherSlice";
import { Select } from "@chakra-ui/react";
import { colors } from "../../../constants.ts";
import Plus from "../../../assets/professor/plus.tsx";
import { menu } from "../../../reducers/teacherSlice.ts";
import ActivitiesIcon from "../../../assets/professor/activities_icon.tsx";
import {
  BundleType,
  GetClassBundlesAPI,
} from "../../../_newapios/user/class.ts";
import {
  GetAllUnitsAPI,
  ModifiedUnitType,
} from "../../../_newapios/content/unit.ts";
import { prettifyCourseAcronyms } from "../../../utils/competences.ts";

const ProfessorCalendarMenu = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const teacherState = useAppSelector((state) => state.teacher);

  const courses = useAppSelector((state) => state.main.courses);

  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<ModifiedUnitType[]>();
  const [bundles, setBundles] = useState<BundleType[]>();
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  useEffect(() => {
    if (teacherState.selectedClass === null) {
      return;
    }
    setLoading(true);
    const promises = [
      GetClassBundlesAPI(teacherState.selectedClass?.id).then((bundle) => {
        setBundles(bundle);
      }),
      GetAllUnitsAPI().then((units) => {
        setUnits(units);
      }),
    ];
    Promise.all(promises).then(() => setLoading(false));
  }, [teacherState.selectedClass, teacherState.submenu, teacherState.menu]);

  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedWeek, setSelectedWeek] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const [dropdownValue, setDropdownValue] = useState("year");
  const [hoveredDayBundles, setHoveredDayBundles] = useState<string[] | null>(
    null
  );

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "50%",
    },
  ];

  useEffect(() => {
    const today = new Date();
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
    setSelectedWeek(getFirstAndLastDayOfWeek());
  }, []);

  const getFirstAndLastDayOfWeek = () => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const diff =
      today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1); // Adjust when Sunday is the first day of the week
    const firstDay = new Date(today.setDate(diff));
    const lastDay = new Date(today.setDate(diff + 6));

    return [firstDay, lastDay];
  };

  const getFirstAndLastDayOfPreviousWeek = () => {
    const firstDay = selectedWeek[0];

    const previousWeekStart = new Date(firstDay);
    previousWeekStart.setDate(firstDay.getDate() - 7);

    const previousWeekEnd = new Date(firstDay);
    previousWeekEnd.setDate(firstDay.getDate() - 1);

    return [previousWeekStart, previousWeekEnd];
  };

  function getFirstAndLastDayOfNextWeek() {
    const firstDay = selectedWeek[0];

    firstDay.setDate(firstDay.getDate() + 7);

    const nextWeekFirstDay = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      firstDay.getDate() - firstDay.getDay() + 1
    );
    const nextWeekLastDay = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      firstDay.getDate() - firstDay.getDay() + 7
    );

    return [nextWeekFirstDay, nextWeekLastDay];
  }

  const getLastWeekOfMonth = (year: number, month: number) => {
    const lastDayOfMonth = new Date(year, month, 0);
    const offset = (6 - lastDayOfMonth.getDay() + 1 + 7) % 7;
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() + offset);
    const firstDay = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate() - 6
    );
    return [firstDay, lastDayOfMonth];
  };

  const getFirstWeekOfMonth = (year: number, month: number) => {
    const firstDayOfMonth = new Date(year, month, 1);
    const offset = (firstDayOfMonth.getDay() - 1 + 7) % 7;
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - offset);
    const lastDay = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      firstDayOfMonth.getDate() + 6
    );
    return [firstDayOfMonth, lastDay];
  };

  const goBackCalendarHandler = () => {
    if (dropdownValue === "year") {
      setSelectedWeek(getLastWeekOfMonth(selectedYear - 1, 11));
      setSelectedYear(selectedYear - 1);
      setSelectedMonth(11);
    } else if (dropdownValue === "month") {
      if (selectedMonth === 0) {
        setSelectedWeek(getLastWeekOfMonth(selectedYear - 1, 11));
        setSelectedYear(selectedYear - 1);
        setSelectedMonth(11);
      } else {
        setSelectedWeek(getLastWeekOfMonth(selectedYear, selectedMonth - 1));
        setSelectedMonth(selectedMonth - 1);
      }
    } else if (dropdownValue === "week") {
      if (selectedMonth === 0 && selectedWeek[0].getDate() - 7 <= 0) {
        setSelectedYear(selectedYear - 1);
        setSelectedMonth(11);
        setSelectedWeek(getFirstAndLastDayOfPreviousWeek());
      } else {
        if (selectedWeek[0].getDate() - 7 <= 0) {
          setSelectedMonth(selectedMonth - 1);
        }
        setSelectedWeek(getFirstAndLastDayOfPreviousWeek());
      }
    }
  };

  const goForwardCalendarHandler = () => {
    if (dropdownValue === "year") {
      setSelectedWeek(getFirstWeekOfMonth(selectedYear + 1, 0));
      setSelectedYear(selectedYear + 1);
      setSelectedMonth(0);
    } else if (dropdownValue === "month") {
      if (selectedMonth === 11) {
        setSelectedWeek(getFirstWeekOfMonth(selectedYear + 1, 0));
        setSelectedYear(selectedYear + 1);
        setSelectedMonth(0);
      } else {
        setSelectedWeek(getFirstWeekOfMonth(selectedYear, selectedMonth + 1));
        setSelectedMonth(selectedMonth + 1);
      }
    } else if (dropdownValue === "week") {
      if (
        selectedMonth === 11 &&
        selectedWeek[0].getDate() + 7 >
          new Date(selectedYear, selectedMonth + 1, 0).getDate()
      ) {
        setSelectedYear(selectedYear + 1);
        setSelectedMonth(0);
        setSelectedWeek(getFirstAndLastDayOfNextWeek());
      } else {
        if (
          selectedWeek[0].getDate() + 7 >
          new Date(selectedYear, selectedMonth + 1, 0).getDate()
        ) {
          setSelectedMonth(selectedMonth + 1);
        }
        setSelectedWeek(getFirstAndLastDayOfNextWeek());
      }
    }
  };

  const todayHandler = () => {
    const today = new Date();
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
    setSelectedWeek(getFirstAndLastDayOfWeek());
  };

  const dropdownHandler = (event: any) => {
    setDropdownValue(event.target.value);
    if (
      event.target.value === "week" &&
      getFirstWeekOfMonth(selectedYear, selectedMonth)[0].getMonth() !==
        getFirstWeekOfMonth(selectedYear, selectedMonth)[1].getMonth()
    ) {
      setSelectedMonth(selectedMonth - 1);
    }
    setSelectedWeek(getFirstWeekOfMonth(selectedYear, selectedMonth));
  };

  const getHoveredDayBundles = (bundlesInDay: string[] | null) => {
    setHoveredDayBundles(bundlesInDay);
  };

  const renderTop = () => {
    return (
      <div className="professor_calendar_menu_top">
        {renderTopLeft()}
        {renderTopCenter()}
        {renderTopRight()}
      </div>
    );
  };

  const renderTopLeft = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          whileHover={{
            scale: 1.1,
            boxShadow: "0px 0px 10px 2px rgba(110, 230, 167, 0.50)",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          className="professor_activities_menu_top_new"
          style={{
            whiteSpace: "nowrap",
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          onClick={() => dispatch(menu("marketplace"))}
        >
          <div className="center_container">
            <Plus color={"white"} />
            <div className="text_white_small" style={{ marginLeft: "3%" }}>
              {t("create_activity")}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderTopCenter = () => {
    return (
      <div className="professor_calendar_menu_top_center">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_calendar_menu_top_today"
            whileHover={{
              scale: 1.05,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 10,
            }}
            onClick={() => {
              todayHandler();
            }}
          >
            <div className="center_container">
              <div className="text_black_very_small_bold">{t("today")}</div>
            </div>
          </m.div>
        </LazyMotion>
        <LazyMotion features={loadFeatures}>
          <m.div
            className="center_container"
            whileHover={{
              scale: 1.3,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <img
              className="image"
              src={leftArrow}
              alt="left"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={() => goBackCalendarHandler()}
            />
          </m.div>
        </LazyMotion>
        <div className="center_container">
          <div className="text_black_very_small_bold">
            {dropdownValue === "year"
              ? selectedYear
              : dropdownValue === "month"
              ? months[selectedMonth]
              : months[selectedMonth] +
                " " +
                selectedWeek[0].getDate() +
                "-" +
                selectedWeek[1].getDate()}
          </div>
        </div>
        <LazyMotion features={loadFeatures}>
          <m.div
            className="center_container"
            whileHover={{
              scale: 1.3,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <img
              className="image"
              src={rightArrow}
              alt="right"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={() => goForwardCalendarHandler()}
            />
          </m.div>
        </LazyMotion>
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <Select
              className="professor_calendar_menu_select"
              size={"sm"}
              value={dropdownValue}
              onChange={dropdownHandler}
              as={motion.select}
              whileHover={{
                scale: 1.1,
                textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              }}
              whileTap={{
                scale: 1.1,
                textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              }}
              colorScheme="blue"
            >
              <option
                value="year"
                style={{
                  color: "#000000",
                  fontFamily: "Causten",
                  fontSize: 20,
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
              >
                {t("year")}
              </option>
              <option
                value="month"
                style={{
                  color: "#000000",
                  fontFamily: "Causten",
                  fontSize: 20,
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
              >
                {t("month")}
              </option>
              <option
                value="week"
                style={{
                  color: "#000000",
                  fontFamily: "Causten",
                  fontSize: 20,
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
              >
                {t("week")}
              </option>
            </Select>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderTopRight = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 0px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            whiteSpace: "nowrap",
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          className="professor_activities_menu_top_calendar"
          onClick={() => dispatch(submenu("activities"))}
        >
          <div className="center_container">
            <ActivitiesIcon color={"#5090F0"} size={20} />
            <div className="text_blue_small" style={{ marginLeft: "3%" }}>
              {t("activities_list")}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderCalendar = () => {
    return (
      <div
        className="professor_calendar_menu_calendar"
        style={{ overflowY: dropdownValue === "week" ? "visible" : "scroll" }}
      >
        {dropdownValue === "year" && (
          <Calendar12
            bundles={bundles}
            year={selectedYear}
            getHoveredDayBundles={getHoveredDayBundles}
          />
        )}
        {dropdownValue === "month" && (
          <CalendarMonth
            bundles={bundles}
            year={selectedYear}
            month={selectedMonth}
            getHoveredDayBundles={getHoveredDayBundles}
          />
        )}
        {dropdownValue === "week" && (
          <CalendarWeek
            bundles={bundles}
            year={selectedYear}
            month={selectedMonth}
            week={selectedWeek}
          />
        )}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="professor_calendar_menu_bottom">
        <div className="left_container">
          <div className="text_blue_very_small_bold">{t("class_topics")}</div>
        </div>
        {bundles === undefined ? (
          <div className="center_container">
            <div className="text_black_small_bold">
              {t("no_topics_created")}
            </div>
          </div>
        ) : (
          <div
            className="professor_calendar_menu_bottom_content"
            style={{
              gridTemplateRows:
                "repeat(" + Math.ceil(bundles.length / 3) + ", 30%",
            }}
          >
            {bundles.map((bundle, bundleIndex) => {
              const topicHighlighted = hoveredDayBundles?.includes(bundle.id);
              var unitCourse = courses.find(
                (course) => course.id === bundle.unit?.course_id
              );
              return (
                <motion.div
                  key={"top" + bundleIndex}
                  className="professor_calendar_menu_bottom_topic"
                  animate={
                    topicHighlighted
                      ? {
                          backgroundColor: unitCourse?.color + "75",
                          boxShadow:
                            "0px 0px 10px 0px " + unitCourse?.color + "75",
                        }
                      : {}
                  }
                >
                  <div
                    className="center_container"
                    style={{
                      backgroundColor: unitCourse?.color,
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <div className="text_white_very_small_bold">
                      {prettifyCourseAcronyms(unitCourse?.acronym)}
                    </div>
                  </div>
                  <div className="center_container">
                    <div className="text_black_very_small">
                      {bundle.unit?.name}
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="professor_calendar"
      style={loading ? { display: "flex" } : {}}
    >
      {loading && <Loading />}
      {!loading && (
        <div className="professor_calendar_menu">
          {renderTop()}
          <div className="professor_calendar_menu_main">
            {renderCalendar()}
            {renderBottom()}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfessorCalendarMenu;
