import env from "../../config/env.json";

export interface StudentCoursePoints {
    [key: string]: number;
}
export interface StudentScenarioPoints {
    [key: string]: StudentCoursePoints;
}

export const GetStudentCoursePointsAPI = async (id: string): Promise<StudentCoursePoints> => {
    var req = await fetch(env.backend_host + "/student/" + id + "/course_points/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetStudentScenarioPointsAPI = async (id: string, scenario_id: string): Promise<number> => {
    var req = await fetch(env.backend_host + "/student/" + id + "/scenario_points/" + scenario_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = JSON.parse(data) || null
    return data;
}