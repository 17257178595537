import env from "../../config/env.json";

export interface ClassCO2Type {
    class_co2: number;
    class_size: number;
}




export const GetStudentPointsAPI = async (student_id: string): Promise<number> => {
    var req = await fetch(env.backend_host + "/student_game_metrics/points/" + student_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    
    data = data || null
    if (data == null) {
        return 0;
    }
    return data;
}

export const GetStudentWattsAPI = async (student_id: string): Promise<number> => {
    var req = await fetch(env.backend_host + "/student_game_metrics/watts/" + student_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetStudentCO2API = async (student_id: string): Promise<number> => {
    var req = await fetch(env.backend_host + "/student_game_metrics/co2/" + student_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetStudentPopulationAPI = async (student_id: string): Promise<number> => {
    var req = await fetch(env.backend_host + "/student_game_metrics/population/" + student_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetClassCO2API = async (student_id: string): Promise<ClassCO2Type> => {
    var req = await fetch(env.backend_host + "/student_game_metrics/class_co2/" + student_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}