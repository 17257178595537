import env from "../../config/env.json";
import { StudentType } from "./student";
import { TeacherType } from "./teacher";

export interface UserType {
    id: string
    username: string
    password: string
    type: "teacher" | "student"
    verification_code?: string | null
}

export const GetMyUserAPI = async (): Promise<StudentType & { type: 'student' } | TeacherType & { type: 'teacher' } | 404 | 409> => {
    var req = await fetch(env.backend_host + "/me", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    if (req.status === 404) return req.status
    if (req.status === 409) return req.status

    var data = await req.json();
    return data;
}
