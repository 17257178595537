import "./ExercisesExperiment.css";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import _ from "lodash";
import ExercisesBar from "./ExercisesExperimentBar";
import Theory from "./Templates/Theory";
import DragDropList from "./Templates/DragDropList";
import DragDropTwoLists from "./Templates/DragDropTwoLists";
import TrueFalse from "./Templates/TrueFalse";
import DragDropOptions from "./Templates/DragDropOptions";
import Definition from "./Templates/Definition";
import ChooseOne from "./Templates/ChooseOne";
import ChooseMany from "./Templates/ChooseMany";
import WriteAnswers from "./Templates/WriteAnswers";
import FillInTheGaps from "./Templates/FillInTheGaps";
import DragDropArrows from "./Templates/DragDropArrows";
import { Idirection } from "../../interfaces";
import { ExerciseType } from "../../_newapios/content/unit";
import { ExperimentProgressType } from "../../_newapios/progress/student_scenario";
import Media from "./Templates/Media";
import { CreateExperimentProgressAPI } from "../../_newapios/progress/student_experiment";
import blueBackground from "../../assets/exercises/bluebackgroundexercise.svg";
import useSound from "use-sound";

export interface ExerciseAnswerType {
  exercise_id: string;
  answer: string[];
  correct: boolean;
  time: number;
  hint: boolean;
  attempts: number;
}

const ExercisesExperiment = (props: {
  exercises: ExerciseType[];
  progress: ExperimentProgressType;
  updateMaxLevelExperiment: () => void;
  //   updateCompletedExperiment: () => {
  //     payload: ExperimentType | null;
  //     type: "main/setExperiment";
  // }
  backHandler: () => void;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const loginState = useAppSelector((state) => state.login);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const exercises: ExerciseType[] = props.exercises;

  const [maxExercise, setMaxExercise] = useState(0);

  const [selectedExercise, setSelectedExercise] = useState(0);

  const [exerciseAnswers, setExerciseAnswers] = useState<ExerciseAnswerType[]>(
    []
  );

  const [tips, setTips] = useState(0);

  const [amogus] = useSound("./amongas.mp3", {
    volume: mainState.volumeSounds,
  });

  const [perfectExperiment] = useSound("./Perfect+experiment+-+01.wav", {
    volume: mainState.volumeSounds,
  });
  const [rightAnswer] = useSound("./Correct+-+01.wav", {
    volume: mainState.volumeSounds,
  });
  const [wrongAnswer] = useSound("./wrong+-+01.wav", {
    volume: mainState.volumeSounds,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const updateCompletedExperiment = async (
    newExerciseAnswers: ExerciseAnswerType[]
  ) => {
    if (!_.isNull(mainState.experimentExercises)) {
      const scenarioId = mainState.experimentExercises.scenario_id;
      CreateExperimentProgressAPI(scenarioId, newExerciseAnswers).then(
        (res) => {
          console.log(res);
          props.backHandler();
        }
      );
    }
  };

  const tipHandler = () => {
    setTips(tips + 1);
    if (tips > 10) {
      amogus();
    }
  };

  const updateLevel = (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => {
    if (direction === "left") {
      console.log("🤣🤣🤣");
      // setSelectedExercise(selectedExercise - 1);
      return;
    }

    if (direction === "right") {
      if (!_.isNull(correct) && !_.isNull(answer)) {
        const newExerciseAnswers = [...exerciseAnswers];
        const newAnswer = {
          exercise_id: exercises[selectedExercise].id,
          answer: answer,
          correct: correct,
          time: 0,
          hint: tips > 0,
          attempts: 1,
        };
        newExerciseAnswers.push(newAnswer);
        setExerciseAnswers(newExerciseAnswers);
        if (selectedExercise + 1 === exercises.length) {
          updateCompletedExperiment(newExerciseAnswers);
          return;
        }
        const sum = exerciseAnswers.reduce((acc, ans) => {
          var point = ans.correct ? 1 : 0;
          return acc + point;
        }, 0);
        if (sum === 4) {
          perfectExperiment();
        } else {
          if (correct) {
            rightAnswer();
          } else {
            wrongAnswer();
          }
        }
      }
      setSelectedExercise(selectedExercise + 1);
      setMaxExercise(maxExercise + 1);
      return;
    }
  };

  // const updateLevelBar = (level: number) => {
  //   setSelectedLevel(level);
  // };

  if (exercises == null) {
    return <div className="exercises_experiment"></div>;
  }

  return (
    <div className="exercises_experiment">
      <ExercisesBar
        exerciseAnswers={exerciseAnswers}
        exercises={exercises}
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevelBar={setSelectedExercise}
        backHandler={() => props.backHandler()}
      />

      {exercises.length === 0 && (
        <img
          className="background"
          src={blueBackground}
          alt="background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
            zIndex: -1,
          }}
        />
      )}
      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "theory" && (
          <Theory
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            research={false}
            maxExercise={maxExercise}
            // completed={
            //   correctExercises.filter(
            //     (correct, correctIndex) => correct === true
            //   ).length >= 1
            // }
            updateLevel={updateLevel}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "dragdroplist" && (
          <DragDropList
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            research={false}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() ===
          "dragdroptwolists" && (
          <DragDropTwoLists
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "dragdroparrows" && (
          <DragDropArrows
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={false}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "truefalse" && (
          <TrueFalse
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() ===
          "dragdropoptions" && (
          <DragDropOptions
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "definition" && (
          <Definition
            exercise={exercises[selectedExercise]}
            researchProgress={null}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            updateLevel={updateLevel}
            backHandler={() => props.backHandler()}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "chooseone" && (
          <ChooseOne
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "choosemany" && (
          <ChooseMany
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "writeanswers" && (
          <WriteAnswers
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={false}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}

      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "fillinthegaps" && (
          <FillInTheGaps
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            rightAnswerSound={rightAnswer}
            wrongAnswerSound={wrongAnswer}
            research={false}
            updateLevel={updateLevel}
            incorrectHandler={() => {}}
            tipHandler={tipHandler}
          />
        )}
      {exercises.length > 0 &&
        exercises[selectedExercise].type.toLowerCase() === "media" && (
          <Media
            exercise={exercises[selectedExercise]}
            totalExercises={exercises.length}
            exerciseIndex={selectedExercise}
            maxExercise={maxExercise}
            research={false}
            updateLevel={updateLevel}
          />
        )}
    </div>
  );
};

export default ExercisesExperiment;
