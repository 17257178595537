import "./Theory.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import greenBackground from "../../../assets/exercises/greenbackgroundexercise.svg";
import { Idirection } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";

const Theory = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  maxExercise: number;
  exerciseIndex: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
}) => {
  const { i18n } = useTranslation();

  const text = props.exercise.text;
  const totalExercises = props.totalExercises;
  const research = props.research;
  const selectedExercise = props.exerciseIndex;
  const maxExercise = props.maxExercise;

  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })

  //   lottie.loadAnimation({
  //     container: greenAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: greenBackground
  //   })
  // }, [props.selectedPage])

  const arrowsHandler = (direction: Idirection) => {
    if (selectedExercise < maxExercise) {
      props.updateLevel(direction, null, null);
    } else {
      props.updateLevel(direction, [""], true);
    }
  };

  const renderProfessor = () => {
    return (
      <div className="theory_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="theory_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text?.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="theory_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{
            scale: selectedExercise === 0 ? 1 : 1.3,
          }}
          style={
            research
              ? {
                  cursor:
                    selectedExercise === 0
                      ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                      : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }
              : {
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
                }
          }
        >
          <img
            className="icon"
            src={
              research
                ? selectedExercise === 0
                  ? leftTransparent
                  : left
                : leftTransparent
            }
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (research) {
              if (selectedExercise === totalExercises - 1) {
              } else {
                arrowsHandler("right");
              }
            } else {
              arrowsHandler("right");
            }
          }}
          whileHover={{ scale: 1.3 }}
          style={
            research
              ? {
                  cursor:
                    selectedExercise === totalExercises - 1
                      ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                      : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }
              : {
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }
          }
        >
          <img
            className="icon"
            src={
              research
                ? selectedExercise === totalExercises - 1
                  ? rightTransparent
                  : right
                : right
            }
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  return (
    <div className="theory">
      <img className="background" src={blueBackground} alt="background" />
      {renderProfessor()}
      {renderTextBox()}
      {research && renderArrows()}
    </div>
  );
};

export default Theory;
