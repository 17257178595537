import "./ProfessorCredentialsMenu.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DeleteStudentAPI,
  StudentType,
} from "../../../_newapios/user/student.ts";
import greenCredential from "../../../assets/professor/greenCredential.svg";
import blueCredential from "../../../assets/professor/blueCredential.svg";
import redCredential from "../../../assets/professor/redCredential.svg";
import yellowCredential from "../../../assets/professor/yellowCredential.svg";
import purpleCredential from "../../../assets/professor/purpleCredential.svg";
import brownCredential from "../../../assets/professor/brownCredential.svg";
import questionCredential from "../../../assets/professor/questionCredential.svg";
import personAddButton from "../../../assets/professor/person_add.svg";
import adminIcon from "../../../assets/professor/admin_icon.svg";
import GroupAddButton from "../../../assets/professor/studentAddGroup.tsx";
import eye from "../../../assets/professor/eye.svg";
import eyeClosed from "../../../assets/professor/eye-closed.svg";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import VerticalDots from "../../../assets/professor/ellypsis-vertical.tsx";
import _ from "lodash";
import BackCross from "../../../assets/professor/close.tsx";
import close from "../../../assets/student/close_user_menu.svg";
import { setStudents } from "../../../reducers/mainSlice.ts";
import {
  DeleteTeacherInClassAPI,
  GetClassStudentsAPI,
  GetClassTeachersAPI,
  ModifyTeachersInClassAPI,
} from "../../../_newapios/user/class.ts";
import Alert from "../../alerts/Alert.tsx";
import {
  CreateGroupAPI,
  DeleteGroupAPI,
  GetAllGroupsAPI,
  GroupType,
  UpdateGroupAPI,
} from "../../../_newapios/user/group.ts";
import Loading from "../../loading_screen/LoadingDots.tsx";
import { setGroupsFunc } from "../../../utils/group.ts";
import { TeacherType } from "../../../_newapios/user/teacher.ts";
import { GetTeachersInSchoolAPI } from "../../../_newapios/user/school.ts";

const ProfessorCredentialsMenu = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const selectedClass = teacherState.selectedClass;
  const students = mainState.students;
  const [teachersInClass, setTeachersInClass] = useState<TeacherType[]>([]);
  const [teachersInSchool, setTeachersInSchool] = useState<TeacherType[]>([]);
  const [isTeacherMenuOpen, setIsTeacherMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCredentials, setShowCredentials] = useState(
    useMemo(() => new Set(), [])
  );
  const [selectedTab, setSelectedTab] = useState(0); // 0 for students, 1 for teachers
  const [deleteMenu, setDeleteMenu] = useState<StudentType | null>(null);
  const [isGroupsMenuOpen, setIsGroupsMenuOpen] = useState<number>(0);
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null); // groupId from which we want to delete the student
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>([]);

  const [groups, setGroups] = useState<GroupType[]>([]);

  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    loadGroupsData();
  }, [selectedClass, students]);

  useEffect(() => {
    const loadTeachersInSchool = async () => {
      const teachers = await GetTeachersInSchoolAPI();
      if (teachers === null) {
        setTeachersInSchool([]);
      } else {
        setTeachersInSchool(teachers);
      }
    };
    loadTeachersInSchool();
  }, []);

  useEffect(() => {
    loadUsersData();
  }, [selectedClass]);

  const loadUsersData = async () => {
    if (!selectedClass) return;

    let newTeachers = await GetClassTeachersAPI(selectedClass.id);
    if (newTeachers === null) {
      newTeachers = [];
    }
    setTeachersInClass(newTeachers);
    setSelectedTeachers(newTeachers.map((teacher) => teacher.id));

    let newStudents = await GetClassStudentsAPI(selectedClass.id);
    if (newStudents === null) {
      newStudents = [];
    }
    dispatch(setStudents(newStudents));
  };

  console.log(teachersInSchool);

  const loadGroupsData = async () => {
    if (!selectedClass) return;
    setLoading(true);
    const promises = [
      GetAllGroupsAPI(selectedClass.id).then((data) =>
        setGroupsFunc(students, selectedClass.id, data, setGroups)
      ),
    ];
    Promise.all(promises).then(() => setLoading(false));
  };

  const getImage = (color: string) => {
    switch (parseInt(color)) {
      case 3:
        return greenCredential;
      case 4:
        return blueCredential;
      case 0:
        return redCredential;
      case 2:
        return yellowCredential;
      case 5:
        return purpleCredential;
      case 1:
        return brownCredential;
      default:
        return brownCredential;
    }
  };

  const deleteMenuHandler = (
    student: StudentType | null = null,
    group: GroupType | null = null
  ) => {
    if (!teacherState.isAdmin) {
      alert(t("only_admin_can_delete"));
      return;
    }
    setDeleteMenu(student);
    setSelectedGroup(group);
  };

  const credentialsHandler = (studentId: string) => {
    if (showCredentials.has(studentId)) {
      showCredentials.delete(studentId);
    } else {
      showCredentials.add(studentId);
    }
    setShowCredentials(new Set(showCredentials));
  };

  const deleteTeacherHandler = async (teacher: TeacherType) => {
    await DeleteTeacherInClassAPI(selectedClass?.id || "", teacher.id);
    loadUsersData();
  };

  const deleteStudentHandler = async (student: StudentType) => {
    await DeleteStudentAPI(student.id);
    loadUsersData();
  };

  const openTeacherMenuHandler = () => {
    setIsTeacherMenuOpen(!isTeacherMenuOpen);
  };

  const modifyTeachersHandler = async () => {
    await ModifyTeachersInClassAPI(selectedClass?.id || "", selectedTeachers);
    loadUsersData();
    openTeacherMenuHandler();
  };

  const openGroupsButtonHandler = (groupsMenu: number) => {
    if (groupsMenu === 0) {
      setSelectedGroup(null);
      setGroupName("");
      setSelectedStudents([]);
    }
    setIsGroupsMenuOpen(groupsMenu);
  };

  const handleStudentCheckboxChange = (studentId: string) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleTeacherCheckboxChange = (teacher: TeacherType) => {
    if (teacher.admin) return;
    setSelectedTeachers((prevSelected) =>
      prevSelected.includes(teacher.id)
        ? prevSelected.filter((id) => id !== teacher.id)
        : [...prevSelected, teacher.id]
    );
  };

  const groupNameHandler = (event: any) => {
    setGroupName(event.target.value);
  };

  const addGroupHandler = () => {
    if (groupName.trim() === "" || selectedStudents.length === 0) {
      handleAlert();
      return;
    }

    const newName = groupName;
    const newStudents = selectedStudents;
    const class_id = selectedClass?.id || "";
    CreateGroupAPI(newName, class_id, newStudents).then((data) => {
      if (data === null) {
        alert("Error al crear el grupo");
        return;
      }
      setGroups((prevGroups) => [...prevGroups, data]);
      openGroupsButtonHandler(0); // to close the window
    });
  };

  const handleAlert = () => {
    setShowAlert(!showAlert);
  };

  const deleteGroupHandler = async (group: GroupType) => {
    await DeleteGroupAPI(group.id);
    loadGroupsData();
  };

  const modifyGroupHandler = async () => {
    if (groupName.trim() === "" || selectedStudents.length === 0) {
      handleAlert();
      return;
    }

    const group = selectedGroup;

    if (!group || selectedClass === null) return;

    await UpdateGroupAPI(
      group.id,
      selectedClass.id,
      groupName,
      selectedStudents
    );
    // TODO handle alert delete student from group
    loadGroupsData();
    openGroupsButtonHandler(0);
  };

  const openModifymenu = (group: GroupType) => {
    const students = group.students.map((st) => st.id);
    setIsGroupsMenuOpen(2);
    setSelectedGroup(group);
    setGroupName(group.name);
    setSelectedStudents(students);
  };

  const renderStudent = (student: StudentType, group: GroupType) => {
    return (
      <div className="professor_credentials_menu_student" key={student.id}>
        {renderStudentIcon(student)}
        {renderStudentName(student)}
        {renderShowCredentials(student)}
        {renderCredentials(student)}
        {group.id === "group1" && renderOptionsButton(student, group)}
      </div>
    );
  };

  const renderStudentIcon = (student: StudentType) => {
    return (
      <div className="center_container">
        <div className="professor_credentials_menu_student_icon">
          <div className="center_container">
            <div className="text_white_small_bold">
              {student.username.charAt(0).toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStudentName = (student: StudentType) => {
    return (
      <div className="left_container">
        <div className="text_black_small">{student.username}</div>
      </div>
    );
  };

  const renderShowCredentials = (student: StudentType) => {
    return (
      <div
        onMouseDown={() => credentialsHandler(student.id)}
        onMouseUp={() => credentialsHandler(student.id)}
        // For tablets and mobiles
        onTouchStart={() => credentialsHandler(student.id)}
        onTouchEnd={() => credentialsHandler(student.id)}
      >
        <div className="professor_credentials_menu_show_credentials_text">
          <div className="center_container">
            <motion.div
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 0px 10px 2px #6ee6a7",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="professor_credentials_menu_show_credentials_text_button"
              style={{
                backgroundColor: showCredentials.has(student.id)
                  ? "#6ee6a7"
                  : "#ffffff",
                border: "#6ee6a7 solid 2px",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <img
                className="icon"
                src={showCredentials.has(student.id) ? eye : eyeClosed}
                alt="eye_closed"
                style={{ maxWidth: "25px" }}
              />
              <div style={{ minWidth: "7px" }} />
              <div className="center_container">
                <div
                  className={
                    showCredentials.has(student.id)
                      ? "text_white_very_small"
                      : "text_light_green_very_small"
                  }
                >
                  {showCredentials.has(student.id)
                    ? t("password_shown")
                    : t("show_password")}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  const renderCredentials = (student: StudentType) => {
    return (
      <div
        onMouseDown={() => credentialsHandler(student.id)}
        onMouseUp={() => credentialsHandler(student.id)}
      >
        {showCredentials.has(student.id) && (
          <div className="professor_credentials_menu_student_credentials">
            {student.password.split("").map((credential, credentialIndex) => {
              return (
                <div className="center_container" key={credentialIndex}>
                  <img
                    className="image"
                    src={getImage(credential)}
                    alt="credential"
                  />
                </div>
              );
            })}
          </div>
        )}
        {!showCredentials.has(student.id) && (
          <div className="professor_credentials_menu_show_credentials_button">
            {[1, 1, 1, 1, 1, 1].map((value, index) => {
              return (
                <div className="center_container" key={index}>
                  <img
                    className="image"
                    src={questionCredential}
                    alt="credential"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderOptionsButtonTeacher = (teacher: TeacherType) => {
    return (
      <div style={{ position: "absolute", right: "0%" }}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<VerticalDots color={"#B8B8B8"} />}
            variant="none"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          />
          <Portal>
            <MenuList>
              <MenuItem
                icon={<EditIcon />}
                isDisabled={teacher.admin}
                style={{
                  fontFamily: "Causten",
                  cursor: teacher.admin
                    ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                    : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => deleteTeacherHandler(teacher)}
              >
                {t("delete_teacher_from_class")}
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </div>
    );
  };

  const renderOptionsButton = (student: StudentType, group: GroupType) => {
    return (
      <div className="center_container">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<VerticalDots color={"#B8B8B8"} />}
            variant="none"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          />
          <Portal>
            <MenuList>
              <MenuItem
                icon={<DeleteIcon />}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => deleteMenuHandler(student)}
              >
                {t("delete_student")}
              </MenuItem>

              {/* <MenuItem
                icon={<DeleteIcon />}
                style={{
                  fontFamily: "Causten",
                  backgroundColor: group.id === "group1" ? "#e8e8e8" : "white",
                  cursor:
                    group.id === "group1"
                      ? "not-allowed"
                      : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={
                  group.id === "group1"
                    ? () => {}
                    : () => deleteMenuHandler(student, group)
                }
              >
                {t("Eliminar de este grupo")}
              </MenuItem> */}
            </MenuList>
          </Portal>
        </Menu>
      </div>
    );
  };

  const renderOptionsButtonGroup = (group: GroupType) => {
    return (
      <div
        style={{
          position: "relative",
          height: "10vh",
          width: "1vw",
          marginLeft: "0.5%",
          marginTop: "0.5vh",
        }}
      >
        <div className="center_container">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<VerticalDots color={"#B8B8B8"} size={"4vh"} />}
              variant="none"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            />
            <Portal>
              <MenuList>
                <MenuItem
                  icon={<DeleteIcon />}
                  _hover={{ backgroundColor: "#f0f0f0" }}
                  isDisabled={group.id === "group1"}
                  style={{
                    fontFamily: "Causten",
                    backgroundColor: "none",

                    cursor:
                      group.id === "group1"
                        ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                        : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => deleteMenuHandler(null, group)}
                >
                  {t("delete_group_options")}
                </MenuItem>

                <MenuItem
                  icon={<EditIcon />}
                  _hover={{ backgroundColor: "#f0f0f0" }}
                  isDisabled={group.id === "group1"}
                  style={{
                    fontFamily: "Causten",
                    backgroundColor: "none",

                    cursor:
                      group.id === "group1"
                        ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                        : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    openModifymenu(group);
                  }}
                >
                  {t("modify_group_options")}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </div>
      </div>
    );
  };

  const deleteStudentFromGroup = async (
    student: StudentType,
    group: GroupType
  ) => {
    const newStudents = group.students.filter((st) => st.id !== student.id);

    // API call
  };

  const renderDeleteMenu = (
    student: StudentType | null,
    group: GroupType | null,
    action: () => void,
    deleteStudentText: string
  ) => {
    const text = `${deleteStudentText} ${student ? student.username : ""} ${group ? t(group.name) : ""
      }?`;

    return (
      <div
        className="professor_credentials_menu_delete_menu_background"
        onClick={() => deleteMenuHandler()}
      >
        <div className="center_container">
          <div className="professor_credentials_menu_delete_menu_container">
            <div className="professor_credentials_menu_delete_menu">
              <div className="professor_credentials_menu_delete_menu_text">
                <div className="left_container">
                  <div className="text_black_medium">
                    {deleteStudentText} <b>{student ? student.username : ""}</b>
                    ?
                  </div>
                </div>
              </div>
              <div
                className="professor_credentials_menu_delete_menu_close"
                onClick={() => deleteMenuHandler()}
              >
                <motion.img
                  className="icon"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.1, rotate: 180 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                />
              </div>

              <div className="professor_credentials_menu_delete_menu_buttons">
                <motion.div
                  className="professor_credentials_menu_delete_menu_button_yes"
                  whileHover={{ scale: 1.1 }}
                  // onClick={() => deleteStudentHandler(student)}
                  onClick={action}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("yes")}</div>
                  </div>
                </motion.div>

                <motion.div
                  className="professor_credentials_menu_delete_menu_button_no"
                  whileHover={{ scale: 1.1 }}
                  onClick={() => deleteMenuHandler()}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("no")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTeacher = (teacher: TeacherType) => {
    return (
      <div className="professor_credentials_menu_teacher" key={teacher.id}>
        {renderTeacherIcon(teacher)}
        <div className="text_black_small">{teacher.fullname}</div>
        {renderOptionsButtonTeacher(teacher)}
      </div>
    );
  };

  const renderTeacherClosed = (teacher: TeacherType) => {
    return (
      <div className="professor_credentials_menu_teacher" key={teacher.id}>
        {renderTeacherIcon(teacher)}
        <div className="text_black_super_small">{teacher.fullname}</div>
      </div>
    );
  };

  const renderTeacherIcon = (teacher: TeacherType) => {
    return (
      <div>
        {teacher.admin ? (
          <img
            className="image"
            src={adminIcon}
            alt="adminIcon"
            style={{
              maxHeight: "4vh",
            }}
          />
        ) : (
          <div className="professor_credentials_menu_teacher_icon">
            <div className="center_container">
              <div className="text_white_small_bold">
                {teacher.username.charAt(0).toUpperCase()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTeachers = () => {
    return (
      <motion.div
        className="professor_credentials_menu_teachers_container"
        layout
        onClick={() => setSelectedTab(1)}
        style={
          selectedTab === 1
            ? { width: "85%", gridTemplateRows: "10% 90%" }
            : {
              flex: "1",
            }
        }
      >
        {selectedTab === 1 && (
          <div className="professor_credentials_menu_teachers_top">
            <div className="left_container">
              <div
                className="text_black_small_bold"
                style={{ marginLeft: "8%" }}
              >
                {t("teachers")}
              </div>
            </div>
            <div className="right_container">
              <motion.div
                className="professor_credentials_menu_add_group_button"
                whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px 1px #BCD1EF" }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                onClick={openTeacherMenuHandler}
              >

                <div className="center_container">
                  <img
                    className="image"
                    src={personAddButton}
                    alt="addTeacher"
                    style={{
                      maxHeight: "6vh",
                    }}
                  />
                </div>
                <div className="center_container">
                  <div className="text_black_very_small_bold">
                    {t("add_teacher")}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <div
            className="professor_credentials_menu_teachers_bottom"
            style={{
              overflowY: "scroll",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "10px",
              boxSizing: "border-box",
              padding: "1%",
              width: "100%",
            }}
          >
            {teachersInClass.map((teacher: TeacherType) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {renderTeacher(teacher)}
              </div>
            ))}
          </div>
        )}
        {selectedTab === 0 && (
          <motion.div
            className="professor_credentials_menu_teachers_closed"
            whileHover={{
              scale: 1.01,
              borderRadius: "5px",
              boxShadow: "0px 0px 10px 1px #5090F0",
            }}
            style={{
              boxShadow: "0px 0px 10px #CCCCCC",
              borderRadius: "5px",
            }}
          >
            <div
              className="professor_credentials_menu_teachers_top"
              style={{ width: "95%", left: "2.5%" }}
            >
              <div className="center_container">
                <div className="text_black_small_bold">{t("teachers")}</div>
              </div>
            </div>
            <div className="professor_credentials_menu_teachers_bottom">
              {teachersInClass.map((teacher: TeacherType) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                  }}
                >
                  {renderTeacherClosed(teacher)}
                </div>
              ))}
            </div>
          </motion.div>
        )}
        { }
      </motion.div>
    );
  };

  const renderAddTeacherMenu = () => {
    return (
      <div className="professor_credentials_menu_add_groups_background">
        <div className="center_container">
          <div className="professor_credentials_menu_add_groups_container">
            <div
              className="professor_credentials_menu_add_groups_menu"
              style={{ gridTemplateRows: "15% 70% 15%" }}
            >
              <div className="professor_credentials_menu_add_groups_top">
                <div
                  className="text_black_medium_bold"
                  style={{ marginLeft: "4%" }}
                >
                  {t("teachers")}
                </div>
                <div
                  className="professor_credentials_menu_add_groups_menu_close"
                  onClick={openTeacherMenuHandler}
                >
                  <motion.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 10,
                    }}
                  />
                </div>
              </div>

              {/* Teachers */}
              <div className="center_container">
                <div className="professor_credentials_menu_add_groups_students">
                  <div className="left_container">
                    <div className="text_black_very_small_bold">
                      {t("teachers_to_add")}
                    </div>
                  </div>

                  <div className="professor_credentials_menu_add_groups_students_checkbox">
                    {teachersInSchool.map((teacher, index) => (
                      <div
                        className="professor_credentials_menu_add_groups_students_checkbox_row"
                        key={teacher.id}
                        style={{
                          borderBottom:
                            index === teachersInSchool.length - 1
                              ? "none"
                              : "2px solid #EEEEEE",
                        }}
                      >
                        <Checkbox
                          isChecked={selectedTeachers.includes(teacher.id)}
                          onChange={() => handleTeacherCheckboxChange(teacher)}
                          as={motion.div}
                          size="lg"
                          whileHover={{
                            scale: teacher.admin ? 1 : 1.1,
                            // boxShadow: "0px 0px 10px 1px #BCD1EF",
                          }}
                          style={{
                            width: "fit-content",
                            cursor: teacher.admin
                              ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                              : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                          isDisabled={teacher.admin}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "1vh",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {renderTeacherIcon(teacher)}
                            <div className="left_container">
                              <div className="text_black_small">
                                {teacher.fullname}
                              </div>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Confirm button */}
              <div className="center_container">
                <motion.div
                  className="professor_credentials_menu_add_groups_confirm"
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 0px 10px 1px #6ee6a7",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  onClick={modifyTeachersHandler}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("confirm")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStudents = () => {
    return (
      <motion.div
        className="professor_credentials_menu_students_container"
        layout
        onClick={() => setSelectedTab(0)}
        style={
          selectedTab === 0
            ? { width: "85%", gridTemplateRows: "10% 90%" }
            : {
              flex: "1",
            }
        }
      >
        {selectedTab === 0 && (
          <div
            className="professor_credentials_menu_teachers_top"
            style={{ overflow: "hidden" }}
          >
            <div className="left_container">
              <div
                className="text_black_small_bold"
                style={{ marginLeft: "8%" }}
              >
                {t("students")}
              </div>
            </div>
            <motion.div
              className="professor_credentials_menu_add_group_button"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 10px 1px #BCD1EF",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() => openGroupsButtonHandler(1)}
            >
              <div className="center_container">
                <GroupAddButton color="#5090F0" size={30} />
              </div>
              <div className="center_container">
                <div className="text_black_very_small_bold">
                  {t("create_group")}
                </div>
              </div>
            </motion.div>
          </div>
        )}
        {selectedTab === 0 && (
          <div className="professor_credentials_menu_students_bottom">
            <Accordion
              allowMultiple
              style={{ display: "flex", width: "98%", flexDirection: "column" }}
            >
              {groups.map((group) => (
                <div className="center_container">
                  <AccordionItem
                    key={group.id}
                    as={motion.div}
                    whileHover={{
                      boxShadow:
                        group.id === "group1"
                          ? "0px 0px 5px 1px #A3F3C3"
                          : "0px 0px 5px 1px #BCD1EF",
                    }}
                    style={{
                      position: "relative",
                      width: "98%",
                      border: "2px solid #EEEEEE",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      borderRadius: "5px",
                      marginTop: "1%",
                      marginBottom: "1%",
                      marginLeft: "0.5%",
                    }}
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          style={{
                            height: "10vh",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                        >
                          <div
                            className="left_container"
                            style={{ marginLeft: "1%" }}
                          >
                            <GroupAddButton
                              color={
                                group.id === "group1" ? "#6EE6A7" : "#5090F0"
                              }
                              size={30}
                            />
                            <div
                              className="text_black_very_small_bold"
                              style={{ marginLeft: "1%" }}
                            >
                              {t(group.name)}
                            </div>
                          </div>
                          <ChevronDownIcon
                            boxSize="4vh"
                            style={{
                              position: "absolute",
                              color: "#B8B8B8",
                              right: "2%",
                            }}
                            transform={
                              isExpanded ? "rotate(180deg)" : "rotate(0deg)"
                            }
                          />
                        </AccordionButton>
                        <AccordionPanel
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {group.students.map((student: StudentType) => (
                            <div
                              key={student.id}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              {renderStudent(student, group)}
                            </div>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                  {renderOptionsButtonGroup(group)}
                </div>
              ))}
            </Accordion>
          </div>
        )}
        {selectedTab === 1 && (
          <motion.div
            className="professor_credentials_menu_teachers_closed"
            whileHover={{
              scale: 1.01,
              borderRadius: "5px",
              boxShadow: "0px 0px 10px 1px #5090F0",
            }}
            style={{
              boxShadow: "0px 0px 10px #CCCCCC",
              borderRadius: "5px",
            }}
          >
            <div
              className="professor_credentials_menu_teachers_top"
              style={{ width: "95%", left: "2.5%" }}
            >
              <div className="center_container">
                <div className="text_black_small_bold">{t("students")}</div>
              </div>
            </div>
            <div className="professor_credentials_menu_teachers_bottom">
              {groups.map((group) => (
                <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   width: "95%",
                  // }}
                  style={{
                    position: "relative",
                    height: "8vh",
                    left: "2.5%",
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                    gap: "0.5vw",
                    border: "2px solid #EEEEEE",
                    borderRadius: "5px",
                  }}
                >
                  <div className="left_container" style={{ marginLeft: "1%" }}>
                    <GroupAddButton
                      color={group.id === "group1" ? "#6EE6A7" : "#5090F0"}
                      size={30}
                    />
                    <div
                      className="text_black_super_small"
                      style={{ marginLeft: "1%" }}
                    >
                      {t(group.name)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </motion.div>
    );
  };

  const renderAddGroupMenu = () => {
    return (
      <div className="professor_credentials_menu_add_groups_background">
        <div className="center_container">
          <div className="professor_credentials_menu_add_groups_container">
            <div className="professor_credentials_menu_add_groups_menu">
              <div className="professor_credentials_menu_add_groups_top">
                <div
                  className="text_black_medium_bold"
                  style={{ marginLeft: "4%" }}
                >
                  {isGroupsMenuOpen === 1
                    ? t("create_new_group")
                    : t("modify_group")}
                </div>
                <div
                  className="professor_credentials_menu_add_groups_menu_close"
                  onClick={() => {
                    setSelectedGroup(null);
                    openGroupsButtonHandler(0);
                  }}
                >
                  <motion.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 10,
                    }}
                  />
                </div>
              </div>
              {/* Textbox */}
              <div className="center_container">
                <Input
                  placeholder={t("group_name")}
                  as={motion.input}
                  id="group_name_input"
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                  }}
                  style={{
                    fontFamily: "Causten",
                    fontSize: "calc(5px + 1vw)",
                    width: "90%",
                    height: "65%",
                    border: "#BCD1EF solid 1px",
                  }}
                  value={groupName}
                  onChange={groupNameHandler}
                />
              </div>
              {/* Students */}
              <div className="center_container">
                <div className="professor_credentials_menu_add_groups_students">
                  <div className="left_container">
                    <div className="text_black_very_small_bold">
                      {t("students_to_add")}
                    </div>
                  </div>

                  <div className="professor_credentials_menu_add_groups_students_checkbox">
                    {students.map((student: StudentType, index: number) => (
                      <div
                        className="professor_credentials_menu_add_groups_students_checkbox_row"
                        key={student.id}
                        style={{
                          borderBottom:
                            index === students.length - 1
                              ? "none"
                              : "2px solid #EEEEEE",
                        }}
                      >
                        <Checkbox
                          isChecked={selectedStudents.includes(student.id)}
                          onChange={() =>
                            handleStudentCheckboxChange(student.id)
                          }
                          as={motion.div}
                          size="lg"
                          whileHover={{
                            scale: 1.1,
                            // boxShadow: "0px 0px 10px 1px #BCD1EF",
                          }}
                          style={{
                            width: "fit-content",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "1vh",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {renderStudentIcon(student)}
                            {renderStudentName(student)}
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Confirm button */}
              <div className="center_container">
                <motion.div
                  className="professor_credentials_menu_add_groups_confirm"
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 0px 10px 1px #6ee6a7",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  onClick={
                    isGroupsMenuOpen === 1
                      ? () => addGroupHandler()
                      : () => modifyGroupHandler()
                  }
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("confirm")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="professor_credentials_menu" style={{ display: "flex" }}>
        <Loading />
      </div>
    );
  } else {
    return (
      <motion.div className="professor_credentials_menu" layout>
        {renderStudents()}
        {renderTeachers()}
        {!_.isNull(deleteMenu) &&
          renderDeleteMenu(
            deleteMenu,
            null,
            () => deleteStudentHandler(deleteMenu),
            t("delete_student_longtext")
          )}
        {/* Delete menus */}
        {isTeacherMenuOpen && renderAddTeacherMenu()}
        {isGroupsMenuOpen !== 0 && renderAddGroupMenu()}
        {!_.isNull(deleteMenu) &&
          !_.isNull(selectedGroup) &&
          renderDeleteMenu(
            deleteMenu,
            selectedGroup,
            () => deleteStudentFromGroup(deleteMenu, selectedGroup),
            t("delete_student_from_group_alert")
          )}
        {_.isNull(deleteMenu) &&
          isGroupsMenuOpen !== 2 &&
          !_.isNull(selectedGroup) &&
          renderDeleteMenu(
            null,
            selectedGroup,
            () => deleteGroupHandler(selectedGroup),
            t("delete_group_alert")
          )}
        {/* Alert menu (for group button) */}
        {showAlert && (
          <Alert
            text={t("alert_add_groups")}
            handleAlert={() => handleAlert()}
          />
        )}
      </motion.div>
    );
  }
};

export default ProfessorCredentialsMenu;
