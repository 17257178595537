import "./UnitEditMenu.css";
import { useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo } from "react";
import { LazyMotion, m } from "framer-motion";
import BackCross from "../../../assets/professor/close.tsx";
import Plus from "../../../assets/professor/plus.tsx";
import bin from "../../../assets/professor/bin.svg";

import Loading from "../../loading_screen/LoadingDots.tsx";
import { Input } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { motion } from "framer-motion";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import ScenarioEditMenu from "./ScenarioEditMenu.tsx";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
  ModifiedUnitType,
  ScenarioType,
  UpdateUnitAPI,
  DeleteUnitAPI,
  GetUnitScenariosAPI,
} from "../../../_newapios/content/unit.ts";
import {
  UpdateScenarioAPI,
  DeleteScenarioAPI,
  CreateScenarioAPI,
  ModifiedScenarioType,
} from "../../../_newapios/content/scenario.ts";
import {
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
} from "../../../_newapios/content/course.ts";
import { globalCompetencesBooleanArray } from "../../../utils/competences.ts";
import { ResearchType } from "../../../_newapios/content/research.ts";
import { ExperimentType } from "../../../_newapios/content/experiment.ts";
import close from "../../../assets/student/close_user_menu.svg";
import _ from "lodash";
import Alert from "../../alerts/Alert.tsx";
import ExercisePreviewResearch from "./exercises_edit/ExercisePreviewResearch.tsx";
import ExercisePreviewExperiment from "./exercises_edit/ExercisePreviewExperiment.tsx";

const UnitEditMenu = ({ ...props }) => {
  const setScenarioWrapper = (index: number) => {
    return (updatedScenario: ModifiedScenarioType) => {
      const newScenarios = [...scenarios];
      newScenarios[index] = updatedScenario;
      setScenarios(newScenarios);
    };
  };
  const saveScenarioWrapper = (
    index: number,
    emptyEvalIds: boolean = false
  ) => {
    return async (scenario: ScenarioType | undefined = undefined) => {
      if (scenario === undefined) {
        scenario = scenarios[index];
      }
      if (emptyEvalIds) {
        scenario.evaluation_criteria_ids = [];
      }

      const updatedScenario = await UpdateScenarioAPI(scenario.id, scenario);
      const newScenarios = [...scenarios];
      newScenarios[index] = updatedScenario;
      setScenarios(newScenarios);
    };
  };
  const { t, i18n } = useTranslation(["common", "evaluation"]);

  const allStudents = [{ value: 0, label: t("all_students") }];
  registerLocale("es", es);

  const weightingOptions = [
    { value: 0, label: "No evaluada - 0 puntos por situación" },
    { value: 50, label: "Baja - 50 puntos por situación" },
    { value: 100, label: "Estándar - 100 puntos por situación" },
    { value: 150, label: "Alta - 150 puntos por situación" },
    { value: 200, label: "Muy Alta - 200 puntos por situación" },
  ];

  const languages = [
    { value: "ES", label: t("spanish") },
    { value: "EN", label: t("english") },
    { value: "DE", label: t("deutsch") },
    { value: "FR", label: t("french") },
  ];

  const visibilityOptions = [
    { value: 0, label: t("visibility_text_0") },
    { value: 1, label: t("visibility_text_1") },
    { value: 2, label: t("visibility_text_2") },
  ];
  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const courses = mainState.courses;
  const regulations = mainState.regulations;
  const globalCompetences = mainState.globalCompetences;
  const userLicense = teacherState.userLicense;

  const [unit, setUnit] = useState<ModifiedUnitType>(props.unit);
  const [selectedRegulationId, setSelectedRegulationId] = useState(
    props.unit.regulation_id
  );
  const [loading, setLoading] = useState(false);

  const [isAddScenarioHovered, setIsAddScenarioHovered] =
    useState<boolean>(false);

  const [scenarios, setScenarios] = useState<ModifiedScenarioType[]>([]);

  const [openResearchEditMenu, setOpenResearchEditMenu] =
    useState<null | ResearchType>(null);
  const [openExperimentEditMenu, setOpenExperimentEditMenu] =
    useState<null | ExperimentType>(null);
  // const [selectedScenario, setSelectedScenario] = useState<any>();
  // const [scenarioIndex, setScenarioIndex] = useState<number>(0);

  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);

  const [deleteMenu, setDeleteMenu] = useState<Boolean>(false);
  const [visibility, setVisibility] = useState<Number>(props.unit.visibility);

  const [alert, setAlert] = useState<string | null>(null);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const unitNameHandler = (value: string) => {
    const newunit = { ...unit };
    newunit.name = value;
    setUnit(newunit);
    if (scenarios.length === 0) return;
    const setScenario0 = setScenarioWrapper(0);
    let scenario0 = scenarios[0];
    scenario0.know_hows[0] = value;
    setScenario0(scenario0);
  };

  const unitCourseHandler = (value: any) => {
    const newUnit = { ...unit };
    newUnit.course_id = value.value;
    setUnit(newUnit);
    saveUnitHandler(newUnit);
  };

  const unitRegulationHandler = (value: any) => {
    const newUnit = { ...unit };
    newUnit.course_id = "";

    [...scenarios].map((_scenario, scenarioIndex) => {
      saveScenarioWrapper(scenarioIndex, true)();
    });
    setUnit(newUnit);
    setSelectedRegulationId(value.value);
  };

  const unitLanguageHandler = (value: any) => {
    const newunit = { ...unit };
    newunit.language = value.value;
    setUnit(newunit);
    saveUnitHandler(newunit);
  };

  useEffect(() => {
    loadScenarios();
  }, [unit.id]);

  const loadScenarios = async () => {
    const newScenarios = await GetUnitScenariosAPI(unit.id || props.unit.id);
    setScenarios(newScenarios);
  };

  useEffect(() => {
    loadEvaluationCriteria();
    loadSpecificCompetences();
  }, [unit.course_id]);

  const loadEvaluationCriteria = async () => {
    setEvaluationCriteria(
      await GetCourseEvaluationCriteriaAPI(
        unit.course_id || props.unit.course_id
      )
    );
  };
  const loadSpecificCompetences = async () => {
    setSpecificCompetences(
      await GetCourseSpecificCompetencesAPI(
        unit.course_id || props.unit.course_id
      )
    );
  };

  const saveUnitHandler = async (
    new_unit: ModifiedUnitType | undefined = undefined,
    name: boolean = false
  ) => {
    if (new_unit === undefined) {
      new_unit = unit;
    }
    UpdateUnitAPI(new_unit.id, new_unit).then((data) => {
      if (data !== undefined && name) {
        setAlert(data);
      }
    });

    const saveScenario0 = saveScenarioWrapper(0);
    saveScenario0();
  };

  const deleteUnitHandler = async () => {
    await DeleteUnitAPI(unit.id);
    props.deleteUnitBackHandler();
  };

  const deleteMenuHandler = () => {
    setDeleteMenu(true);
  };
  const visibililtyHandler = (value: any) => {
    const newunit = { ...unit };
    newunit.visibility = value.value;
    setUnit(newunit);
    setVisibility(newunit.visibility);
  };

  const addScenarioHandler = async () => {
    const newScenario = await CreateScenarioAPI(unit.id);
    var newScenarios = [...scenarios];
    newScenarios.push(newScenario);

    setScenarios(newScenarios);
  };
  const deleteScenarioWrapper = (index: number) => {
    return async () => {
      await DeleteScenarioAPI(scenarios[index].id);
      var newScenarios = [...scenarios];
      newScenarios.splice(index, 1);
      setScenarios(newScenarios);
    };
  };

  const unitBackHandler = async () => {
    if (
      unit.regulation_id !== undefined &&
      unit.regulation_id !== null &&
      unit.regulation_id !== "" &&
      unit.course_id !== undefined &&
      unit.course_id !== null &&
      unit.course_id !== ""
    ) {
      await saveUnitHandler();
      props.backHandler();
    } else {
      setAlert("unit_reg_course_error");
    }
  };

  const alertHandler = () => {
    setAlert(null);
  };

  const renderTop = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_edit_unit_menu_top">
          <m.div
            className="professor_edit_unit_menu_top_left_name"
            whileHover={{ scale: 1.1, rotate: 90 }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.1 }}
            onClick={() => unitBackHandler()}
          >
            <BackCross />
          </m.div>
          <Input
            className="professor_edit_unit_menu_top_left_name_input"
            placeholder={t("scenario_main_know_how")}
            value={unit.name}
            onChange={(e) => unitNameHandler(e.target.value)}
            onBlur={() => saveUnitHandler(undefined, true)}
            style={{
              fontFamily: "Causten",
              fontWeight: "bold",
              width: "30vw",
              fontSize: "1.5em",
              borderRadius: "2.5px",
              color: "black",
              marginRight: "2vw",
              marginLeft: "1vw",
              boxShadow: "none",
            }}
            as={motion.input}
            size={"lg"}
            whileHover={{
              scale: 1.01,
              boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
            }}
          />
          <Select
            name="regulations"
            options={regulations.map((reg) => {
              return {
                value: reg.id,
                label: t(reg.name, { ns: "evaluation" }),
              };
            })}
            value={
              regulations
                .filter((reg) => reg.id === selectedRegulationId)
                .map((reg) => {
                  return {
                    value: reg.id,
                    label: t(reg.name, { ns: "evaluation" }),
                  };
                })[0]
            }
            useBasicStyles
            placeholder={t("regulation_placeholder")}
            closeMenuOnSelect={true}
            key={1}
            onChange={unitRegulationHandler}
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                width: "10vw",
                fontFamily: "Causten",
                fontWeight: "bold",
                fontSize: "0.8em",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: regulations.find(
                  (reg) => reg.id === selectedRegulationId
                )?.color,
                color: "white",
                borderRadius: "50px",
                marginRight: "1vw",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              option: (provided, state) => ({
                ...provided,
                color: regulations.find((reg) => reg.id === state.data.value)
                  ?.color,
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                "&:hover": {
                  background: "#5090F0",
                  color: "white",
                },
              }),
              dropdownIndicator: (provided, { selectProps, hasValue }) => ({
                ...provided,
                color: hasValue ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              // To remove space between text and dropdown
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
              }),
            }}
          />
          <Select
            className="professor_edit_unit_menu_top_left_course_select"
            placeholder={t("subjects_placeholder")}
            options={courses
              .filter((course) => {
                return course.regulation_id === selectedRegulationId;
              })
              .map((course) => {
                return {
                  value: course.id,
                  label: t(course.acronym + "_name", { ns: "evaluation" }),
                };
              })}
            value={
              courses
                .filter((course) => {
                  return course.id === unit.course_id;
                })
                .map((course) => {
                  return {
                    value: course.id,
                    label: t(course.acronym + "_name", { ns: "evaluation" }),
                  };
                })[0] || null // JANK - quite janky indeed
            }
            key={unit?.course_id}
            onChange={unitCourseHandler}
            size={"md"}
            useBasicStyles
            closeMenuOnSelect={true}
            focusBorderColor={
              courses.find((course) => course.id === unit.course_id)?.color ||
              "black"
            }
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                width: "12vw",
                fontFamily: "Causten",
                fontWeight: "bold",
                fontSize: "0.8em",
                border: state.hasValue ? "none" : undefined,
                boxShadow: state.isFocused
                  ? "0px 0px 10px 2px " +
                    courses.find((course) => course.id === unit.course_id)
                      ?.color
                  : "none",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: state.hasValue
                  ? courses.find((course) => course.id === unit.course_id)
                      ?.color
                  : "white",
                color: "white",
                borderRadius: "50px",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              dropdownIndicator: (provided, { selectProps, hasValue }) => ({
                ...provided,
                color: hasValue ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
                // textOverflow: "none",
              }),
            }}
          />
          <Select
            name="languages"
            useBasicStyles
            options={languages}
            closeMenuOnSelect={true}
            placeholder={t("languages_placeholder")}
            onChange={unitLanguageHandler}
            value={languages.find((lang) => lang.value === unit.language)}
            key={unit.language}
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                // width: "fit-content",
                width: "8vw",
                fontFamily: "Causten",
                left: "1vw",
                fontWeight: "bold",
                fontSize: "0.8em",
                boxShadow: state.isFocused
                  ? "0px 0px 10px 2px #292F4C"
                  : "none",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: unit.language ? "#292F4C" : "none",
                color: "white",
                borderRadius: "50px",
                marginRight: "1vw",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
              }),
              dropdownIndicator: (provided, { selectProps }) => ({
                ...provided,
                color: unit.language ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
              }),
            }}
          />
          {!_.isNull(userLicense) && userLicense.license.allow_private && (
            <Select
              name="visibility"
              useBasicStyles
              options={visibilityOptions}
              closeMenuOnSelect={true}
              // placeholder={t("languages_placeholder")}
              onChange={visibililtyHandler}
              value={visibilityOptions.find(
                (vis) => vis.value === unit.visibility
              )}
              key={unit.visibility}
              chakraStyles={{
                control: (provided, state) => ({
                  ...provided,
                  width: "9vw",
                  left: "1vw",
                  fontFamily: "Causten",
                  fontWeight: "bold",
                  fontSize: "0.8em",
                  boxShadow: state.isFocused
                    ? "0px 0px 10px 2px #292F4C"
                    : "none",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  background: "#6EE6A7",
                  color: "white",
                  borderRadius: "50px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  fontFamily: "Causten",
                  left: "1vw",
                }),
                dropdownIndicator: (provided, { selectProps }) => ({
                  ...provided,
                  color: unit.visibility ? "white" : "black",
                  "> svg": {
                    transitionDuration: "normal",
                    transform: `rotate(${
                      selectProps.menuIsOpen ? -180 : 0
                    }deg)`,
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  // width: "fit-content",
                  WebkitPaddingEnd: "0px",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  width: "fit-content",
                }),
              }}
            />
          )}
          <m.div
            className="professor_edit_unit_menu_top_delete"
            onClick={(e) => {
              e.stopPropagation();
              // deleteUnitHandler();
              deleteMenuHandler();
            }}
            whileHover={{
              scale: 1.1,
              boxShadow: "0px 0px 10px 1px #F0684F",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="center_container">
              <img
                src={bin}
                style={{ height: "80%", position: "relative" }}
                alt="bin"
              />
            </div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  const renderMain = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div className="professor_edit_unit_menu_main" layoutRoot>
          <m.div className="professor_edit_unit_menu_main_scenarios" layoutRoot>
            {scenarios?.map((_scenario, index) => {
              // return renderScenario(scenario, index);
              const selectedGlobalCompetences = globalCompetencesBooleanArray(
                _scenario.global_competences_ids,
                globalCompetences
              );
              return (
                <ScenarioEditMenu
                  acronym={
                    courses.find((course) => course.id === unit.course_id)
                      ?.acronym
                  }
                  key={index}
                  scenario={scenarios[index]}
                  updateScenario={setScenarioWrapper(index)}
                  saveScenario={saveScenarioWrapper(index)}
                  evaluationCriteria={evaluationCriteria}
                  specificCompetences={specificCompetences}
                  selectedGlobalCompetences={selectedGlobalCompetences}
                  deleteScenario={deleteScenarioWrapper(index)}
                  researchHandler={(research: ResearchType) => {
                    setOpenResearchEditMenu(research);
                  }}
                  experimentHandler={(experiment: ExperimentType) => {
                    setOpenExperimentEditMenu(experiment);
                  }}
                  selectedRegulationId={selectedRegulationId}
                  // exerciseHandler={(
                  //   type: "research" | "experiment",
                  //   exercises_json: any[],
                  //   scenario: any
                  // ) => exerciseHandler(type, exercises_json, scenario, index)}
                />
              );
            })}
            {renderAddScenario()}
          </m.div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderDeleteMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_unit_text")}
                      <b>{unit.name}</b>?
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDeleteMenu(false)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteUnitHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDeleteMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderAddScenario = () => {
    if (scenarios.length > 0) {
      return null;
    }
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          layout="position"
          className="professor_edit_unit_menu_main_scenario_add"
          whileHover={{
            scale: 1.05,
            boxShadow: "#5090F0 1px 0px 10px",
            backgroundColor: "#5090F0",
          }}
          transition={{ type: "tween", ease: "easeInOut", duration: 0.5 }}
          onClick={() => addScenarioHandler()}
          onMouseEnter={() => setIsAddScenarioHovered(true)}
          onMouseLeave={() => setIsAddScenarioHovered(false)}
        >
          <Plus color={isAddScenarioHovered ? "#FFFFFF" : "#5090F0"} />
          <div
            className="text_blue_very_small_bold"
            style={{
              marginLeft: "0.5em",
              color: isAddScenarioHovered ? "white" : "#5090F0",
            }}
          >
            {t("add_scenario")}
          </div>
        </m.div>
      </LazyMotion>
    );
  };
  return (
    // <div className={loading ? "center_container" : "professor_edit_unit_menu"}>
    //   {loading && <Loading />}
    //   {!loading && renderTop()}
    //   {!loading && renderMiddle()}
    //   {!loading && renderMain()}
    //   {!loading && renderAddButton()}
    // </div>
    <div
      style={
        openResearchEditMenu === null && openExperimentEditMenu === null
          ? { position: "relative", height: "100%", width: "100%" }
          : {
              position: "fixed",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
              zIndex: 100,
            }
      }
    >
      {openResearchEditMenu === null && openExperimentEditMenu === null && (
        <div
          className={loading ? "center_container" : "professor_edit_unit_menu"}
        >
          {loading && <Loading />}
          {!loading && renderTop()}
          {/* {!loading && renderMiddle()} */}
          {!loading && renderMain()}
          {!loading && alert !== null && (
            <Alert text={alert} handleAlert={() => alertHandler()} />
          )}
        </div>
      )}
      {!loading && openResearchEditMenu !== null && (
        <ExercisePreviewResearch
          subject={props.subject}
          language={unit.language}
          research_id={openResearchEditMenu.id}
          exercises={openResearchEditMenu.exercises}
          backHandler={() => setOpenResearchEditMenu(null)}
        />
      )}
      {!loading && openExperimentEditMenu !== null && (
        <ExercisePreviewExperiment
          subject={props.subject}
          language={unit.language}
          experiment_id={openExperimentEditMenu.id}
          exercises={openExperimentEditMenu.exercises}
          backHandler={() => setOpenExperimentEditMenu(null)}
        />
      )}
      {deleteMenu && renderDeleteMenu()}
    </div>
  );
};

export default UnitEditMenu;
