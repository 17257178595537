import { useAppSelector, useAppDispatch } from "../../../../hooks/hooks.ts";
import {
  useGLTF,
  useTexture,
  useCursor,
  useAnimations,
  Float,
} from "@react-three/drei";
import { MeshPhongMaterial, SRGBColorSpace, Group } from "three";
import { DreiGLTF } from "../../../../interfaces.ts";
import { useEffect, useRef, useState } from "react";

import {
  increaseTutorialStep,
  setChosenPlot,
  updateInfoBar,
} from "../../../../reducers/studentSlice.ts";

import { setStudents } from "../../../../reducers/mainSlice.ts";
import MiniAldeaOutline from "./MiniAldeas/MiniAldeaOutline.tsx";
import MiniAldea from "./MiniAldeas/MiniAldea.tsx";
import { ClassType, GetMyClassAPI } from "../../../../_newapios/user/class.ts";
import { StudentType } from "../../../../_newapios/user/student";

import {
  PlotType,
  SetStudentPlotAPI,
} from "../../../../_newapios/gamecontent/plots.ts";
import ParcelaSelector from "./ParcelaSelector.tsx";
import { studentNeedsPlot, updateStudents } from "../../../utils/student.ts";
import {
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
} from "../../../../_newapios/progress/student_game_metrics.ts";

// TODO - We probably need to refactor this component entirely to make it have sense and more readable

const ParcelasBien = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);

  const intervalRef = useRef(0);

  const loginState = useAppSelector((state) => state.login);
  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);

  const plots = mainState.plots;
  const IS_TEACHER = loginState.type === "teacher";

  const [isChoosingPlot, setIsChoosingPlot] = useState<boolean>(true);

  const [studentsMetrics, setStudentsMetrics] = useState<
    {
      name: string;
      population: number;
      power: number;
      co2: number;
    }[]
  >([]);

  useEffect(() => {
    const me = mainState.students.find(
      (student) => student.id === loginState.id
    ) as StudentType;
    setIsChoosingPlot(IS_TEACHER ? false : me.plot_id === null);
    loadStudentsMetrics();
  }, [mainState.students]);

  // CRAZENESS AWAITS

  const clearUpdater = () => {
    window.clearInterval(intervalRef.current);
  };

  const updatePlotStatus = async () => {
    const myclass = await updateStudents(dispatch);
    setIsChoosingPlot(studentNeedsPlot(myclass, loginState));
  };

  const loadStudentsMetrics = async () => {
    const temp_students_metrics = await Promise.all(
      mainState.students.map((student) => calcStudentMetrics(student))
    );
    setStudentsMetrics(temp_students_metrics);
  };

  const calcStudentMetrics = async (student: StudentType) => {
    const studentName = student.username;
    const studentPopulation = await GetStudentPopulationAPI(student.id);
    const studentPower = await GetStudentWattsAPI(student.id);
    const studentCO2 = await GetStudentCO2API(student.id);

    return {
      name: studentName,
      population: Math.round(studentPopulation * 100) / 100,
      power: Math.round(studentPower * 100) / 100,
      co2: Math.round(studentCO2 * 100) / 100,
    };
  };

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (isChoosingPlot) {
        updatePlotStatus();
      } else {
        clearUpdater();
      }
    }, 2000);
    intervalRef.current = interval;
    return () => window.clearInterval(interval);
  }, [isChoosingPlot]);

  // CRAZENESS SUCCEEDED

  const aldeaGLTF = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/MiniAldeas/minialdea01.gltf"
  ) as DreiGLTF;
  const flecha = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/indicador_rombo_global.gltf"
  ) as DreiGLTF;
  const bakedParcelasTexture = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/baked_minialdea04_op.jpg"
  );
  bakedParcelasTexture.colorSpace = SRGBColorSpace;
  bakedParcelasTexture.flipY = false;

  const bakedParcelasMaterial = new MeshPhongMaterial({
    map: bakedParcelasTexture,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const bakedParcelasMaterialYellow = new MeshPhongMaterial({
    map: bakedParcelasTexture,
    color: 0xfb9e12,
    emissive: 0xfb9e12,
    emissiveIntensity: 0.2,
    shininess: 0,
    fog: true,
  });

  const bakedParcelasMaterialRed = new MeshPhongMaterial({
    map: bakedParcelasTexture,
    color: 0xff4444,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const bakedParcelasMaterialBlue = new MeshPhongMaterial({
    map: bakedParcelasTexture,
    color: 0x248aff,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const bakedflechas = new MeshPhongMaterial({
    color: 0xfb9e12,
    emissive: 0xfb9e12,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );

  // const reloadPage = () => {
  //   setTimeout(() => window.location.reload(), 1000);
  // };

  const renderChoosingPlot = () => {
    return (
      <>
        {
          //tutorialStep === 3 && // XXX - make constants file?
          plots.map((plot, aldeaIndex) => {
            var cb: (e: any) => void;
            const plotObject = plots[aldeaIndex];
            const plotIsTaken =
              mainState.students.find(
                (student) => student.plot_id === plotObject.id
              ) !== undefined;

            var material = bakedParcelasMaterial;
            var materialParcela = bakedParcelasMaterial;

            if (plotIsTaken) {
              // Plot already taken
              material = bakedParcelasMaterialRed;
              materialParcela = bakedParcelasMaterialRed;
              cb = (e: any) => {
                e.stopPropagation();
              };
            } else if (studentState.chosenPlot === undefined) {
              // Plot available
              material = bakedParcelasMaterialBlue;
              materialParcela = bakedParcelasMaterialBlue;
              cb = (e: any) => {
                e.stopPropagation();
                dispatch(setChosenPlot(plot));
                // dispatch(increaseTutorialStep());
              };
            } else {
              // Plot available but already choosing
              material = bakedParcelasMaterialBlue;
              materialParcela = bakedParcelasMaterialBlue;
              cb = (e: any) => {
                e.stopPropagation();
              };
            }

            return (
              <MiniAldea
                material={material}
                materialParcela={materialParcela}
                aldea={aldeaGLTF}
                positions={plot}
                renderShadow={() => props.renderShadow()}
                onPointerOver={!plotIsTaken ? () => setHovered(true) : () => {}}
                onPointerOut={
                  !plotIsTaken == null ? () => setHovered(false) : () => {}
                }
                onClick={(event: any) => cb(event)}
              />
            );
          })
        }
      </>
    );
  };

  const renderPlots = () => {
    return (
      <>
        {plots.map((plot, plotIndex) => {
          var material = bakedParcelasMaterial;
          var materialParcela = bakedParcelasMaterial;

          const plotOwner = mainState.students.find(
            (student) => student.plot_id === plot.id
          );
          if (plotOwner === undefined) return <></>;

          const me = mainState.students.find(
            (student) => student.id === loginState.id
          ) as StudentType;
          const plotIsMine = IS_TEACHER ? false : me.plot_id === plot.id;

          if (plotIsMine) {
            materialParcela = bakedParcelasMaterialYellow;
          }

          const studentMetrics = studentsMetrics.find(
            (student) => student.name === plotOwner.username
          );

          if (studentMetrics === undefined) return <></>;

          if (plotIsMine) {
            return (
              <MiniAldeaOutline
                positions={plot}
                material={material}
                materialParcela={materialParcela}
                aldea={aldeaGLTF}
                flecha={flecha}
                materialFlecha={bakedflechas}
                renderShadow={() => props.renderShadow()}
                renderAnimation={() => props.renderAnimation()}
                onPointerOver={() => {
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "visible" })
                  );
                  setHovered(true);
                }}
                onPointerOut={() => {
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "hidden" })
                  );
                  setHovered(false);
                }}
                onClick={() => {
                  props.toggleMap(undefined, plot.id);
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "hidden" })
                  );
                }}
              />
            );
          } else {
            return (
              <MiniAldea
                positions={plot}
                material={material}
                materialParcela={materialParcela}
                aldea={aldeaGLTF}
                renderShadow={() => props.renderShadow()}
                onPointerOver={() => {
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "visible" })
                  );
                  setHovered(true);
                }}
                onPointerOut={() => {
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "hidden" })
                  );
                  setHovered(false);
                }}
                onClick={() => {
                  // Clicked on a friend

                  props.toggleMap(plotOwner?.id, plot.id);
                  dispatch(
                    updateInfoBar({ ...studentMetrics, display: "visible" })
                  );
                }}
              />
            );
          }
        })}
      </>
    );
  };
  return (
    <>
      {isChoosingPlot && renderChoosingPlot()}
      {!isChoosingPlot && renderPlots()}
    </>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/MiniAldeas/minialdea01.gltf"
);

export default ParcelasBien;
