import "./DragDropTwoLists.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";

const DragDropTwoLists = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [answers, setAnswers] = useState<string[][]>([]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const solutions = props.exercise.solutions as number[];
  const labels = props.exercise.labels as string[];
  const options = props.exercise.options as string[];

  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);
  const [helpOpen, setHelpOpen] = useState(false);
  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);

  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })
  // }, [])

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderAnswers();
  }, [options]);

  const orderAnswers = () => {
    console.log(maxExercise, selectedExercise);
    if (props.research) {
      if (selectedExercise < maxExercise) {
        var answers0 = [];
        var answers1 = [];
        for (var i = 0; i < options.length; i++) {
          if (solutions[i] === 0) {
            answers0.push(options[i]);
          } else {
            answers1.push(options[i]);
          }
        }
        var orderedAnswers = [answers0, answers1];

        setAnswers(distributeSolution(orderedAnswers));
      } else {
        setAnswers(distributeOptions());
      }
    } else {
      setAnswers(distributeOptions());
    }
  };

  useEffect(() => {
    setSelectedExercise(props.exerciseIndex);
  }, [props.exerciseIndex]);

  useEffect(() => {
    const newAnswers = [...answers];
    newAnswers.forEach((answer) => {
      answer.forEach((item, index) => {
        if (item === undefined) {
          answer.splice(index, 1);
        }
      });
    });
  }, [answers]);

  // useEffect(() => {
  //   setmaxExercise(maxExercise);
  // }, [props.maxExercise]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const distributeOptions = () => {
    var temp_options = [...options];
    var newAnswers: any[] = [[], [], [], [], []];
    var j = 2;
    const shuffledOptions = _.shuffle(temp_options);
    for (var i = 0; i < shuffledOptions.length; i++) {
      if (j === 4) {
        newAnswers[j].push(shuffledOptions[i]);
        j = 2;
      } else {
        newAnswers[j].push(shuffledOptions[i]);
        j++;
      }
    }
    return newAnswers;
  };

  const distributeSolution = (solution: string[][]) => {
    var newSolution: any[] = [[], [], [], [], []];
    newSolution[0] = solution[0];
    newSolution[1] = solution[1];
    return newSolution;
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    var exists: number;
    if (
      answers[2].length > 0 ||
      answers[3].length > 0 ||
      answers[4].length > 0
    ) {
      correct = false;
      return false;
    }
    for (var i = 0; i < answers[0].length; i++) {
      exists = options.findIndex((item) => {
        return item === answers[0][i];
      });
      if (exists === -1) correct = false;
      if (solutions[exists] !== 0) correct = false;
    }
    for (var i = 0; i < answers[1].length; i++) {
      exists = options.findIndex((item) => {
        return item === answers[1][i];
      });
      if (exists === -1) correct = false;
      if (solutions[exists] !== 1) correct = false;
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers[0].concat(answers[1]), true); //concatenate vertically both arrays
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers[0].concat(answers[1]), true);
      } else {
        props.updateLevel("right", answers[0].concat(answers[1]), false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const updateDashboard = (
    srcC: string,
    desC: string,
    srcI: number,
    desI: number
  ) => {
    let copiedDashboards = [...answers];
    console.log(srcC, desC, srcI, desI);
    if (srcC === desC) {
      const [removed] = copiedDashboards[parseInt(srcC)].splice(srcI, 1);
      copiedDashboards[parseInt(srcC)].splice(desI, 0, removed);
      console.log(copiedDashboards);
    } else {
      console.log(copiedDashboards[parseInt(desC)].length);
      if (desC === "2" || desC === "3") {
        if (copiedDashboards[parseInt(desC)].length > 2) return;
      }
      if (desC === "4") {
        if (copiedDashboards[parseInt(desC)].length > 3) return;
      }
      if (desC === "0" || desC === "1") {
        if (copiedDashboards[parseInt(desC)].length > 4) return;
      }
      copiedDashboards[parseInt(desC)].splice(
        desI,
        0,
        copiedDashboards[parseInt(srcC)][srcI]
      );
      copiedDashboards[parseInt(srcC)].splice(srcI, 1);
    }

    setAnswers(copiedDashboards);
  };

  const renderProfessor = () => {
    return (
      <div className="drag_drop_two_lists_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="drag_drop_two_lists_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (research) {
              if (selectedExercise < maxExercise) arrowsHandler("right");
            } else {
              if (selectedExercise < maxExercise) arrowsHandler("right");
            }
          }}
          whileHover={{ scale: 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="drag_drop_two_lists_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="drag_drop_two_lists_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_two_lists_background">
        <DragDropContext
          onDragEnd={(param) => {
            const srcC = param.source.droppableId.slice(-1);
            const desC =
              param.destination === null || param.destination === undefined
                ? param.source.droppableId.slice(-1)
                : param.destination.droppableId.slice(-1);

            const srcI = param.source.index;
            const desI =
              param.destination === null || param.destination === undefined
                ? param.source.index
                : param.destination.index;

            updateDashboard(srcC, desC, srcI, desI);
          }}
        >
          <div className="drag_drop_two_lists_top">
            <Droppable key={"droppable-0"} droppableId={"droppable-0"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_0"
                >
                  <div className="drag_drop_two_lists_label">
                    <div className="center_container">
                      <div className="text_black_medium_bold">{labels[0]}</div>
                    </div>
                  </div>
                  {answers[0]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            onClick={() => console.log(item)}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-1"} key={"droppable-1"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_1"
                >
                  <div className="drag_drop_two_lists_label">
                    <div className="center_container">
                      <div className="text_black_medium_bold">{labels[1]}</div>
                    </div>
                  </div>
                  {answers[1]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="drag_drop_two_lists_bottom">
            <Droppable droppableId={"droppable-2"} key={"droppable-2"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[2]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-3"} key={"droppable-3"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[3]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-4"} key={"droppable-4"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_two_lists_droppable_2"
                >
                  {answers[4]?.map((item, i) => (
                    <Draggable
                      key={item}
                      draggableId={"draggable-" + item}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_two_lists_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            {!_.isUndefined(item) && (
                              <div
                                className={
                                  item.length < 20
                                    ? "text_black_small_bold"
                                    : "text_black_very_small_bold"
                                }
                              >
                                {item}
                              </div>
                            )}
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    );
  };

  const renderBackground = () => {
    setTimeout(() => {
      setIncorrectFeedback(false);
    }, 2000);
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="drag_drop_two_lists_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="drag_drop_two_lists"
      style={{ zIndex: "90", backgroundColor: "white" }}
    >
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropTwoLists;
