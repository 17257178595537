import "./ChooseManyEdit.css";
import { useEffect, useState } from "react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import tick from "../../../../assets/professor/ok.svg";
import bin from "../../../../assets/professor/bin.svg";
import { useTranslation } from "react-i18next";

import ImageSelector from "./ImageSelector/ImageSelector";
import { Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import axios from "axios";
import { useAppSelector } from "../../../../hooks/hooks";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";
const pako = require("pako");

//import lottie from "lottie-web";

const ChooseManyEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateChooseMany: (newChooseMany: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();
  const animations = useAppSelector((state) => state.main).animations;

  const [helpOpen, setHelpOpen] = useState(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const image = props.exercise.image?.url;
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [solution, setSolution] = useState(
    props.exercise.solutions as number[]
  );
  const [saveToDB, setSaveToDB] = useState(false);
  const animationUrl = props.exercise.animation?.url;

  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.options = options;
      exercise.solutions = solution;

      props.updateChooseMany(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setOptions(props.exercise.options as string[]);
    setSolution(props.exercise.solutions as number[]);
  }, [props.exercise.text, props.exercise.image, props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const imageHandler = (newExercise: ExerciseType) => {
    props.updateChooseMany(newExercise);
  };

  const optionHandler = (newOption: string, newOptionIndex: number) => {
    var updatedOptions = [...options];

    updatedOptions[newOptionIndex] = newOption;

    setOptions(updatedOptions);
  };

  const addOptionHandler = () => {
    var updatedOptions = [...options];
    var updatedSolution = [...solution];
    updatedOptions.push("");
    updatedSolution.push(0);
    setOptions(updatedOptions);
    setSolution(updatedSolution);
  };

  const deleteOptionHandler = (optionIndex: number) => {
    var updatedOptions = [...options];
    var updatedSolutions = [...solution];

    const deletedOption = updatedOptions.splice(optionIndex, 1)[0];
    const deletedSolution = updatedSolutions.splice(optionIndex, 1)[0];

    setOptions(updatedOptions);
    setSolution(updatedSolutions);
    setSaveToDB(true);
  };

  const solutionHandler = (newSolution: string, newSolutionIndex: number) => {
    var updatedSolutions = [...solution];

    if (updatedSolutions[newSolutionIndex] === 1) {
      updatedSolutions[newSolutionIndex] = 0;
    } else {
      updatedSolutions[newSolutionIndex] = 1;
    }

    setSolution(updatedSolutions);
    setSaveToDB(true);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_choose_many_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_choose_many_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_choose_many_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="edit_choose_many_image">
        <div className="center_container">
          <ImageSelector
            imageHandler={(exercise: ExerciseType) => imageHandler(exercise)}
            animation={animationUrl}
            image={image}
            exerciseId={props.exercise.id}
          />
        </div>
      </div>
    );
  };

  // const renderImage = () => {
  //   {
  //     if (animationUrl !== undefined) {
  //       return (
  //         <div className="edit_choose_many_image">
  //           <div className="center_container">
  //             <Lottie
  //               animationData={animation}
  //               loop={graphs ? true : false}
  //               autoplay={true}
  //               initialSegment={graphs ? undefined : [60, 60]}
  //             />
  //           </div>
  //         </div>
  //       );
  //     } else if (image !== undefined) {
  //       return (
  //         <div className="edit_choose_many_image">
  //           <div className="center_container">
  //             <img className="image" src={image} alt="image" />
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div className="edit_choose_many_image">
  //           <div className="center_container">
  //             {/* <ImageSelector imageHandler={(image: any) => imageHandler(image)} image={image} /> */}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  // };

  const renderAddButton = (currentOptions: string[]) => {
    if (currentOptions.length < 5) {
      return (
        <motion.div
          className="edit_choose_many_add_option"
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          layout
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          onClick={() => addOptionHandler()}
        >
          <div className="center_container">
            <img src={plus} />
          </div>
        </motion.div>
      );
    }
  };

  const renderOptionsButtons = () => {
    return (
      <motion.div
        layoutRoot
        className="edit_choose_many_options"
        style={{
          gridTemplateColumns:
            options.length < 5
              ? "repeat(" + (options.length + 1) + ", 1fr)"
              : "repeat(" + options.length + ", 1fr)",
        }}
      >
        {options.map((option: string, optionIndex: number) => {
          return (
            <motion.div layout className="edit_choose_many_option_container">
              <motion.div
                key={optionIndex}
                className={
                  solution[optionIndex] === 1
                    ? "edit_choose_many_option_pressed"
                    : "edit_choose_many_option"
                }
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <Textarea
                  placeholder={t("click_here_to_edit")}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    textAlign: "center",
                    fontFamily: "Causten",
                    fontSize: option.length < 20 ? "1em" : "0.7em",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  value={option}
                  resize={"none"}
                  onChange={(e) => optionHandler(e.target.value, optionIndex)}
                  onBlur={() => setSaveToDB(true)}
                />
              </motion.div>
              <div className="edit_choose_many_option_bottom">
                <div className="edit_choose_many_option_bottom_correct">
                  <div className="center_container">
                    <motion.div
                      className={
                        solution[optionIndex] === 1
                          ? "edit_choose_many_option_checkbox_marked"
                          : "edit_choose_many_option_checkbox"
                      }
                      onClick={() => solutionHandler(option, optionIndex)}
                      whileHover={{
                        scale: 1.1,
                        boxShadow:
                          solution[optionIndex] === 1
                            ? "0px 0px 10px 1px #6EE6A7"
                            : "0px 0px 10px 1px #5090F0",
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      style={{
                        cursor:
                          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      }}
                    >
                      <img
                        src={solution[optionIndex] === 1 ? tick : undefined}
                        style={{ width: "2vw" }}
                      />
                    </motion.div>
                  </div>
                </div>
                <div className="center_container">
                  <motion.div
                    className="edit_choose_many_option_bottom_delete"
                    onClick={() => deleteOptionHandler(optionIndex)}
                    whileHover={{
                      scale: 1.1,
                      boxShadow: "0px 0px 10px 1px #F0684F",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    <div className="center_container">
                      <img src={bin} style={{ height: "2vw" }} />
                    </div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          );
        })}
        {renderAddButton(options)}
      </motion.div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_choose_many_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_choose_many">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderImage()}
      {renderOptionsButtons()}
    </div>
  );
};

export default ChooseManyEdit;
