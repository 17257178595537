import "./WriteAnswers.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useAppSelector } from "../../../hooks/hooks";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import Lottie from "lottie-react";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import axios from "axios";
import { use } from "i18next";
import { Idirection } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { PlayFunction } from "use-sound/dist/types";

const pako = require("pako");

// Simple hashing function
const stringToHashConversion = (string: string) => {
  var hashVal = 0;
  if (string.length == 0) return hashVal;
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hashVal = (hashVal << 5) - hashVal + char;
    hashVal = hashVal & hashVal;
  }
  return hashVal;
};

// Component
const WriteAnswers = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const animations = mainState.animations;
  const commonX = mainState.x;
  console.log("commonX", commonX);
  const hash = stringToHashConversion(props.exercise.text || "");

  var randomLims = props.exercise.random_lims;
  if (randomLims == null) {
    randomLims = [1, 10];
  }

  const x = Math.round(
    randomLims[0] +
      (randomLims[1] - randomLims[0]) *
        parseFloat(((commonX * Math.abs(hash)) % 1).toFixed(1))
  ); // CRYPTO TECH GOING ON
  console.log(props.exercise);

  // CHECKING IF DISTRIBUTION IS UNIFORM
  // let x_vals = [];
  // let comx = 0;
  // let ex = 0;
  // for(let i = 0; i<1000; i++){
  //   comx = Math.random()
  //   ex = Math.round((props.randomLims[0] + (props.randomLims[1]-props.randomLims[0])*(comx*Math.abs(hash) % 1))*100)/100   // CRYPTO TECH GOING ON
  //   x_vals.push(ex)
  // }

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  // const [text, setText] = useState(props.exercise.text);
  // const [helpText] = useState(props.exercise.help_text);
  // const [image] = useState(props.exercise.image?.url);
  // const [animation] = useState(props.exercise.animation?.url);
  // const [labels] = useState(props.exercise.labels);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;

  const labels = props.exercise.labels as string[];
  const options = props.exercise.options as string[];

  const [answers, setAnswers] = useState<string[]>([]);
  const [maxError] = useState(props.exercise.max_error as number[]);
  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);
  const [wrongAnswer, setWrongAnswer] = useState<boolean[]>([]);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  const [helpOpen, setHelpOpen] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const [animation, setAnimation] = useState<string>("");

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const fetchGzipJSON = async (animation_url: string) => {
    if (animation_url === "") {
      return;
    }
    // let inflatedJSON = {};
    let inflatedData = {};
    try {
      // Fetch the GZIP compressed data
      const { data } = await axios.get(animation_url, {
        responseType: "arraybuffer",
        decompress: true,
      });
      // Decompress the data using pako
      // inflatedJSON = JSON.parse(pako.inflate(data, { to: "string" }));
      inflatedData = pako.inflate(data, { to: "string" });

      const json = JSON.parse(inflatedData as string);

      setAnimation(json);
    } catch (error) {
      console.error("could not fetch gzip json", error);
    }
  };

  useEffect(() => {
    if (animationUrl !== undefined) {
      fetchGzipJSON(animationUrl);
    }
  }, [animationUrl]);

  useEffect(() => {
    var newAnswers = _.fill(Array(options.length), "");

    if (props.research) {
      if (selectedExercise < maxExercise) {
        newAnswers = options;
      }
    }
    setAnswers(newAnswers);
  }, [labels]);

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    // var myAnswers: any = document.getElementsByClassName(
    //   "write_answers_write_box_input"
    // );
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      try {
        var ans = eval(answers[i]);
        var sol = eval(options[i]); // TODO - WTH is this xDDDDD
      } catch {
        ans = NaN;
        sol = NaN;
      }
      console.log(ans, sol, maxError[i] / 100);
      //var sol = eval("x**2")

      if (!isNaN(sol) && !isNaN(ans) && maxError != null) {
        //numeric answer AND solution
        if (Math.abs(ans - sol) > maxError[i] / 10) {
          correct = false;
        }
      } else {
        //something isn't numeric
        ans = answers[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        sol = options[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        if (ans != sol) {
          correct = false;
          wrongIndexes.push(i);
        }
      }
    }
    var newWrongAnswers = [...wrongAnswer];
    newWrongAnswers[i] = !correct;
    setWrongAnswer(newWrongAnswers);
    if (props.research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  const renderProfessor = () => {
    return (
      <div className="write_answers_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div
        className="write_answers_text_box"
        style={{
          width: imageExists ? "28%" : "90%",
          left: imageExists ? "2%" : "5%",
        }}
      >
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text.replaceAll("$x", x.toString())}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          whileHover={{ scale: selectedExercise === maxExercise ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="write_answers_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="write_answers_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderImage = () => {
    if (animation === "") {
      if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="write_answers_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="write_answers_image"></div>;
      }
    } else {
      if (animations) {
        return (
          <div className="write_answers_image">
            <div className="center_container">
              <Lottie
                animationData={animation}
                loop={animations ? true : false}
                autoplay={true}
                initialSegment={animations ? undefined : [60, 60]}
              />
            </div>
          </div>
        );
      } else if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="write_answers_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="write_answers_image"></div>;
      }
    }
  };

  const renderWriteBoxes = () => {
    return (
      <div
        className="write_answers_write_boxes"
        style={{
          gridTemplateColumns:
            "repeat(" + labels.length + ", " + 100 / labels.length + "%)",
        }}
      >
        {labels.map((lb: any, labelIndex: any) => {
          var label = lb;
          if (lb == null) {
            label = "";
          }
          var left = 90 - label.length * 1;
          var right = label.length * 1 + 5;
          return (
            <div
              key={labelIndex}
              className={
                // wrongAnswer[labelIndex] && props.research
                //   ? "write_answers_write_box_wrong"
                //   : "write_answers_write_box"
                incorrectFeedback === true
                  ? incorrectIndexes.includes(labelIndex)
                    ? "write_answers_write_box_wrong"
                    : "write_answers_write_box"
                  : "write_answers_write_box"
              }
              style={{ gridTemplateColumns: left + "% " + right + "%" }}
            >
              {/* <motion.input
                type="text"
                className="write_answers_write_box_input"
                value={answers[labelIndex]}
                whileHover={{ scale: 1.02 }}
                onChange={}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
              <div className="center_container">
                <div className="text_black_medium">{label}</div>
              </div> */}
              <InputGroup>
                <Input
                  variant="unstyled"
                  type="text"
                  className="write_answers_write_box_input"
                  value={answers[labelIndex]}
                  onChange={(e) => {
                    var newAnswers = [...answers];
                    newAnswers[labelIndex] = e.target.value;
                    setAnswers(newAnswers);
                  }}
                />
                {label !== "" && (
                  <InputRightAddon
                    children={label}
                    style={{
                      fontFamily: "Causten",
                      backgroundColor: "transparent",
                    }}
                  />
                )}
              </InputGroup>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBackground = () => {
    setTimeout(() => {
      setIncorrectFeedback(false);
    }, 2000);
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="write_answers_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="write_answers">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {x != null && renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {/* {image !== undefined && image !== null && image !== "" && renderImage()} */}
      {renderImage()}
      {renderWriteBoxes()}
    </div>
  );
};

export default WriteAnswers;
