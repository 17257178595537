import { useAppSelector } from "../../hooks/hooks.ts";
import { Suspense } from "react";
import { Canvas, RootState, useFrame } from "@react-three/fiber";

import GlobalMap from "./R3FMapComponents/GlobalMap.tsx";
import LocalMap from "./R3FMapComponents/LocalMap.tsx";

import { useProgress } from "@react-three/drei";
import LoadingScreen from "../loading_screen/LoadingScreen.tsx";

import { useRef } from "react";
import { useNavMesh } from "./R3FAnimations/PruebaNPC/hooks/useNavMesh.ts";
import PruebaNPC from "./R3FAnimations/PruebaNPC/PruebaNPC.tsx";

const R3FMap = ({ ...props }) => {
  const studentState = useAppSelector((state) => state.student);

  const Loader = () => {
    const { active, progress, errors, item, loaded, total } = useProgress();
    return <LoadingScreen percentage={progress} />;
  };

  const { setScene, setCamera, setRenderer } = useNavMesh();

  return (
    <>
      <Suspense fallback={<Loader />}>
        {/* <PruebaNPC /> */}
        {props.localMap && (
          <Canvas
            shadows
            frameloop={props.renderAnimation() ? "always" : "demand"}
            gl={{
              precision: "highp",
              powerPreference: "high-performance",
            }}
            camera={{
              fov: 45,
              near: 1,
              far: 150,
              position: [10, 15, -10],
              rotation: [-Math.PI / 2, 0, 0],
            }}
            onCreated={({ gl, scene, camera }: RootState): void => {
              setScene(scene);
              setCamera(camera);
              setRenderer(gl);
            }}
          >
            <LocalMap
              IntMult={
                props.isChallenge1Open() && studentState.challenge1Step < 24
                  ? 0.1
                  : 1
              }
              studentId={props.localMapStudentId}
              type={props.localMapType}
              maxDist={18}
              openLab={props.labHandler}
              openEnergyLab={props.energyLabHandler}
              toggleMap={props.localHandler}
              openWorkshop={props.workshopHandler}
              openHome={props.homeHandler}
              openChallenges={props.challengesHandler}
              houseMains={(position: number) =>
                props.toggleHouseMainsOpen(position)
              }
              renderShadow={() => props.renderShadow()}
              renderAnimation={() => props.renderAnimation()}
              tutorial={props.tutorial}
            />
          </Canvas>
        )}
      </Suspense>

      <Suspense fallback={<Loader />}>
        {!props.localMap && (
          <Canvas
            shadows
            gl={{
              precision: "highp",
              powerPreference: "high-performance",
            }}
            camera={{
              fov: 45,
              near: 1,
              far: 150,
              position: [20, 15, 15],
              // rotation: [- Math.PI/2 , 0, 0]
            }}
          >
            <GlobalMap
              maxDist={25}
              toggleMap={(studentId: number, plotId: number) =>
                props.localHandler(studentId, plotId)
              }
              classCO2={props.classCO2}
              renderShadow={() => props.renderShadow()}
              renderAnimation={() => props.renderAnimation()}
            />
          </Canvas>
        )}
      </Suspense>
    </>
  );
};

export default R3FMap;
