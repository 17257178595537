import { CourseType } from '../_newapios/content/course';
import { RegulationType } from '../_newapios/content/regulation';
import { GlobalCompetenceType } from '../_newapios/content/unit';
import { ResearchType } from '../_newapios/content/research';
import { ExperimentType } from '../_newapios/content/experiment';
import { PlotType } from '../_newapios/gamecontent/plots'
import { StudentType } from '../_newapios/user/student';
import Env from '../config/env.json'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MainState {
    environment: string,
    x: number,
    settingsOpen: boolean,
    shadows: boolean,
    animations: boolean,
    volumeMusic: number,
    volumeSounds: number,
    courses: CourseType[],
    globalCompetences: GlobalCompetenceType[],
    regulations: RegulationType[],
    plots: PlotType[],
    students: StudentType[],
    researchExercises: ResearchType | null,
    experimentExercises: ExperimentType | null,
    selectedCourseId: string,
}

const initialState: MainState = {
    environment: Env.backend_host,
    x: 0,
    settingsOpen: false,
    shadows: false,
    animations: false,
    volumeMusic: 0.2,
    volumeSounds: 0.8,
    courses: [],
    globalCompetences: [],
    regulations: [],
    plots: [],
    students: [],
    researchExercises: null,
    experimentExercises: null,
    selectedCourseId: "",
}

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        changeX: (state, action: PayloadAction<number>) => {
            state.x = action.payload
        },
        setSettingsOpen: (state, action: PayloadAction<boolean>) => {
            state.settingsOpen = action.payload
        },
        setShadows: (state, action: PayloadAction<boolean>) => {
            state.shadows = action.payload
        },
        setAnimations: (state, action: PayloadAction<boolean>) => {
            state.animations = action.payload
        },
        volumeMusic: (state, action: PayloadAction<number>) => {
            state.volumeMusic = action.payload
        },
        volumeSounds: (state, action: PayloadAction<number>) => {
            state.volumeSounds = action.payload
        },
        setCourses: (state, action: PayloadAction<CourseType[]>) => {
            state.courses = action.payload
        },
        setGlobalCompetences: (state, action: PayloadAction<GlobalCompetenceType[]>) => {
            state.globalCompetences = action.payload
        },
        setRegulations: (state, action: PayloadAction<RegulationType[]>) => {
            state.regulations = action.payload
        },
        setPlots: (state, action: PayloadAction<PlotType[]>) => {
            state.plots = action.payload
        },
        setStudents: (state, action: PayloadAction<StudentType[]>) => {
            state.students = action.payload
        },
        setResearch: (state, action: PayloadAction<ResearchType | null>) => {
            state.researchExercises = action.payload
        },
        setExperiment: (state, action: PayloadAction<ExperimentType | null>) => {
            state.experimentExercises = action.payload
        },
        setSelectedCourseId: (state, action: PayloadAction<string>) => {
            state.selectedCourseId = action.payload
        }
    }
});

export const { changeX, setSettingsOpen, setShadows, setAnimations, volumeMusic, volumeSounds, setCourses, setGlobalCompetences, setRegulations, setPlots, setStudents, setResearch, setExperiment, setSelectedCourseId } = mainSlice.actions;

export default mainSlice.reducer;