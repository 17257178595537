import "./TrueFalse.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import Lottie from "lottie-react";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import axios from "axios";
import { Idirection, IscreenSize } from "../../../interfaces";
import { motion } from "framer-motion";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";

const pako = require("pako");

const TrueFalse = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const animations = mainState.animations;

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const propSolutions = props.exercise.solutions as number[];
  const solution = propSolutions[0] as number;

  const [selectedExercise] = useState(props.exerciseIndex);
  const [maxExercise] = useState(props.maxExercise);

  // const exerciseIndex = props.exerciseIndex;
  const [research, setResearch] = useState(props.research);

  const [helpOpen, setHelpOpen] = useState(false);
  const [buttonPressed, setButtonPressed] = useState<"none" | "true" | "false">(
    "none"
  );
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const [animation, setAnimation] = useState<string>("");

  const fetchGzipJSON = async (animation_url: string) => {
    if (animation_url === "") {
      return;
    }
    // let inflatedJSON = {};
    let inflatedData = {};
    try {
      // Fetch the GZIP compressed data
      const { data } = await axios.get(animation_url, {
        responseType: "arraybuffer",
        decompress: true,
      });
      // Decompress the data using pako
      // inflatedJSON = JSON.parse(pako.inflate(data, { to: "string" }));
      inflatedData = pako.inflate(data, { to: "string" });

      const json = JSON.parse(inflatedData as string);

      setAnimation(json);
    } catch (error) {
      console.error("could not fetch gzip json", error);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (animationUrl !== undefined) {
      fetchGzipJSON(animationUrl);
      console.log(animation);
    }
  }, [animationUrl]);

  console.log(image);

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  useEffect(() => {
    if (research) {
      if (selectedExercise < maxExercise) {
        solution === 1 ? setButtonPressed("true") : setButtonPressed("false");
      }
    }
  }, []);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var solutionString = solution === 1 ? "true" : "false";
    if (research) {
      if (buttonPressed.toString() === solutionString) {
        props.updateLevel("right", [buttonPressed.toString()], true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
      }
    } else {
      if (buttonPressed.toString() === solutionString) {
        props.updateLevel("right", [buttonPressed.toString()], true);
      } else {
        props.updateLevel("right", [buttonPressed.toString()], false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const renderProfessor = () => {
    return (
      <div className="true_false_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div
        className="true_false_text_box"
        style={{
          width: imageExists ? "28%" : "90%",
          left: imageExists ? "2%" : "5%",
        }}
      >
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          whileHover={{ scale: selectedExercise === maxExercise ? 1 : 1.3 }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="true_false_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="true_false_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderBackground = () => {
    setTimeout(() => {
      setIncorrectFeedback(false);
    }, 2000);
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderImage = () => {
    if (animation === "") {
      if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="true_false_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="true_false_image"></div>;
      }
    } else {
      if (animations) {
        return (
          <div className="true_false_image">
            <div className="center_container">
              <Lottie
                animationData={animation}
                loop={animations ? true : false}
                autoplay={true}
                initialSegment={animations ? undefined : [60, 60]}
              />
            </div>
          </div>
        );
      } else if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="true_false_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="true_false_image"></div>;
      }
    }
  };

  const renderTrueFalseButtons = () => {
    return (
      <div
        className="true_false_buttons"
        style={{ left: imageExists ? "50%" : "40%" }}
      >
        <motion.div
          className={
            buttonPressed === "true"
              ? "true_false_left_button_pressed"
              : "true_false_left_button"
          }
          onClick={() => setButtonPressed("true")}
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #6EE6A7" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div className="text_white_medium_bold">{t("true")}</div>
          </div>
        </motion.div>
        <motion.div
          className={
            buttonPressed === "false"
              ? "true_false_right_button_pressed"
              : "true_false_right_button"
          }
          onClick={() => setButtonPressed("false")}
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #F0684F" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div className="text_white_medium_bold">{t("false")}</div>
          </div>
        </motion.div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="true_false_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="true_false">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {imageExists && renderImage()}
      {renderTrueFalseButtons()}
    </div>
  );
};

export default TrueFalse;
