import "./FillInTheGaps.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { Input } from "@chakra-ui/react";
import { PlayFunction } from "use-sound/dist/types";

const FillInTheGaps = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text || "";
  const mainText = props.exercise.main_text as string;
  const options = props.exercise.options as string[];

  const [answers, setAnswers] = useState<string[]>([]);
  const [maxError] = useState(props.exercise.max_error as number[]);
  const [selectedExercise] = useState(props.exerciseIndex);
  const [maxExercise] = useState(props.maxExercise);

  const [helpOpen, setHelpOpen] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [research, setResearch] = useState(props.research);
  const [attempts, setAttempts] = useState(0);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);
  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })
  // }, [])

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.fill(Array(options.length), ""));
      }
    } else {
      setAnswers(_.fill(Array(options.length), ""));
    }
  }, [options]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    // var myAnswers: any = document.getElementsByClassName(
    //   "fill_in_the_gaps_write_box_input"
    // );
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      var ans: number | string = parseFloat(answers[i]);
      var sol: number | string = parseFloat(options[i]);

      if (!isNaN(sol) && !isNaN(ans)) {
        //numeric answer AND solution
        if (Math.abs(ans - sol) > maxError[i] / 100) {
          correct = false;
        }
      } else {
        //something isn't numeric
        ans = answers[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        sol = options[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();

        if (ans != sol) {
          correct = false;
          wrongIndexes.push(i);
        }
      }
    }
    setWrongAnswer(!correct);
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const renderProfessor = () => {
    return (
      <div className="fill_in_the_gaps_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="fill_in_the_gaps_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          whileHover={{ scale: 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="fill_in_the_gaps_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="fill_in_the_gaps_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderTextBoxMain = () => {
    return (
      <div className="fill_in_the_gaps_text_box_main">
        <div className="center_container">
          {!_.isUndefined(mainText) && (
            <div
              className={
                mainText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {mainText}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderWriteBoxes = () => {
    return (
      <div
        className="fill_in_the_gaps_write_boxes"
        style={
          answers.length < 5
            ? {
                gridTemplateColumns:
                  "repeat(" +
                  answers.length +
                  ", " +
                  100 / answers.length +
                  "%)",
                height: "7%",
              }
            : {
                gridTemplateColumns: "repeat(4, 25%)",
                gridTemplateRows: "repeat(2, 50%)",
                height: "15%",
              }
        }
      >
        {answers.map((answer, answerIndex) => {
          return (
            <motion.div
              key={answerIndex}
              className={
                incorrectFeedback === true
                  ? incorrectIndexes.includes(answerIndex)
                    ? "fill_in_the_gaps_write_box_wrong"
                    : "fill_in_the_gaps_write_box"
                  : "fill_in_the_gaps_write_box"
              }
              style={answerIndex > 3 ? { marginTop: "5%" } : undefined}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
                borderRadius: "5px",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <div className="text_black_medium">{answerIndex + 1} =</div>
              </div>
              <Input
                variant="unstyled"
                style={{
                  fontFamily: "Causten",
                }}
                value={answer}
                onChange={(e) => {
                  var newAnswers = [...answers];
                  newAnswers[answerIndex] = e.target.value;
                  setAnswers(newAnswers);
                }}
              />
            </motion.div>
          );
        })}
      </div>
    );
  };

  const renderBackground = () => {
    if (incorrectFeedback) {
      setTimeout(() => {
        setIncorrectFeedback(false);
      }, 2000);
    }
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };
  console.log(helpText);
  const renderHelpMenu = () => {
    return (
      <div className="fill_in_the_gaps_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="fill_in_the_gaps">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderTextBoxMain()}
      {renderWriteBoxes()}
    </div>
  );
};

export default FillInTheGaps;
