import env from "../../config/env.json";
import { GlobalCompetenceType } from "./unit";

export const GetAllGlobalCompetencesAPI = async (): Promise<GlobalCompetenceType[]> => {
    var req = await fetch(env.backend_host + "/global_competence/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data;
}