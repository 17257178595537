import "./ChooseMany.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import Lottie from "lottie-react";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import axios from "axios";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";

const pako = require("pako");

const ChooseMany = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const animations = mainState.animations;

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const solutions = props.exercise.solutions as number[];
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [selected, setSelected] = useState([] as number[]);
  const [shuffledOptions, setShuffledOptions] = useState([] as string[]);

  const [helpOpen, setHelpOpen] = useState(false);
  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const [animation, setAnimation] = useState<string>("");

  useEffect(() => {
    setSelected(_.fill(Array(solutions.length), 0));
    setShuffledOptions(_.shuffle(options));
  }, [props.exercise.id]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    correctAnswer();
  }, []);

  const correctAnswer = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setSelected(solutions);
        setShuffledOptions(options);
      }
    }
  };

  const fetchGzipJSON = async (animation_url: string) => {
    if (animation_url === "") {
      return;
    }
    // let inflatedJSON = {};
    let inflatedData = {};
    try {
      // Fetch the GZIP compressed data
      const { data } = await axios.get(animation_url, {
        responseType: "arraybuffer",
        decompress: true,
      });
      // Decompress the data using pako
      // inflatedJSON = JSON.parse(pako.inflate(data, { to: "string" }));
      inflatedData = pako.inflate(data, { to: "string" });

      const json = JSON.parse(inflatedData as string);

      setAnimation(json);
    } catch (error) {
      console.error("could not fetch gzip json", error);
    }
  };

  useEffect(() => {
    setResearch(props.research);
  }, [props.research]);

  useEffect(() => {
    if (animationUrl !== undefined) {
      fetchGzipJSON(animationUrl);
      console.log(animation);
    }
  }, [animationUrl]);

  console.log(image);

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    var mySolution = [...solutions];
    var mySelected = [...selected];
    var correctOptions: string[] = [];
    var answeredOptions: string[] = [];

    for (let i = 0; i < mySolution.length; i++) {
      var optIndex = mySolution.findIndex((element) => element === 1); // correct solutions are when value is 1 (true)
      if (optIndex !== -1) {
        correctOptions.push(options[optIndex]);
        mySolution[optIndex] = 0;
      }
      var ansIndex = mySelected.findIndex((element) => element === 1); // index of our selected answers
      if (ansIndex !== -1) {
        answeredOptions.push(shuffledOptions[ansIndex]);
        mySelected[ansIndex] = 0;
      }
    }

    if (correctOptions.length !== answeredOptions.length) {
      correct = false; // you didn't take as many options as the one that were correct
    } else {
      for (let i = 0; i < correctOptions.length; i++) {
        var containsAnswer = answeredOptions.includes(correctOptions[i]);
        if (!containsAnswer) {
          correct = false;
        }
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answeredOptions, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        setIncorrectFeedback(true);
        props.wrongAnswerSound();
      }
    } else {
      if (correct) {
        props.updateLevel("right", answeredOptions, true);
      } else {
        props.updateLevel("right", answeredOptions, false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const optionButtonHandler = (index: number) => {
    var mySelected = [...selected];
    if (mySelected[index] === 1) {
      mySelected[index] = 0;
    } else {
      mySelected[index] = 1;
    }
    setSelected(mySelected);
  };

  const renderProfessor = () => {
    return (
      <div className="choose_many_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div
        className="choose_many_text_box"
        style={{
          width: imageExists ? "28%" : "90%",
          left: imageExists ? "2%" : "5%",
        }}
      >
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          whileHover={{ scale: selectedExercise === maxExercise ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="choose_many_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="choose_many_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderImage = () => {
    if (animation === "") {
      if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="choose_many_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="choose_many_image"></div>;
      }
    } else {
      if (animations) {
        return (
          <div className="choose_many_image">
            <div className="center_container">
              <Lottie
                animationData={animation}
                loop={animations ? true : false}
                autoplay={true}
                initialSegment={animations ? undefined : [60, 60]}
              />
            </div>
          </div>
        );
      } else if (image !== undefined && image !== null && image !== "") {
        return (
          <div className="choose_many_image">
            <div className="center_container">
              <img className="image" src={image} alt="image" />
            </div>
          </div>
        );
      } else {
        return <div className="choose_many_image"></div>;
      }
    }
  };

  const renderBackground = () => {
    setTimeout(() => {
      setIncorrectFeedback(false);
    }, 2000);
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderOptionsButtons = () => {
    return (
      <div
        className="choose_many_options"
        style={{
          gridTemplateColumns:
            "repeat(" + selected.length + ", " + 100 / selected.length + "%)",
        }}
      >
        {selected.map((selection, selectionIndex) => {
          if (selected.length !== shuffledOptions.length) return null;
          return (
            <motion.div
              key={selectionIndex}
              className={
                selection === 1
                  ? "choose_many_option_pressed"
                  : "choose_many_option"
              }
              onClick={() => optionButtonHandler(selectionIndex)}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <div
                  className={
                    selection === 1 &&
                    shuffledOptions[selectionIndex].length < 70
                      ? "text_white_small_bold"
                      : selection === 1 &&
                        shuffledOptions[selectionIndex].length >= 70
                      ? "text_white_very_small_bold"
                      : selection === 0 &&
                        shuffledOptions[selectionIndex].length < 70
                      ? "text_black_small_bold"
                      : "text_black_very_small_bold"
                  }
                  style={{ padding: "1%" }}
                >
                  {shuffledOptions[selectionIndex]}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="choose_many_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="choose_many">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {imageExists && renderImage()}
      {renderOptionsButtons()}
    </div>
  );
};

export default ChooseMany;
