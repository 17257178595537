import env from "../../config/env.json";

export interface StudentType {
    id: string,
    username: string
    password: string,
    class_id: string,
    plot_id: string | null,
    inactive?: string
}

export const CreateStudentAPI = async (username: string, password: string, pin: string): Promise<StudentType> => { // Goofy ahh request
    var req = await fetch(env.backend_host + "/student", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "body": JSON.stringify({
            username, password, pin
        }),
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();
    return data;
}

export const DeleteStudentAPI = async (studentId: string): Promise<void> => {
    var req = await fetch(env.backend_host + "/student/delete/" + studentId, {
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
    return;
}
