
import ExcelJS from "exceljs";
import { ModifiedBundleType } from "../../../_newapios/user/class.ts";
import _ from "lodash";
import {
    CourseType,
} from "../../../_newapios/content/course.ts";
import { SpecificCompetenceType } from "../../../_newapios/content/unit.ts";
import {
    specificCompetencesBooleanArray,
} from "../../../utils/competences.ts";
import { ModifiedScenarioType } from "../../../_newapios/content/scenario.ts";
import { ScenarioProgressType } from "../../../_newapios/progress/student_scenario.ts";
import { SpecificCompetencePoints } from "../../../_newapios/progress/student_specific_competences.ts";


const SubjectWorksheet = (
    workbook: ExcelJS.Workbook,
    sheetName: string,
    subject: CourseType,
    specificCompetences: SpecificCompetenceType[],
    bundles: ModifiedBundleType[],
    scenarioProgress: ScenarioProgressType[],
    t: any,
    studentSpecificCompetences: SpecificCompetencePoints
    // studentSpecificCompetences: SpecificCompetencePoints

) => {

    const subjWorksheet = workbook.addWorksheet(sheetName, {});
    const multiplier = 20;
    const labels = specificCompetences.map((sc) =>
        t(subject.acronym + sc.number + "_label", { ns: "evaluation" })
    );
    const labelCount = labels.length;

    // Apply font and border styles
    const font = {
        name: "Lato",
        family: 1,
        color: { argb: "00000000" },
        size: 10,
        bold: false,
    };

    const borderStyle: Partial<ExcelJS.Border> = {
        style: "thin",
        color: { argb: "00000000" },
    };
    const alignment: Partial<ExcelJS.Alignment> = {
        vertical: "middle",
        horizontal: "center",
    };

    function getColumnLetter(columnIndex: number): string {
        const base = 'A'.charCodeAt(0);
        let columnLetter = '';
        let tempIndex = columnIndex;

        while (tempIndex >= 0) {
            columnLetter = String.fromCharCode(base + (tempIndex % 26)) + columnLetter;
            tempIndex = Math.floor(tempIndex / 26) - 1;
        }
        return columnLetter;
    }


    const renderTableCompetences = () => {
        labels.forEach((label, index) => {

            // 1a TABLA

            const startCell = "B" + (4 + index); // Starting cell for each label
            const endCell = "C" + (4 + index); // Ending cell for each label (one column to the right)

            // Merge cells for the current label
            subjWorksheet.mergeCells(startCell, endCell);

            // Set the value of the merged cells to the current label
            const cell = subjWorksheet.getCell(startCell);
            cell.value = label;

            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "C9DAF8" },
            };
            cell.font = font;
            cell.border =
                index === labelCount - 1
                    ? {
                        bottom: { style: "thin", color: { argb: "00000000" } },
                        left: { style: "thin", color: { argb: "00000000" } },
                    }
                    : {
                        left: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
            cell.alignment = {
                vertical: "middle",
                horizontal: "left",
            };


        });

        // Iterate over the keys of each courseCompetences object
        Object.keys(studentSpecificCompetences).forEach((key, key_index) => {
            // Access the value associated with each key
            const value = studentSpecificCompetences[key];
            const startRow = 4
            const pointsCell = subjWorksheet.getCell(`F${startRow + key_index}`)
            // Log the key-value pair
            pointsCell.value = value
            pointsCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "f3f3f3" },
            };
            pointsCell.alignment = alignment;
            pointsCell.font = font;
            pointsCell.border = {
                top: { style: "thin", color: { argb: "BBBBBBBB" } }, // Add top border
                left: { style: "thin", color: { argb: "BBBBBBBB" } },
                right: { style: "thin", color: { argb: "00000000" } },
                bottom: (key_index === Object.keys(studentSpecificCompetences).length - 1) ? { style: "thin", color: { argb: "000000" } } : { style: "thin", color: { argb: "BBBBBBBB" } },
            };

            const adqCell = subjWorksheet.getCell(`D${startRow + key_index}`);
            adqCell.value = (value >= 400) ? true : false;
            adqCell.font = font;
            adqCell.alignment = alignment;
            adqCell.fill =
                adqCell.value == true
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "CBFBEC" },
                    }
                    : {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FBD2CA" },
                    };
            adqCell.value = adqCell.value == true ? "Adquirida" : "No adquirida";

            adqCell.border = {
                top: { style: "thin", color: { argb: "BBBBBBBB" } }, // Add top border
                left: { style: "thin", color: { argb: "BBBBBBBB" } },
                right: borderStyle,
                bottom: (key_index === Object.keys(studentSpecificCompetences).length - 1) ? { style: "thin", color: { argb: "000000" } } : { style: "thin", color: { argb: "BBBBBBBB" } },
            };


            const gradeAdqValue =
                value >= 600 ? 'Alto' :
                    value >= 500 ? 'Medio' :
                        value >= 400 ? 'Bajo' : 'N/A';
            const gradeAdqCell = subjWorksheet.getCell(`E${startRow + key_index}`);
            gradeAdqCell.value = gradeAdqValue;
            gradeAdqCell.font = font;
            gradeAdqCell.alignment = alignment;

            gradeAdqCell.fill =
                gradeAdqCell.value == 'Alto'
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "EAFCD9" },
                    }
                    : gradeAdqCell.value == 'Medio'
                        ? {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "FDF2CF" },
                        }
                        : gradeAdqCell.value == 'Bajo'
                            ? {
                                type: "pattern",
                                pattern: "solid",
                                fgColor: { argb: "FBD2CA" },
                            }
                            : {
                                type: "pattern",
                                pattern: "solid",
                                fgColor: { argb: "f3f3f3" },
                            };

            gradeAdqCell.border = {
                top: { style: "thin", color: { argb: "BBBBBBBB" } }, // Add top border
                left: { style: "thin", color: { argb: "BBBBBBBB" } },
                right: { style: "thin", color: { argb: "BBBBBBBB" } },
                bottom: (key_index === Object.keys(studentSpecificCompetences).length - 1) ? { style: "thin", color: { argb: "000000" } } : { style: "thin", color: { argb: "BBBBBBBB" } }, // Add bottom border
            };

        });




    }

    const renderGreyCells = (startCellRow: number) => {
        const greycell = subjWorksheet.getCell(`B${startCellRow + 2}`);
        greycell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d9d9d9" },
        };
        greycell.border = {
            left: borderStyle,
        };

        const greycell1 = subjWorksheet.getCell(`D${startCellRow + 2}`);
        greycell1.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d9d9d9" },
        };
        greycell1.border = {
            left: borderStyle,
            right: borderStyle
        };

        const greycell2 = subjWorksheet.getCell(`M${startCellRow + 2}`);
        greycell2.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d9d9d9" },
        };

        const greycell3 = subjWorksheet.getCell(`N${startCellRow + 2}`);
        greycell3.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d9d9d9" },
        };

    }

    const renderCellNamesUnitsTable = (startCellRow: number, spComplength: number, bundleName: string | undefined) => {
        // TITLE
        subjWorksheet.mergeCells(`B${startCellRow}`, `${getColumnLetter(14 + spComplength - 1)}${startCellRow}`); // the final column depends on the number of competences
        // Title style
        // Set the style for the cell containing bundle.unit?.name
        const nameCell = subjWorksheet.getCell(`B${startCellRow}`);
        nameCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fbd2ca" },
        };
        nameCell.font = font;
        nameCell.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        nameCell.alignment = alignment;
        // Set value title
        nameCell.value = bundleName;

        //Merge cells
        subjWorksheet.mergeCells(`E${startCellRow + 1}`, `G${startCellRow + 1}`);
        subjWorksheet.mergeCells(`H${startCellRow + 1}`, `L${startCellRow + 1}`);
        subjWorksheet.mergeCells(`O${startCellRow + 1}`, `${getColumnLetter(14 + spComplength - 1)}${startCellRow + 1}`);

        subjWorksheet.mergeCells(`B${startCellRow + 1}`, `C${startCellRow + 1}`);
        subjWorksheet.mergeCells(`B${startCellRow + 2}`, `C${startCellRow + 2}`);

        // LEARNING SITUATIONS (name)
        subjWorksheet.getCell(`B${startCellRow + 1}`).value = t("learning_situations");

        // Set the style for the cell containing "learning_situations"
        const learnSituCell = subjWorksheet.getCell(`B${startCellRow + 1}`);
        learnSituCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d9d2e9" },
        };
        learnSituCell.font = font;
        learnSituCell.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        learnSituCell.alignment = alignment;
        subjWorksheet.getCell(`D${startCellRow + 1}`).value = t("know_how");
        const knowHowCell = subjWorksheet.getCell(`D${startCellRow + 1}`)
        knowHowCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ead1dc" },
        };
        knowHowCell.font = font;
        knowHowCell.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        knowHowCell.alignment = alignment;

        // Research cell (name)
        subjWorksheet.getCell(`E${startCellRow + 1}`).value = t("research");
        const researchCell = subjWorksheet.getCell(`E${startCellRow + 1}`)
        researchCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "cbfbec" },
        };
        researchCell.font = font;
        researchCell.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        researchCell.alignment = {
            vertical: "middle",
            horizontal: "center",
        };

        // Experiment cell (name)
        subjWorksheet.getCell(`H${startCellRow + 1}`).value = t("experiment");
        const expCell = subjWorksheet.getCell(`H${startCellRow + 1}`);
        expCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "eafcd9" },
        };
        expCell.font = font;
        expCell.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        expCell.alignment = alignment;

        // Total cell (name)
        subjWorksheet.getCell(`M${startCellRow + 1}`).value = "Total";
        const total = subjWorksheet.getCell(`M${startCellRow + 1}`);
        total.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fce5cd" },
        };
        total.font = font;
        total.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        total.alignment = alignment;

        // Weighting cell (name)
        subjWorksheet.getCell(`N${startCellRow + 1}`).value = t("weighting");
        const weight = subjWorksheet.getCell(`N${startCellRow + 1}`);
        weight.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fce5cd" },
        };
        weight.font = font;
        weight.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        weight.alignment = alignment;

        // Competences cell (name)
        subjWorksheet.getCell(`O${startCellRow + 1}`).value = t("competences");
        const competences = subjWorksheet.getCell(`O${startCellRow + 1}`);
        competences.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fdf2cf" },
        };
        competences.font = font;
        competences.border = {
            top: borderStyle,
            left: borderStyle,
            right: borderStyle,
            bottom: borderStyle,
        };
        competences.alignment = alignment;


        subjWorksheet.getCell(`E${startCellRow + 2}`).value = t("points") + " " + t("clues").charAt(0).toLowerCase() + t("clues").slice(1);
        subjWorksheet.getCell(`F${startCellRow + 2}`).value = t("points") + " " + t("mistakes").charAt(0).toLowerCase() + t("mistakes").slice(1);
        subjWorksheet.getCell(`G${startCellRow + 2}`).value = t("obtained_points");
        // Apply font style to the cells
        subjWorksheet.getCell(`D${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`E${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`F${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`G${startCellRow + 2}`).font = font;

        subjWorksheet.getCell(`D${startCellRow + 2}`).alignment = {
            vertical: "middle",
            horizontal: "left",
        };
        subjWorksheet.getCell(`E${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`F${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`G${startCellRow + 2}`).alignment = alignment;
        // Apply border style to the left of column D and the right of column G
        subjWorksheet.getCell(`D${startCellRow + 2}`).border = {
            left: borderStyle,
        };
        subjWorksheet.getCell(`E${startCellRow + 2}`).border = {
            left: borderStyle,
        };

        subjWorksheet.getCell(`G${startCellRow + 2}`).border = {
            right: borderStyle,
            bottom: { style: "thin", color: { argb: "BBBBBBBB" } }
        };

        subjWorksheet.getCell(`G${startCellRow + 2}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'd0e7e0'
            }
        };

        subjWorksheet.getCell(`H${startCellRow + 2}`).value = t("question") + " 1";
        subjWorksheet.getCell(`I${startCellRow + 2}`).value = t("question") + " 2";
        subjWorksheet.getCell(`J${startCellRow + 2}`).value = t("question") + " 3";
        subjWorksheet.getCell(`K${startCellRow + 2}`).value = t("question") + " 4";
        subjWorksheet.getCell(`L${startCellRow + 2}`).value = t("obtained_points");

        subjWorksheet.getCell(`H${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`I${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`J${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`K${startCellRow + 2}`).font = font;
        subjWorksheet.getCell(`L${startCellRow + 2}`).font = font;

        subjWorksheet.getCell(`H${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`I${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`J${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`K${startCellRow + 2}`).alignment = alignment;
        subjWorksheet.getCell(`L${startCellRow + 2}`).alignment = alignment;

        subjWorksheet.getCell(`H${startCellRow + 2}`).border = {
            left: borderStyle,
        };
        subjWorksheet.getCell(`L${startCellRow + 2}`).border = {
            right: borderStyle,
        };
    }

    const renderSpecificCompetencesUnitTable = (competence: Boolean, startCellRow: number, columnIndex: number, scenarioIndex: number, scenariosLength: number, specificCompLength: number) => {
        const startColumn = 14
        const columnLetter = getColumnLetter(startColumn + columnIndex);
        // Get the cell for the specific competence
        const cell = subjWorksheet.getCell(`${columnLetter}${startCellRow + 3 + scenarioIndex}`);
        cell.value = `${competence}`;
        const nameCell = subjWorksheet.getCell(`${columnLetter}${startCellRow + 2}`);
        const modifiedAcronym = subject.acronym.split("_").slice(0, -1)[0]
        nameCell.value = t(modifiedAcronym + (columnIndex + 1));

        // Apply style
        cell.border = {
            left: columnIndex === 0 ? borderStyle : undefined,
            right: columnIndex === specificCompLength - 1 ? borderStyle : undefined,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };
        cell.alignment = alignment;
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: competence ? { argb: "CBFBEC" } : { argb: "FBD2CA" }, // Green for true, Red for false
        };
        cell.font = font;
        cell.value = cell.value === "true" ? "✓" : "✗";

        nameCell.border = {
            left: columnIndex === 0 ? borderStyle : undefined,
            right: columnIndex === specificCompLength - 1 ? borderStyle : undefined,

        };
        nameCell.alignment = alignment;

        nameCell.font = font;
    }

    const renderScenarioNames = (startCellRow: number, scenarioIndex: number, scenario: ModifiedScenarioType, scenariosLength: number) => {
        subjWorksheet.mergeCells(`B${startCellRow + 3 + scenarioIndex}`, `C${startCellRow + 3 + scenarioIndex}`);
        const scenariosCells = subjWorksheet.getCell(`B${startCellRow + 3 + scenarioIndex}`);
        scenariosCells.value = `${scenarioIndex + 1}. ` + scenario.name;
        scenariosCells.font = font;

        // Apply border style
        scenariosCells.border = {
            left: borderStyle,
            right: borderStyle,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },
        };


        // Apply alignment style
        scenariosCells.alignment = {
            vertical: "middle",
            horizontal: "left",
        };
    }
    const renderScenarioKnowHows = (startCellRow: number, scenarioIndex: number, scenario: ModifiedScenarioType, scenariosLength: number) => {
        const knowHowsCells = subjWorksheet.getCell(`D${startCellRow + 3 + scenarioIndex}`);
        knowHowsCells.value = scenario.know_hows[0];
        knowHowsCells.font = {
            name: "Lato",
            color: { argb: "00000000" },
            size: 8,
            bold: false,
        };
        knowHowsCells.alignment = alignment
        knowHowsCells.border = {
            left: borderStyle,
            right: borderStyle,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },
        };
    }

    const renderResearchUnitsTable = (filtScenarioProgress: ScenarioProgressType, startCellRow: number, scenarioIndex: number, scenariosLength: number) => {
        let researchHints = 0;
        let researchErrors = 0;
        let researchPoints = 0;

        if (filtScenarioProgress) {
            researchHints = filtScenarioProgress.research_progress?.exercise_progress
                .map((exp) => exp.used_hint)
                .reduce((a, b) => (a = a + (b ? 1 : 0)), 0);

            researchErrors = filtScenarioProgress.research_progress?.exercise_progress
                .map((exprog) => exprog.attempts)
                .reduce((a, b) => a + b, 0);

            researchPoints =
                filtScenarioProgress.research_progress?.completed_on !== null &&
                    filtScenarioProgress.research_progress?.completed_on !== undefined
                    ? multiplier
                    : 0;
        }
        const hintsCell = subjWorksheet.getCell(`E${startCellRow + 3 + scenarioIndex}`);
        hintsCell.value = researchHints;

        hintsCell.font = font;
        hintsCell.alignment = alignment;
        hintsCell.border = {
            left: borderStyle,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };


        const errorsCell = subjWorksheet.getCell(`F${startCellRow + 3 + scenarioIndex}`);
        errorsCell.value = researchErrors;

        errorsCell.font = font;
        errorsCell.alignment = alignment;
        errorsCell.border = {
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };

        const researchPointsCell = subjWorksheet.getCell(`G${startCellRow + 3 + scenarioIndex}`);
        researchPointsCell.value = researchPoints;

        researchPointsCell.font = font;
        researchPointsCell.alignment = alignment;
        researchPointsCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'FFE4FFF7'
            }
        };
        researchPointsCell.border = {
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };
        return researchPoints
    }

    const renderExperimentUnitsTable = (filtScenarioProgress: ScenarioProgressType, startCellRow: number, scenarioIndex: number, scenariosLength: number) => {
        let experimentProgress = undefined;
        let experimentCorrectArray = Array(4).fill(false);
        let experimentPoints = 0;

        let question1Correct = false;
        let question2Correct = false;
        let question3Correct = false;
        let question4Correct = false;

        if (filtScenarioProgress) {
            experimentProgress = filtScenarioProgress.experiment_progress
                ? filtScenarioProgress.experiment_progress[0]
                : undefined;
            if (experimentProgress) {
                question1Correct = experimentProgress.exercise_progress[0]?.correct || false;
                question2Correct = experimentProgress.exercise_progress[1]?.correct || false;
                question3Correct = experimentProgress.exercise_progress[2]?.correct || false;
                question4Correct = experimentProgress.exercise_progress[3]?.correct || false;

                // Saving each question's correctness in separate variables
                experimentCorrectArray = [question1Correct, question2Correct, question3Correct, question4Correct];

                experimentPoints =
                    experimentCorrectArray.filter((x) => x == true).length * multiplier;


            }
        };

        const q1Cell = subjWorksheet.getCell(`H${startCellRow + 3 + scenarioIndex}`);
        q1Cell.value = question1Correct;
        q1Cell.value = q1Cell.value === true ? multiplier : 0;

        q1Cell.font = font;
        q1Cell.alignment = alignment;
        q1Cell.border = {
            left: borderStyle,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };

        const q2Cell = subjWorksheet.getCell(`I${startCellRow + 3 + scenarioIndex}`);
        q2Cell.value = question2Correct;
        q2Cell.value = q2Cell.value === true ? multiplier : 0;

        q2Cell.font = font;
        q2Cell.alignment = alignment;
        q2Cell.border = {
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };

        const q3Cell = subjWorksheet.getCell(`J${startCellRow + 3 + scenarioIndex}`);
        q3Cell.value = question3Correct;
        q3Cell.value = q3Cell.value === true ? multiplier : 0;

        q3Cell.font = font;
        q3Cell.alignment = alignment;
        q3Cell.border = {
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };

        const q4Cell = subjWorksheet.getCell(`K${startCellRow + 3 + scenarioIndex}`);
        q4Cell.value = question4Correct;
        q4Cell.value = q4Cell.value === true ? multiplier : 0;

        q4Cell.font = font;
        q4Cell.alignment = alignment;
        q4Cell.border = {
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };

        const expPointsCell = subjWorksheet.getCell(`L${startCellRow + 3 + scenarioIndex}`);
        expPointsCell.value = experimentPoints;

        expPointsCell.font = font;
        expPointsCell.alignment = alignment;
        expPointsCell.border = {
            right: borderStyle,
            bottom:
                scenarioIndex === scenariosLength - 1
                    ? { style: "thin", color: { argb: "00000000" } }
                    : { style: "thin", color: { argb: "BBBBBBBB" } },

        };


        return experimentPoints
    }


    // 2a TABLA
    const renderTableUnits = () => {


        let startRowTab2 = 12 + (labelCount) - 4;

        let previousScenarioLength = 0;

        let startCellRow = startRowTab2;
        bundles.filter((bun) => bun.unit?.course_id === subject.id).forEach((bundle, i) => {
            //for each unit (bundle)
            const scenarios = bundle.unit?.scenarios || [];

            if (scenarios && scenarios.length > 0) {

                // const startCellRow = startRowTab2 + (scenarios.length + 3) * i
                if (i === 0) {
                    startCellRow = startRowTab2 + previousScenarioLength;
                } else {
                    startCellRow = startRowTab2 + previousScenarioLength + 3;
                }
                renderCellNamesUnitsTable(startCellRow, specificCompetences.length, bundle.unit?.name)

                renderGreyCells(startCellRow)

                previousScenarioLength = scenarios.length
                const scLength = scenarios.length
                scenarios.forEach((scenario: ModifiedScenarioType, j) => {
                    //for each learning situation

                    renderScenarioNames(startCellRow, j, scenario, scLength)

                    renderScenarioKnowHows(startCellRow, j, scenario, scLength)


                    const filtScenarioProgress = scenarioProgress.filter((scPr) => scPr.scenario_id === scenario.id)[0];

                    let researchPoints = 0;
                    researchPoints = renderResearchUnitsTable(filtScenarioProgress, startCellRow, j, scLength);

                    let experimentPoints = 0;
                    experimentPoints = renderExperimentUnitsTable(filtScenarioProgress, startCellRow, j, scLength);

                    let scenarioPoints = 0;
                    scenarioPoints = researchPoints + experimentPoints;

                    const totalPointsCell = subjWorksheet.getCell(`M${startCellRow + 3 + j}`);
                    totalPointsCell.value = scenarioPoints;
                    totalPointsCell.font = font;
                    totalPointsCell.alignment = alignment;
                    totalPointsCell.border = {
                        bottom:
                            j === scenarios.length - 1
                                ? { style: "thin", color: { argb: "00000000" } }
                                : { style: "thin", color: { argb: "BBBBBBBB" } },

                    };

                    const weightingCell = subjWorksheet.getCell(`N${startCellRow + 3 + j}`);
                    weightingCell.value = 1;
                    weightingCell.font = font;
                    weightingCell.alignment = alignment;
                    weightingCell.border = {
                        right: borderStyle,
                        bottom:
                            j === scenarios.length - 1
                                ? { style: "thin", color: { argb: "00000000" } }
                                : { style: "thin", color: { argb: "BBBBBBBB" } },

                    };

                    const selectedSpecificCompetences = specificCompetencesBooleanArray(
                        scenario.specific_competences_ids,
                        specificCompetences
                    );

                    selectedSpecificCompetences.forEach((competence, columnIndex) => {
                        renderSpecificCompetencesUnitTable(competence, startCellRow, columnIndex, j, scenarios.length, selectedSpecificCompetences.length)

                    });
                });
            }

            startRowTab2 = startCellRow;
        });

    }


    subjWorksheet.columns = [
        //A
        {
            width: 14.5,
        },
        //B
        {
            key: "title",
            width: 14.5,
        },
        //C
        {
            width: 26.35,
        },
        //D
        {
            key: "adq",
            width: 35,
        },
        //E
        {
            key: "adqGrade",
            width: 20.4,
        },
        //F
        {
            key: "points",
            width: 16,
        },
        //G
        {
            width: 25,
        },
        //H
        {
            width: 14.5,
        },
        //I
        {
            width: 14.5,
        },
        //J
        {
            width: 14.5,
        },
        //K
        {
            width: 14.5,
        },
        //L
        {
            width: 14.5,
        },
        //M
        {
            width: 14.5,
        },
        //N
        {
            width: 14.5,
        },
        //O
        {
            width: 14.5,
        },
        //P
        {
            width: 14.5,
        },
        //Q
        {
            width: 14.5,
        },
        //R
        {
            width: 14.5,
        },
        //S
        {
            width: 14.5,
        },
        //T
        {
            width: 14.5,
        },
        //U
        {
            width: 14.5,
        },
        //V
        {
            width: 14.5,
        },
        //W
        {
            width: 14.5,
        },
        //X
        {
            width: 14.5,
        },
        //Y
        {
            width: 14.5,
        },
        //Z
        {
            width: 14.5,
        },

    ];

    subjWorksheet.mergeCells("B2", "F2");
    subjWorksheet.getCell("B2").value = t("end_profile");
    subjWorksheet.getCell("B3").value = t("key_competences");
    subjWorksheet.getCell("D3").value = t("adq?");
    subjWorksheet.getCell("E3").value = t("adq_grade");
    subjWorksheet.getCell("F3").value = t("points");

    subjWorksheet.mergeCells("B3", "C3");

    // "PERFIL DE SALIDA"
    const titleRow = subjWorksheet.getRow(2);

    titleRow.eachCell(function (cell, colNumber) {
        if (colNumber > 1 && colNumber < 3) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "4A86E8" },
            };
            cell.font = font;
            cell.border = {
                top: { style: "thin", color: { argb: "00000000" } },
                left: { style: "thin", color: { argb: "00000000" } },
                right: { style: "thin", color: { argb: "00000000" } },
                bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
            };
            cell.alignment = alignment;
        }
    });

    // NOMBRES DE CADA COLUMNA
    const headersRow = subjWorksheet.getRow(3);

    headersRow.eachCell(function (cell, colNumber) {
        if (colNumber < 8) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "6D9EEB" },
            };
            cell.font = font;
            cell.border =
                colNumber == 3
                    ? {
                        left: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    }
                    : colNumber == 6
                        ? {
                            right: { style: "thin", color: { argb: "00000000" } },
                            left: { style: "thin", color: { argb: "BBBBBBBB" } },
                            bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        }
                        : {
                            left: { style: "thin", color: { argb: "BBBBBBBB" } },
                            bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        };
            cell.alignment = alignment;
        }
    });

    const adqCol = subjWorksheet.getColumn(4);

    adqCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < labelCount + 4) {
            cell.fill =
                cell.value == true
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "CBFBEC" },
                    }
                    : {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FBD2CA" },
                    };
            cell.alignment = alignment;
            cell.font = font;
            cell.border =
                rowNumber == 3 + labelCount
                    ? {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "00000000" } },
                    }
                    : {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
            cell.value = cell.value === true ? "Adquirida" : "No adquirida";
        }
    });

    const adqGradeCol = subjWorksheet.getColumn(5);

    adqGradeCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < labelCount + 4) {
            cell.fill =
                cell.value == 2 // Alto
                    ? {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "EAFCD9" },
                    }
                    : cell.value == 1 // Medio
                        ? {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "FDF2CF" },
                        }
                        : cell.value == 0 // Bajo
                            ? {
                                type: "pattern",
                                pattern: "solid",
                                fgColor: { argb: "FBD2CA" },
                            }
                            : {
                                type: "pattern",
                                pattern: "solid",
                                fgColor: { argb: "f3f3f3" },
                            };
            cell.font = {
                name: "Lato",
                family: 1,
                color:
                    cell.value == 2 || cell.value == 1 || cell.value == 0
                        ? { argb: "00000000" }
                        : { argb: "ffffffff" },
                size: 10,
                bold: false,
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.border =
                rowNumber == 3 + labelCount
                    ? {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "00000000" } },
                    }
                    : {
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
            switch (cell.value) {
                case 0:
                    cell.value = "Bajo";
                    break;
                case 1:
                    cell.value = "Medio";
                    break;
                case 2:
                    cell.value = "Alto";
                    break;
            }
        }
    });

    const pointsCol = subjWorksheet.getColumn(6);

    // iterate over all current cells in this column
    pointsCol.eachCell((cell, rowNumber) => {
        if (rowNumber > 3 && rowNumber < labelCount + 4) {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "f3f3f3" },
            };
            cell.alignment = alignment;
            cell.font = font;
            cell.border =
                rowNumber == 3 + labelCount
                    ? {
                        bottom: { style: "thin", color: { argb: "00000000" } },
                        right: { style: "thin", color: { argb: "00000000" } },
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                    }
                    : {
                        right: { style: "thin", color: { argb: "00000000" } },
                        bottom: { style: "thin", color: { argb: "BBBBBBBB" } },
                        left: { style: "thin", color: { argb: "BBBBBBBB" } },
                    };
        }
    });
    renderTableCompetences()
    renderTableUnits()

    return subjWorksheet;
};

export default SubjectWorksheet;