import "./Alert.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyMotion, m, motion } from "framer-motion";

import close from "../../assets/student/close_user_menu.svg";
import { IscreenSize } from "../../interfaces";

const Alert = ({ ...props }) => {
    const { t } = useTranslation();

    const loadFeatures = () => import("../../hooks/framerMotionHooks.ts").then((res) => res.default);


    return (
        <div
            className="alert_menu_background"
        >
            <LazyMotion features={loadFeatures}>
                <div className="center_container">
                    <div className="alert_menu_container">
                        <div className="alert_menu">
                            <div className="alert_menu_text">
                                <div className="center_container">
                                    <div className="text_black_medium">
                                        {t(props.text)}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="alert_menu_close"
                                onClick={() => props.handleAlert()}
                            >
                                <m.img
                                    className="icon"
                                    src={close}
                                    alt="close"
                                    whileHover={{ scale: 1.1, rotate: 180 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                />
                            </div>

                            <div className="alert_menu_buttons">
                                <div className="center_container">
                                    <m.div
                                        className="alert_menu_button_ok"
                                        whileHover={{ scale: 1.1 }}
                                        onClick={() => props.handleAlert()}
                                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                                    >
                                        <div className="center_container">
                                            <div className="text_white_small_bold">{t("ok")}</div>
                                        </div>
                                    </m.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyMotion>
        </div>
    );

};
export default Alert;
