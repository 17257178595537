import "./Calendar12.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { colors } from "../../../constants.ts";
import { BundleType } from "../../../_newapios/user/class.ts";
import { useAppSelector } from "../../../hooks/hooks.ts";

const Calendar12 = ({ ...props }) => {
  const [dates, setDates] = useState<Date[][]>([]);
  const [bundles, setBundles] = useState<BundleType[]>();

  const courses = useAppSelector((state) => state.main.courses);

  const { t } = useTranslation();

  useEffect(() => {
    setDates(getAllMonthsInYear(props.year));
  }, [props.year]);

  useEffect(() => {
    setBundles(props.bundles);
  }, [props.bundles]);

  const monthTitles = [
    { name: t("january") },
    { name: t("february") },
    { name: t("march") },
    { name: t("april") },
    { name: t("may") },
    { name: t("june") },
    { name: t("july") },
    { name: t("august") },
    { name: t("september") },
    { name: t("october") },
    { name: t("november") },
    { name: t("december") },
  ];

  const weekDaysTitles = [
    { name: t("monday_abreviation") },
    { name: t("tuesday_abreviation") },
    { name: t("wednesday_abreviation") },
    { name: t("thursday_abreviation") },
    { name: t("friday_abreviation") },
    { name: t("saturday_abreviation") },
    { name: t("sunday_abreviation") },
  ];

  const getAllMonthsInYear = (year: number) => {
    const dates = [];
    for (var i = 0; i < 12; i++) {
      dates.push(getAllDaysInMonth(year, i));
    }

    return dates;
  };

  const getAllDaysInMonth = (year: number, month: number) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const sendBundles = (bundlesInDay: string[] | null) => {
    props.getHoveredDayBundles(bundlesInDay);
  };

  const renderCalendarMonth = (month: Date[], monthIndex: number) => {
    var week = -1;
    return (
      <div key={monthIndex} className="calendar_12_month">
        <div className="calendar_12_month_top">
          <div className="left_container">
            <div className="text_light_grey_super_small_bold">
              {monthTitles[monthIndex].name}
            </div>
          </div>
        </div>
        <div className="calendar_12_month_bottom">
          {weekDaysTitles.map((weekDay, weekDayIndex) => {
            return renderWeekDayTitles(weekDay, weekDayIndex);
          })}
          {month.map((monthDay, monthDayIndex) => {
            if (week === -1) {
              week = 0;
            } else if (monthDay.getDay() === 1) {
              week += 1;
            }

            let bundlesInSameDay: BundleType[] = [];

            if (bundles !== undefined && bundles !== null) {
              for (var i = 0; i < bundles.length; i++) {
                let startingTime = new Date(bundles[i].start_date);
                startingTime.setHours(0, 0, 0);
                let endingTime = new Date(bundles[i].end_date);
                endingTime.setHours(23, 59, 59);
                monthDay.setMinutes(monthDay.getMinutes(), 5);
                if (
                  monthDay.getTime() >= startingTime.getTime() &&
                  monthDay.getTime() <= endingTime.getTime()
                ) {
                  bundlesInSameDay.push(bundles[i]);
                }
              }
            }
            return renderDay(
              monthDay,
              monthDayIndex,
              week,
              monthDay.getDay() === 0 ? 6 : monthDay.getDay() - 1,
              bundlesInSameDay
            );
          })}
        </div>
      </div>
    );
  };

  const renderWeekDayTitles = (weekDay: any, weekDayIndex: number) => {
    return (
      <div key={weekDayIndex} className="center_container">
        <div className="text_light_grey_super_small">{weekDay.name}</div>
      </div>
    );
  };

  const renderDay = (
    monthDay: Date,
    monthDayIndex: number,
    positionY: number,
    positionX: number,
    bundles: BundleType[]
  ) => {
    var dayColor = "#ffffff";
    var bundlesInDay: string[] = [];

    if (bundles !== undefined && bundles !== null && bundles.length > 0) {
      var percentage = 100 / bundles.length;
      var currentPercentage = 0;
      dayColor = "linear-gradient(to bottom";
      for (var i = 0; i < bundles.length; i++) {
        bundlesInDay.push(bundles[i].id);
        var unitCourse = courses.find(
          (course) => course.id === bundles[i].unit?.course_id
        );
        dayColor +=
          ", " + unitCourse?.color + "75" + " " + currentPercentage + "%,";
        currentPercentage += percentage;
        dayColor += unitCourse?.color + "75" + " " + currentPercentage + "%";
      }
      dayColor += ")";
    }
    const today = new Date();
    let isToday =
      monthDay.getDate() === today.getDate() &&
      monthDay.getMonth() === today.getMonth() &&
      monthDay.getFullYear() === today.getFullYear();
    return (
      <div
        key={monthDayIndex}
        className="center_container"
        style={{
          gridArea: "day" + positionY.toString() + positionX.toString(),
          background: dayColor,
          border: isToday ? "2px solid #000000" : "2px solid #ffffff",
        }}
        onMouseEnter={() => {
          sendBundles(bundlesInDay);
        }}
        onMouseLeave={() => {
          sendBundles(null);
        }}
      >
        <div className="text_dark_grey_super_small">{monthDay.getDate()}</div>
      </div>
    );
  };

  return (
    <div className="calendar_12">
      {dates.map((month, monthIndex) => {
        return renderCalendarMonth(month, monthIndex);
      })}
    </div>
  );
};

export default Calendar12;
