import "./Professor.css";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import ProfessorSidebar from "./ProfessorSidebar";
import { useTranslation } from "react-i18next";
import ProfessorWelcome from "./ProfessorWelcome";
import MainSettings from "./class_creation/MainSettings";
import ProfessorMenus from "./menus/ProfessorMenus";
import Marketplace from "./marketplace/Marketplace";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { GetAuthorsAPI } from "../../_newapios/user/teacher";
import { setAuthors } from "../../reducers/teacherSlice";
import BusinessView from "./BusinessView/BusinessView";
import { GetAllUnitsGPTAPI } from "../../_newapios/content/unit";

const ProfessorDashboard = () => {
  const { i18n } = useTranslation();

  const professorState = useAppSelector((state) => state.teacher);
  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const getScenariosForGPT = async () => {
    const unitsGPT = await GetAllUnitsGPTAPI();
    console.log(unitsGPT);
  };

  const loadAuthors = async () => {
    const authors = await GetAuthorsAPI();
    console.log(authors);
    dispatch(setAuthors(authors));
  };

  useEffect(() => {
    loadAuthors();
    getScenariosForGPT();
  }, []);

  return (
    <div
      className={
        professorState.sidebarOpen
          ? "professor_sidebar_open"
          : "professor_sidebar_closed"
      }
    >
      {professorState.sidebarOpen && <ProfessorSidebar />}
      {professorState.menu === "welcome" && <ProfessorWelcome />}
      {/* {professorState.displayExercise != null &&
        ((professorState.displayExercise.research && (
          <ExercisesResearch
            exercises={professorState.displayExercise.exercises}
            progress={professorState.displayExercise.progress}
            updateMaxPageResearch={() => null}
            updateMaxLevelResearch={() => null}
            backHandler={() => {
              dispatch(toggleDisplayExercise(null));
              i18n.changeLanguage(cookies.get("lang"));
            }}
          />
        )) || (
          <ExercisesExperiment
            exercises={professorState.displayExercise.exercises}
            progress={{ experiment: { completed: true } }}
            updateMaxPageExperiment={() => null}
            updateMaxLevelExperiment={() => null}
            updateCompletedExperiment={() =>
              dispatch(toggleDisplayExercise(null))
            }
            backHandler={() => {
              dispatch(toggleDisplayExercise(null));
              i18n.changeLanguage(cookies.get("lang"));
            }}
          />
        ))} */}
      {professorState.menu === "classCreate" &&
        professorState.createClassStep === 0 && <MainSettings />}
      {professorState.menu === "menus" && <ProfessorMenus />}
      {professorState.menu === "marketplace" && <Marketplace />}
      {professorState.menu === "businessView" && <BusinessView />}
    </div>
  );
};

export default ProfessorDashboard;
