import "./Media.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import greenBackground from "../../../assets/exercises/greenbackgroundexercise.svg";
import { Idirection } from "../../../interfaces";
import ReactPlayer from "react-player";
import { ExerciseType } from "../../../_newapios/content/unit";

const Media = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  maxExercise: number;
  exerciseIndex: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
}) => {
  const { i18n } = useTranslation();

  const text = props.exercise.text;
  const maxExercise = props.maxExercise;
  const selectedExercise = props.exerciseIndex;

  const [levels, setLevels] = useState(props.totalExercises);
  const [research, setResearch] = useState(props.research);

  // const url: string =
  //   "https://www.youtube.com/watch?v=k-RoPmzJ0sw&ab_channel=EmiliaMusicVEVO";

  // const videoid: RegExpMatchArray | null = url.match(
  //   /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  // );

  // if (videoid != null) {
  //   console.log("video id = ", videoid[1]);
  // } else {
  //   console.log("The youtube url is not valid.");
  // }

  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })

  //   lottie.loadAnimation({
  //     container: greenAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: greenBackground
  //   })
  // }, [props.selectedPage])

  useEffect(() => {
    setResearch(props.research);
  }, [props.research]);

  const arrowsHandler = (direction: Idirection) => {
    if (selectedExercise < maxExercise) {
      props.updateLevel(direction, null, null);
    } else {
      props.updateLevel(direction, [""], true);
    }
  };

  const renderProfessor = () => {
    return (
      <div className="media_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  //   const renderTextBox = () => {
  //     console.log(text)
  //     return(
  //       <div className = "media_text_box">
  //         <div className = "center_container">
  //           <div className = {text?.length < 265 ? "text_black_medium": "text_black_small"}>
  //             {text}
  //           </div>
  //         </div>
  //       </div>
  //     )
  //  }
  const renderMedia = () => {
    console.log(text);
    return (
      <div className="media_text_box">
        <div className="center_container">
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={text}
            playing={true}
            controls={true}
          />
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="media_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{
            scale: selectedExercise === 0 ? 1 : 1.3,
          }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
          }}
        >
          <img
            className="icon"
            src={
              research
                ? selectedExercise === 0
                  ? leftTransparent
                  : left
                : leftTransparent
            }
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === levels - 1
              ? () => {}
              : () => arrowsHandler("right")
          }
          whileHover={{ scale: 1.3 }}
          style={{
            cursor:
              selectedExercise === levels - 1
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === levels - 1 ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  return (
    <div className="media">
      <img className="background" src={blueBackground} alt="background" />
      {renderMedia()}
      {research && renderArrows()}
    </div>
  );
};

export default Media;
