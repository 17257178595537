import env from "../../config/env.json";
import { EvaluationCriterionType, SpecificCompetenceType } from "./unit";

export interface CourseType {
    id: string,
    acronym: string,
    color: string,
    regulation_id: string,
    specific_competences: SpecificCompetenceType[]
}

export const GetAllCoursesAPI = async (): Promise<CourseType[]> => {
    var req = await fetch(env.backend_host + "/course/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data;
}

export const GetCourseEvaluationCriteriaAPI = async (id: string): Promise<EvaluationCriterionType[]> => {
    var req = await fetch(env.backend_host + "/course/" + id + "/evaluation_criteria", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data
}

export const GetCourseSpecificCompetencesAPI = async (id: string): Promise<SpecificCompetenceType[]> => {
    var req = await fetch(env.backend_host + "/course/" + id + "/specific_competences", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data
}