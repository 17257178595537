import "./Student.css";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks.ts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSound } from "use-sound";
import {
  kilowatts,
  co2,
  population,
  menu,
  setChallenge1Step,
  setChallengeBlackOut,
  points,
  updateMetrics,
  setTutorialStep,
  increaseTutorialStep,
} from "../../reducers/studentSlice.ts";

import R3FMap from "./R3FMap.tsx";

import TutorialGlobalMap from "./Tutorials/TutorialGlobalMap.tsx";
import TutorialLocalMap from "./Tutorials/TutorialLocalMap.tsx";

import Challenge1 from "./Challenges/ChallengeBlackOut.tsx";
import EnergyLab from "./EnergyLab.tsx";
import Campus from "./Campus/Campus.tsx";
import Workshop from "./Workshop.tsx";
import Challenges from "./Challenges.tsx";
import MyHome from "./MyHome.tsx";

import Settings from "../settings/Settings.tsx";

import kilowattsIcon from "../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../assets/student/population_icon.svg";
import pointsIcon from "../../assets/student/points_icon.svg";
import StudentUserButton from "../../assets/student/student_user.svg";
import co2Icon from "../../assets/student/co2_icon.svg";
import settingsIcon from "../../assets/settings.svg";
import close from "../../assets/student/close_user_menu.svg";
import co2Danger from "../../assets/student/CO2_danger.svg";

import { LogoutAPI } from "../../_newapios/auth/local.ts";
import InfoBar from "./InfoBar/InfoBar.tsx";
import CO2Bar from "./CO2Bar/CO2Bar.tsx";
import VideoPlayer from "./Videoplayer.tsx";
import { motion } from "framer-motion";
import { volumeMusic, volumeSounds } from "../../reducers/mainSlice.ts";
import { BiomeType, PlotType } from "../../_newapios/gamecontent/plots.ts";
import ParcelaSelector from "./R3FMapComponents/GlobalComponents/ParcelaSelector.tsx";
import {
  GetClassCO2API,
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
  GetStudentPointsAPI,
  ClassCO2Type,
} from "../../_newapios/progress/student_game_metrics.ts";
import Leaderboard from "./Leaderboard/Leaderboard.tsx";
import { calcTutorialPart } from "../../utils/tutorial.ts";

// TODO - MEGA TODO: HANDLE PROGRESS USING REDUX

const Student = ({}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const studentState = useAppSelector((state) => state.student);
  const loginState = useAppSelector((state) => state.login);
  const mainState = useAppSelector((state) => state.main);

  const me = mainState.students.find((student) => {
    return student.id === loginState.id;
  });

  const [localMap, setLocalMap] = useState<boolean>(false);
  const [localMapStudentId, setLocalMapStudentId] = useState<
    string | undefined
  >();
  const [localMapBiome, setLocalMapBiome] = useState<BiomeType>("grassland");
  const [userMenu, setUserMenu] = useState<boolean>(false);
  const [co2Menu, setco2Menu] = useState<boolean>(false);
  // const [openBoardAndNotices, setOpenBoardAndNotices] = useState<boolean>(true)

  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const [tutorial, setTutorial] = useState<string>("none");

  const [classCO2, setClassCO2] = useState<ClassCO2Type>({
    class_co2: 0,
    class_size: 0,
  });

  const [playMainTheme, setPlayMainTheme] = useState<boolean>(false);

  const [mainThemePlay, { pause: mainThemePause, stop }] = useSound(
    "./Demo01+-+Theme+-+mp3.mp3",
    {
      volume: mainState.volumeMusic,
      loop: true,
    }
  );

  const [playExerciseTheme, setPlayExerciseTheme] = useState(true);

  const [exerciseTheme, { pause: exerciseThemePause }] = useSound(
    "./exercise+music+-+05.mp3",
    {
      volume: mainState.volumeSounds,
      loop: true,
    }
  );
  useEffect(() => {
    if (
      mainState.researchExercises === null &&
      mainState.experimentExercises === null
    ) {
      setPlayMainTheme(true);
      setPlayExerciseTheme(false);
    } else {
      setPlayMainTheme(false);
      setPlayExerciseTheme(true);
    }
  }, [mainState.researchExercises, mainState.experimentExercises]);

  const [initialSoundPlay] = useSound("./Sintonia+inicial+-+01.wav", {
    autoplay: true,
    volume: mainState.volumeSounds,
    onplay: () => {
      setPlayMainTheme(false);
    },
    onend: () => {
      setPlayMainTheme(true);
    },
  });

  useEffect(() => {
    if (playMainTheme) {
      mainThemePlay();
    } else {
      mainThemePause();
    }
    if (playExerciseTheme) {
      exerciseTheme();
    } else {
      exerciseThemePause();
    }
  }, [playMainTheme, playExerciseTheme]);

  const [goToLocalSound] = useSound("./Go+to+local+-+01.wav", {
    volume: mainState.volumeSounds,
    onplay: () => setPlayMainTheme(false),
    onend: () => setPlayMainTheme(true),
  });
  const [goToGlobalSound] = useSound("./Go+to+global+-+01.wav", {
    volume: mainState.volumeSounds,
    onplay: () => setPlayMainTheme(false),
    onend: () => setPlayMainTheme(true),
  });

  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [playVideoControls, setPlayVideoControls] = useState<boolean>(false);

  useEffect(() => {
    loadProgress();
  }, [studentState.updateMetrics]);

  useEffect(() => {
    calcTutorial();
  }, [
    localMapStudentId,
    studentState.tutorialStep,
    studentState.menu,
    me?.plot_id,
  ]);

  const calcTutorial = async () => {
    const end =
      studentState.tutorialStep > 10 && studentState.tutorialStep < 13;
    const tutorialPart = await calcTutorialPart(me, localMapStudentId, end);
    if (studentState.tutorialStep === 0) {
      switch (tutorialPart) {
        case "none":
          break;
        case "choose_plot":
          dispatch(setTutorialStep(0));
          break;
        case "campus":
          dispatch(setTutorialStep(3));
          break;
        case "energy_lab":
          dispatch(setTutorialStep(7));
          break;
        case "workshop":
          dispatch(setTutorialStep(9));
          break;
        default:
          break;
      }
    }
    setTutorial(tutorialPart);
  };

  const loadProgress = async () => {
    if (me === undefined) return;

    var total_watts = await GetStudentWattsAPI("me");
    var tmpco2 = await GetStudentCO2API("me");
    var pop = await GetStudentPopulationAPI("me");
    var class_co2 = await GetClassCO2API("me");
    var temp_points = await GetStudentPointsAPI("me");

    dispatch(kilowatts(total_watts));
    dispatch(co2(tmpco2));
    dispatch(population(pop));
    setClassCO2(class_co2);
    dispatch(points(temp_points || 0));

    if (me.plot_id === undefined) {
      setPlayVideo(true);
    }
  };

  const videoPlayerBackHandler = () => {
    setPlayVideo(false);
    setPlayVideoControls(false);
    dispatch(volumeMusic(0.2));
    dispatch(volumeSounds(0.8));
  };

  const renderKiloWatts = () => {
    return (
      <div className="student_kilowatts">
        <div className="center_container">
          <img className="image" src={kilowattsIcon} alt="kilowatts" />
        </div>
        <div className="center_container">
          <div className="text_white_small_bold">
            {Math.round(studentState.kilowatts * 100) / 100}
          </div>
        </div>
        <div className="center_container">
          <div className="text_yellow_small_bold">{t("kilowatts")}</div>
        </div>
      </div>
    );
  };

  const renderCO2 = () => {
    return (
      <div className="student_co2">
        <div className="center_container">
          <img className="image" src={co2Icon} alt="co2" />
        </div>
        <div className="center_container">
          <div className="text_white_small_bold">
            {Math.round(studentState.co2 * 100) / 100}
          </div>
        </div>
        <div className="center_container">
          <div className="text_grey_small_bold">{t("t_co2")}</div>
        </div>
      </div>
    );
  };

  const renderPopulation = () => {
    return (
      <div
        className="student_population"
        style={
          studentState.co2 >= 200 ||
          classCO2.class_co2 >= 200 * classCO2.class_size
            ? { gridTemplateColumns: "15% 25% 10% 50%", width: "15%" }
            : { gridTemplateColumns: "15% 25% 60%", width: "12%" }
        }
        onMouseEnter={() => setco2Menu(true)}
        onMouseLeave={() => setco2Menu(false)}
      >
        <div className="center_container">
          <img className="image" src={populationIcon} alt="population" />
        </div>
        <div className="center_container">
          <div className="text_white_small_bold">
            {Math.round(studentState.population)}
          </div>
        </div>
        {(studentState.co2 >= 200 ||
          classCO2.class_co2 >= 200 * classCO2.class_size) && (
          <div className="center_container">
            {<img className="image" src={co2Danger} alt="co2_danger" />}
          </div>
        )}
        <div className="center_container">
          <div className="text_light_green_very_small_bold">
            {t("population")}
          </div>
        </div>
      </div>
    );
  };

  const renderPopulationLoss = () => {
    var studentDisc = Math.round((25 / 1300) * (studentState.co2 - 200));
    if (studentDisc < 0) {
      studentDisc = 0;
    }
    console.log(classCO2.class_co2);
    var classDisc = Math.round(
      (25 / (1300 * classCO2.class_size)) *
        (classCO2.class_co2 - 200 * classCO2.class_size)
    );
    if (classDisc < 0) {
      classDisc = 0;
    }
    return (
      <div className="student_population_loss">
        <div className="student_population_loss_student">
          <div className="right_container">
            <div className="text_white_super_small_bold">
              {t("population_loss_student")}
            </div>
          </div>
          <div className="center_container">
            <div
              className={
                studentDisc !== 0
                  ? "text_red_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {studentDisc + "%"}
            </div>
          </div>
        </div>
        <div className="student_population_loss_class">
          <div
            className="right_container text_white_super_small_bold"
            style={{ whiteSpace: "nowrap" }}
          >
            {t("population_loss_class")}
          </div>
          <div className="center_container">
            <div
              className={
                classDisc != 0
                  ? "text_red_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {classDisc + "%"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPoints = () => {
    return (
      <div className="student_points">
        <div className="center_container">
          <img className="image" src={pointsIcon} alt="points" />
        </div>
        <div className="center_container">
          <div className="text_white_small_bold">{studentState.points}</div>
        </div>
        <div className="center_container">
          <div className="text_light_blue_very_small_bold">
            {t("points_label")}
          </div>
        </div>
      </div>
    );
  };

  const renderUserMenu = () => {
    return (
      <div className="user_menu_background_student">
        <div className="user_menu">
          <div className="user_menu_close" onClick={() => menuHandler()}>
            <div className="right_container">
              <motion.img
                whileHover={{ scale: 1.3, rotate: 180 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 10,
                  duration: 1,
                }}
                className="image"
                src={close}
                alt="close"
              />
            </div>
          </div>
          <div className="center_container">
            <div className="user_menu_icon" style={{ top: "10%" }}>
              <div className="center_container">
                <div className="text_white_big_bold">
                  {loginState.username.charAt(0).toUpperCase()}
                </div>
              </div>
            </div>
          </div>
          <div className="user_menu_username">
            <div className="center_container">
              <div
                className="text_black_medium_bold"
                onClick={() => {
                  setPlayVideo(true);
                  setPlayVideoControls(true);
                }}
              >
                {loginState.username}
              </div>
            </div>
          </div>
          <motion.div
            className="logout_button"
            whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 0px #F06D50" }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 10,
              duration: 1,
            }}
            onClick={() =>
              LogoutAPI().then(
                (logout) => window.location.reload()
                //env.backend_host + "/logout?path=" + window.location.href
              )
            }
          >
            <div className="center_container">
              <div className="text_white_small_bold">{t("log_out")}</div>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  const renderUserMenuButton = () => {
    return (
      <motion.div
        className="user_menu_button"
        onClick={() => menuHandler()}
        whileHover={{ scale: 1.1, rotate: 360 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className="center_container">
          <img
            className="image"
            src={StudentUserButton}
            alt="userButton"
            style={{ height: "3vh" }}
          />
        </div>
      </motion.div>
    );
  };

  const labHandler = () => {
    if (
      (tutorial === "campus" || tutorial === "repeat_campus") &&
      studentState.tutorialStep === 6
    ) {
      dispatch(setTutorialStep(7));
    }
    dispatch(menu("campus"));
  };

  const energyLabHandler = () => {
    if (tutorial === "energy_lab" && studentState.tutorialStep === 7) {
      dispatch(increaseTutorialStep());
    }
    dispatch(menu("energyLab"));
  };

  const workshopHandler = () => {
    if (tutorial === "workshop" && studentState.tutorialStep === 9) {
      dispatch(increaseTutorialStep());
    }
    dispatch(menu("workshop"));
  };

  const challengesHandler = () => {
    dispatch(menu("challenges"));
  };

  const homeHandler = () => {
    dispatch(menu("home"));
  };
  const localHandler = (studentId: string, plotId: string) => {
    if (!localMap) {
      goToLocalSound();
    }
    if (localMap) {
      goToGlobalSound();
    }

    const plot = mainState.plots.find((plot) => plot.id === plotId) as PlotType;

    if (!localMap) {
      setLocalMapStudentId(studentId);

      setLocalMapBiome(plot.biome);
    }
    setLocalMap(!localMap);
  };

  const handleSettings = () => {
    setOpenSettings(!openSettings);
  };

  const menuHandler = () => {
    setUserMenu(!userMenu);
  };

  const toggleHouseMainsOpen = (position: number) => {
    const challenge1 = [...studentState.challengeBlackOut];
    challenge1[position - 1] = 1;
    dispatch(setChallenge1Step(studentState.challenge1Step + 1));
    dispatch(setChallengeBlackOut(challenge1));
  };

  const isTutorialOpen = () => {};

  const isChallenge1Open = () => {
    const challenge = studentState.challenge;
    return challenge !== undefined;
  };

  const renderShadow = () => {
    const shadows = mainState.shadows;
    return shadows;
  };

  const renderAnimation = () => {
    const animation = mainState.animations;
    return animation;
  };

  const renderSettingsButton = () => {
    return (
      <motion.div
        className="settings_button"
        whileHover={{ scale: 1.1, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => handleSettings()}
      >
        <div className="center_container">
          <img
            className="image"
            src={settingsIcon}
            alt="settingsButton"
            style={{ height: "3vh" }}
          />
        </div>
      </motion.div>
    );
  };
  console.log(tutorial, studentState.tutorialStep);
  return (
    <div className="student">
      {tutorial === "choose_plot" && !localMap && <TutorialGlobalMap />}
      {tutorial !== "choose_plot" &&
        tutorial !== "none" &&
        localMap &&
        studentState.menu !== "campus" && <TutorialLocalMap part={tutorial} />}
      {isChallenge1Open() && localMap && <Challenge1 />}
      {playVideo && (
        <VideoPlayer
          controls={playVideoControls}
          backHandler={() => videoPlayerBackHandler()}
        />
      )}
      {/* Little easter egg where they can watch the intro video if they click in a certain place */}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderKiloWatts()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderPopulation()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderPoints()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        co2Menu &&
        renderPopulationLoss()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderCO2()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderUserMenuButton()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") && <Leaderboard />}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") &&
        renderSettingsButton()}
      {((studentState.menu === "campus" &&
        studentState.campusMenu === "campus") ||
        studentState.menu !== "campus") && <InfoBar localMap={localMap} />}
      {studentState.menu !== "campus" && !localMap && (
        <CO2Bar classCO2={classCO2} />
      )}
      {/* {studentState.menu === 'globalMap' && openBoardAndNotices && <BoardAndNotices backHandler = {() => setOpenBoardAndNotices(false)} />} */}
      {userMenu &&
        ((studentState.menu === "campus" &&
          studentState.campusMenu === "campus") ||
          studentState.menu !== "campus") &&
        renderUserMenu()}
      {openSettings &&
        ((studentState.menu === "campus" &&
          studentState.campusMenu === "campus") ||
          studentState.menu !== "campus") && (
          <Settings handleSettings={() => handleSettings()} />
        )}
      {studentState.menu === "globalMap" && playVideo !== true && (
        <R3FMap
          localMap={localMap}
          isChallenge1Open={() => isChallenge1Open()}
          localMapStudentId={localMapStudentId}
          labHandler={labHandler}
          energyLabHandler={energyLabHandler}
          localHandler={localHandler}
          localMapType={localMapBiome}
          workshopHandler={workshopHandler}
          homeHandler={homeHandler}
          challengesHandler={challengesHandler}
          toggleHouseMainsOpen={(position: number) =>
            toggleHouseMainsOpen(position)
          }
          renderShadow={() => renderShadow()}
          renderAnimation={() => renderAnimation()}
          classCO2={classCO2}
          tutorial={tutorial}
        />
      )}
      {studentState.menu === "globalMap" &&
        playVideo !== true &&
        studentState.chosenPlot !== undefined && (
          <ParcelaSelector chosenPlot={studentState.chosenPlot} />
        )}
      {studentState.menu === "campus" && <Campus />}
      {studentState.menu === "energyLab" && <EnergyLab biome={localMapBiome} />}
      {studentState.menu === "workshop" && <Workshop biome={localMapBiome} />}
      {studentState.menu === "challenges" && <Challenges />}
      {studentState.menu === "home" && <MyHome />}
    </div>
  );
};

export default Student;
