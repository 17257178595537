import env from "../../config/env.json";
import { ImageType, AnimationType } from "../content/unit";
import { AssetType } from "./house_upgrades";

export interface PowerUpgradeType {
    id: string,
    name: string,
    description: string,
    color: string,
    energy_purchase: number,
    energy_upgrade: number,
    co2_purchase: number,
    co2_upgrade: number,
    co2_function: string | null,
    points_purchase: number,
    points_upgrade: number,
    predilect_biome: string[],
    adverse_biome: string[],
    image_id: string,
    image: ImageType,
    asset: AssetType,
    animation: AnimationType[]
}

export const GetAllPowerUpgradesAPI = async (): Promise<PowerUpgradeType[]> => {
    var req = await fetch(env.backend_host + "/power_upgrade/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}