import "./DragDropOptions.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";

const DragDropOptions = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [answers, setAnswers] = useState<string[]>([]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const textMain = props.exercise.main_text as string;
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [helpOpen, setHelpOpen] = useState(false);
  const [research, setResearch] = useState(props.research);

  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })
  // }, [])

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    } else {
      setAnswers(_.shuffle(options));
    }
    // } else {
    //   if (selectedExercise < maxExercise) {
    //     setOptions(distributeOptions(props.solution["es"]));
    //   } else {
    //     setOptions(distributeOptions(shuffleArray(props.options["es"])));
    //   }
    // }
  }, [options]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      if (options[i] !== answers[i]) {
        correct = false;
        wrongIndexes.push(i);
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const updateDashboard = (srcC: string, desC: string) => {
    let copiedDashboards = [...answers];
    if (srcC !== desC) {
      copiedDashboards.splice(parseInt(desC), 1, answers[parseInt(srcC)]);
      copiedDashboards.splice(parseInt(srcC), 1, answers[parseInt(desC)]);
    }
    setAnswers(copiedDashboards);
  };

  const renderProfessor = () => {
    return (
      <div className="drag_drop_options_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="drag_drop_options_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          whileHover={{ scale: selectedExercise === maxExercise ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="drag_drop_options_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="drag_drop_options_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderTextBoxMain = () => {
    return (
      <div className="drag_drop_options_text_box_main">
        <div className="center_container">
          {!_.isUndefined(textMain) && (
            <div
              className={
                textMain.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {textMain}
            </div>
          )}
        </div>
      </div>
    );
  };
  console.log(incorrectIndexes);

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_options_background">
        <DragDropContext
          onDragEnd={(param) => {
            const srcC = param.source.droppableId.slice(-1);
            const desC =
              param.destination === null || param.destination === undefined
                ? param.source.droppableId.slice(-1)
                : param.destination.droppableId.slice(-1);
            updateDashboard(srcC, desC);
          }}
        >
          <div
            className="drag_drop_options_background_grid"
            style={{
              gridTemplateColumns:
                "repeat(" + answers.length + ", " + 100 / answers.length + "%)",
            }}
          >
            {answers.map((column, columnIndex) => {
              return (
                <div
                  className="drag_drop_options_background_column"
                  key={"column" + columnIndex}
                >
                  <div className="drag_drop_options_background_column_left">
                    <div className="center_container">
                      <div className="text_white_medium">{columnIndex + 1}</div>
                    </div>

                    <Droppable
                      droppableId={"droppable-" + columnIndex}
                      key={"droppable-" + columnIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="drag_drop_options_droppable"
                        >
                          <Draggable
                            key={columnIndex}
                            draggableId={"draggable-" + column + columnIndex}
                            index={0}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="drag_drop_options_draggable"
                                style={{
                                  top: 7 + "%",
                                  ...provided.draggableProps.style,
                                  backgroundColor: "",
                                  // incorrectFeedback === true
                                  //   ? incorrectIndexes.includes(columnIndex)
                                  //     ? "#F6A495"
                                  //     : ""
                                  //   : "",
                                }}
                              >
                                <motion.div
                                  className="center_container"
                                  whileHover={{
                                    scale: 1.02,
                                    boxShadow: "0px 0px 10px 1px #5090F0",
                                    borderRadius: "5px",
                                  }}
                                  transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 10,
                                  }}
                                >
                                  <div
                                    className={
                                      column.length < 15
                                        ? "text_black_medium"
                                        : "text_black_small"
                                    }
                                  >
                                    {column}
                                  </div>
                                </motion.div>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  {columnIndex !== answers.length - 1 && (
                    <div className="drag_drop_options_background_column_right">
                      <div className="drag_drop_options_background_column_right_line" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </div>
    );
  };

  const renderBackground = () => {
    if (incorrectFeedback) {
      setTimeout(() => {
        setIncorrectFeedback(false);
      }, 2000);
    }
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="drag_drop_options_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="drag_drop_options">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderTextBoxMain()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropOptions;
