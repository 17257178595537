import env from "../../config/env.json";
import { ClassType } from "./class.ts"
import { TeacherType } from "./teacher.ts";

export interface SchoolType {
    id: string
    name: string
    start_date: string
    license_id: number | null
    classes: ClassType[]
}

export interface BusinessSchoolType {
    id: string
    name: string
    teachers: {
        id: string
        logins: {
            timestamp: string
        }[]
    }[]
}

export const GetMySchoolAPI = async (): Promise<SchoolType> => {
    var req = await fetch(env.backend_host + "/school/mine", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data.classes = data.classes || []
    return data;
}

export const GetAllSchoolsAPI = async (): Promise<BusinessSchoolType[]> => {
    var req = await fetch(env.backend_host + "/school/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    console.log(req)
    var data = await req.json();
    console.log(data)
    return data;
}


export const GetTeachersInSchoolAPI = async (): Promise<TeacherType[] | null> => {
    var req = await fetch(env.backend_host + "/school/teachers", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}