import { useGLTF, useTexture } from "@react-three/drei";
import { MeshPhongMaterial, Color, SRGBColorSpace } from "three";
import { DreiGLTF } from "../../../../../../interfaces";

const ModelGrassland = ({ ...props }) => {
  const { nodes } = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/LOCAL_jungla.gltf"
  ) as DreiGLTF;
  const bakedTexture = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Grassland/baked_pradera_local_op.jpg"
  );
  var bakedMaterial = new MeshPhongMaterial({
    map: bakedTexture,
    shininess: 0,
    fog: true,
  });

  if (props.funkyMode) {
    bakedMaterial = new MeshPhongMaterial({
      map: bakedTexture,
      emissiveMap: bakedTexture,
      emissive: new Color(0xff0000),
      emissiveIntensity: 10,
      shininess: 0,
      fog: true,
    });
  }

  bakedTexture.flipY = false;
  bakedTexture.colorSpace = SRGBColorSpace;

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.Local_jungla.geometry}
        material={bakedMaterial}
        position={[-10.38, 0.38, 0.38]}
        scale={15.21}
      />
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={nodes.River_jungla.geometry}
        material={bakedMaterial}
        position={[-6.69, 0.15, 0.38]}
        scale={[5.46, 3.66, 15.29]}
      />
    </group>
  );
};

useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Variants/Jungle/LOCAL_jungla.gltf"
);

export default ModelGrassland;
