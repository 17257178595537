import { useGLTF } from "@react-three/drei";
import { useAppSelector } from "../../../../hooks/hooks";
import * as THREE from "three";
import { DreiGLTF } from "../../../../interfaces";
import { useEffect, useState } from "react";
import {
  GetAllPowerUpgradesAPI,
  PowerUpgradeType,
} from "../../../../_newapios/gamecontent/power_upgrades";
import {
  GetStudentPowerAllAPI,
  StudentPowerType,
} from "../../../../_newapios/progress/student_power";
import { convertToEuler, convertToVector3 } from "../../../../utils/R3F";

const MejorasEnergia = ({ ...props }) => {
  const loginState = useAppSelector((state) => state.login);
  const [powerUpgrades, setPowerUpgrades] = useState<PowerUpgradeType[]>([]);
  const [studentPower, setStudentPower] = useState<StudentPowerType[]>([]);
  useEffect(() => {
    loadProgress();
  }, []);

  const loadProgress = async () => {
    const temp_power_upgrades = await GetAllPowerUpgradesAPI();
    setPowerUpgrades(temp_power_upgrades);
    const temp_student_power = await GetStudentPowerAllAPI(
      props.studentId == null ? loginState.id : props.studentId
    );
    setStudentPower(temp_student_power);
  };

  const bakedAssetsMaterialCoal = new THREE.MeshPhongMaterial({
    color: new THREE.Color(0xff1200),
    emissive: new THREE.Color(0xff1200),
    emissiveIntensity: 1,
  });


  return (
    <group {...props} dispose={null}>
      {studentPower?.map((studPow, studPowIndex) => {
        const upg = powerUpgrades.find(
          (upg) => upg.id === studPow.power_upgrade_id
        );
        const upgIndex = powerUpgrades.findIndex(
          (upg) => upg.id === studPow.power_upgrade_id
        );
        if (upg === undefined || upg.asset == undefined) return;
        return (
          <LoadPowerUpgrades
            upg={upg}
            renderShadow={() => props.renderShadow()}
          />
        );
      })}
    </group>
  );
};

export default MejorasEnergia;

// All this because I can't use useGLTF inside the map (because it's a React hook)


function LoadPowerUpgrades(props: {
  upg: PowerUpgradeType;
  renderShadow: () => boolean;
}) {
  const PowerGLTF = useGLTF(props.upg.asset.gltf.url) as DreiGLTF;
  return (
    <group
      position={convertToVector3(props.upg.asset.position)}
      rotation={convertToEuler(props.upg.asset.rotation)}
      scale={convertToVector3(props.upg.asset.scale)}
    >
      {props.upg.asset.gltf.geometry.map((geometry, index) => {
        return (
          <mesh
            key={index}
            castShadow={props.renderShadow()}
            receiveShadow={props.renderShadow()}
            geometry={PowerGLTF.nodes[geometry].geometry}
            material={PowerGLTF.materials[props.upg.asset.gltf.material[index]]}
          />
        );
      })}
    </group>
  );
}