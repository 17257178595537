import { LazyMotion, m, motion } from "framer-motion";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
  GetUnitScenariosAPI,
  ScenarioType,
} from "../../../_newapios/content/unit";
import placeholderExp from "../../../assets/professor/placeholder_exp.png";
import placeholderInv from "../../../assets/professor/placeholder_inv.png";
import bin from "../../../assets/professor/bin.svg";
import CollapseButton from "../../../assets/professor/chevron-up.svg";
import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  TagRightIcon,
  Textarea,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "chakra-react-select";
import { simpleGlobalCompetences } from "../../../constants.ts";
import { GetResearchAPI } from "../../../_newapios/content/research.ts";
import { GetExperimentAPI } from "../../../_newapios/content/experiment.ts";
import { prettifyCourseAcronyms } from "../../../utils/competences.ts";
import _ from "lodash";
import { ChevronDownIcon } from "@chakra-ui/icons";
import close from "../../../assets/student/close_user_menu.svg";

const ScenarioEditMenu = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);

  const ref = useRef(null);

  // Scenario vars
  const acronym: string = props.acronym;
  const scenario: ScenarioType = props.scenario;
  const evaluationCriteria: EvaluationCriterionType[] =
    props.evaluationCriteria;
  const specificCompetences: SpecificCompetenceType[] =
    props.specificCompetences;
  const selectedGlobalCompetences: boolean[] = props.selectedGlobalCompetences;
  const updateScenario: (scenario: ScenarioType) => void = props.updateScenario;
  const saveScenario: (scenario?: ScenarioType | undefined) => void =
    props.saveScenario;
  const deleteScenario: () => void = props.deleteScenario;

  const [openScenario, setOpenScenario] = useState<boolean>(true);
  const [showHoverText, setShowHoverText] = useState<string>("");
  const [focusKnowHow, setFocusKnowHow] = useState<boolean>(false);

  const [deleteMenu, setDeleteMenu] = useState<Boolean>(false);

  const updateScenarioName = (name: string) => {
    const newScenario = { ...scenario };
    newScenario.name = name;
    updateScenario(newScenario);
  };

  const updateScenarioDescription = (description: string) => {
    const newScenario = { ...scenario };
    newScenario.description = description;
    updateScenario(newScenario);
  };

  const updateScenarioEvalCriteria = (evalCriterionId: string, i: number) => {
    const newScenario = { ...scenario };
    if (evalCriterionId === "delete") {
      newScenario.evaluation_criteria_ids.splice(i, 1);
      updateScenario(newScenario);
      return;
    }
    newScenario.evaluation_criteria_ids[i] = evalCriterionId;
    updateScenario(newScenario);
  };

  const updateAndSaveScenarioEvalCriteria = (
    evalCriterionId: string,
    i: number
  ) => {
    console.log("Save");
    updateScenarioEvalCriteria(evalCriterionId, i);
    saveScenario();
  };

  const addScenarioEvalCriteria = () => {
    const newScenario = { ...scenario };
    newScenario.evaluation_criteria_ids.push("");
    updateScenario(newScenario);
  };

  const scenarioKnowHowsHandler = (value: any, i: number) => {
    var newScenario = { ...scenario };

    if (value === "") {
      newScenario.know_hows.splice(i, 1);
    } else {
      newScenario.know_hows[i] = value;
    }
    updateScenario(newScenario);
  };

  const addScenarioKnowHow = () => {
    var newScenario = { ...scenario };
    newScenario.know_hows.push("");
    updateScenario(newScenario);
  };

  const researchHandler = async (researchId: string) => {
    const research = await GetResearchAPI(researchId);
    props.researchHandler(research);
  };

  const experimentHandler = async (experimentId: string) => {
    const experiment = await GetExperimentAPI(experimentId);
    props.experimentHandler(experiment);
    console.log("we are here");
  };

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const deleteMenuHandler = () => {
    setDeleteMenu(true);
  };

  const renderDeleteMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_scenario_text")}
                      <b>{scenario.name}</b>?
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDeleteMenu(false)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteScenario()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDeleteMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };
  return (
    <LazyMotion features={loadFeatures}>
      <div
        // layoutRoot
        // layout="position"
        className="professor_edit_unit_menu_main_scenario"
      >
        {/* {openScenario && ( */}
        <div
          // layout
          className="professor_edit_unit_menu_main_scenario_open"
          // whileHover={{
          //   scale: 1.01,
          //   boxShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
          //   borderRadius: "10px",
          // }}
          // transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          style={{
            boxShadow: openScenario
              ? "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px"
              : "none",
            borderRadius: openScenario ? "5px" : "none",
          }}
        >
          <div className="professor_edit_unit_menu_main_scenario_open_top_left">
            <img
              className="image"
              src={
                _.isUndefined(scenario.image?.url)
                  ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg"
                  : scenario.image?.url
              }
              alt="scenario_image"
              style={{ borderRadius: "10px", maxHeight: "80px" }}
            />
            <div className="professor_edit_unit_menu_main_scenario_open_top_left_info">
              <div className="professor_edit_unit_menu_main_scenario_info_top">
                <Input
                  placeholder={t("scenario_name")}
                  value={scenario?.name}
                  onChange={(e) => updateScenarioName(e.target.value)}
                  onBlur={() => {
                    saveScenario();
                  }}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "black",
                    width: "100%",
                  }}
                  as={motion.input}
                  size={"sm"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                />
                {/* {" | "}
                <Input
                  placeholder={t("scenario_main_know_how")}
                  value={scenario.know_hows[0]}
                  onChange={(e) => scenarioKnowHowsHandler(e.target.value, 0)}
                  onBlur={() => {
                    saveScenario();
                  }}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "black",
                    width: "90%",
                  }}
                  as={motion.input}
                  size={"sm"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                /> */}
              </div>
              <div className="professor_edit_unit_menu_main_scenario_info_bottom">
                <Textarea
                  placeholder={t("scenario_description")}
                  value={scenario.description}
                  resize={"none"}
                  onChange={(e) => updateScenarioDescription(e.target.value)}
                  onBlur={() => {
                    saveScenario();
                  }}
                  style={{
                    fontFamily: "Causten",
                    background: "#EEEEEE",
                    overflowY: "scroll",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    marginTop: "0.5vh",
                    color: "black",
                    width: "100%",
                  }}
                  as={motion.textarea}
                  size={"sm"}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="professor_edit_unit_menu_main_scenario_open_top_right">
            <div className="text_black_very_small_bold">
              {t("basic_knowledge")}
            </div>
            <Wrap
              justify="space-between"
              align="center"
              style={{ width: "100%", height: "100%" }}
            >
              <WrapItem w="49%">
                <Input
                  placeholder={t("scenario_main_know_how")}
                  value={scenario.know_hows[0]}
                  isReadOnly={true}
                  autoFocus={false}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "#6B6B6B",
                    width: "100%",
                  }}
                  size={"xs"}
                />
              </WrapItem>
              {scenario.know_hows.map((knowHow: any, i: number) => {
                if (i === 0) return null;
                return (
                  <WrapItem w="49%">
                    <Input
                      placeholder={t("scenario_know_hows_placeholder")}
                      value={knowHow}
                      isReadOnly={false}
                      autoFocus={
                        i === scenario.know_hows.length - 1 && focusKnowHow
                          ? true
                          : false
                      }
                      onBlur={() => {
                        setFocusKnowHow(false);
                        saveScenario();
                      }}
                      onChange={(e) =>
                        scenarioKnowHowsHandler(e.target.value, i)
                      }
                      style={{
                        fontFamily: "Causten",
                        fontWeight: "bold",
                        background: "#EEEEEE",
                        fontSize: "0.8em",
                        borderRadius: "2.5px",
                        color: "black",
                        width: "100%",
                      }}
                      as={motion.input}
                      size={"xs"}
                      whileHover={{
                        scale: 1.01,
                        boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                      }}
                    />
                  </WrapItem>
                );
              })}
              <WrapItem w="49%">
                <Input
                  placeholder={t("add_know_how")}
                  value={""}
                  onFocus={() => {
                    addScenarioKnowHow();
                  }} // updateScenarioHandler is in there
                  onChange={() => {}}
                  style={{
                    fontFamily: "Causten",
                    fontWeight: "bold",
                    background: "#EEEEEE",
                    fontSize: "0.8em",
                    borderRadius: "2.5px",
                    color: "black",
                    width: "100%",
                  }}
                  as={motion.input}
                  size={"xs"}
                  // whileHover={{
                  //   scale: 1.01,
                  //   boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                  // }}
                />
              </WrapItem>
            </Wrap>
          </div>
          <div className="professor_edit_unit_menu_main_scenario_open_bottom_left">
            <div
              // whileHover={{
              //   scale: 1.05,
              //   boxShadow: "0px 0px 10px 2px #5090F0",
              // }}
              className="professor_edit_unit_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("inv")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={() => {
                researchHandler(scenario.research.id);
              }}
            >
              {showHoverText === "inv" && (
                <div className="professor_edit_unit_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_research")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderInv} alt="Research" />
            </div>
            <div
              // whileHover={{
              //   scale: 1.05,
              //   boxShadow: "0px 0px 10px 2px #5090F0",
              // }}
              className="professor_edit_unit_menu_main_scenario_open_bottom_left_image_container"
              onMouseEnter={() => setShowHoverText("exp")}
              onMouseLeave={() => setShowHoverText("none")}
              onClick={() => {
                experimentHandler(scenario.experiment.id);
              }}
            >
              {showHoverText === "exp" && (
                <div className="professor_edit_unit_menu_main_scenario_open_bottom_left_image_container_hover center_container">
                  <div className="text_white_medium_bold">
                    {t("see_experiment")}
                  </div>
                </div>
              )}
              <img className="image" src={placeholderExp} alt="Experiment" />
            </div>
          </div>
          <div className="professor_edit_unit_menu_main_scenario_open_bottom_right">
            <div className="professor_edit_unit_menu_main_scenario_open_bottom_right_top">
              <div className="text_black_very_small_bold">
                {t("evaluation_criteria")}
              </div>
              <div
                // justify="space-between"
                // align="center"
                style={{
                  // position: "relative",
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {scenario.evaluation_criteria_ids?.map(
                  (evalCriteriaId: string, i: number) => {
                    const evcrit = evaluationCriteria.find(
                      (crit: any) => crit.id === evalCriteriaId
                    );
                    return (
                      <Menu>
                        <MenuButton
                          style={{
                            width: "100%",
                            height: "4vh",
                            backgroundColor: "#EEEEEE",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                            borderRadius: "50px",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        >
                          <div className="center_container">
                            <div
                              className="text_black_super_small"
                              style={{
                                width: "calc(100% - 24px)", // Adjust width to accommodate icon
                                overflow: "hidden",
                                // display: "flex",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginTop: "1%",
                                marginLeft: "2%",
                                marginBottom: "1%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div className="left_container">
                                {_.isUndefined(evcrit)
                                  ? t("evaluation_criteria_placeholder")
                                  : t(
                                      props.acronym +
                                        evcrit?.specific_competence?.number +
                                        "." +
                                        evcrit?.number +
                                        "_desc",
                                      { ns: "evaluation" }
                                    )}
                              </div>
                            </div>
                            <ChevronDownIcon
                              boxSize="12px"
                              style={{ color: "black", marginRight: "3%" }}
                            />
                          </div>
                        </MenuButton>
                        <MenuList
                          style={{
                            maxHeight: "35vh",
                            maxWidth: "35vw",
                            overflowY: "scroll",
                            fontFamily: "Causten",
                          }}
                        >
                          {/* Add "delete eval criteria" option */}
                          <MenuItem
                            onClick={() =>
                              updateAndSaveScenarioEvalCriteria("delete", i)
                            }
                          >
                            <div className="left_container">
                              <div className="text_black_super_small">
                                {t("delete_eval_criteria_placeholder")}
                              </div>
                            </div>
                          </MenuItem>
                          {evaluationCriteria
                            .map((evcrit) => ({
                              value: evcrit.id,
                              label: t(
                                props.acronym +
                                  evcrit?.specific_competence?.number +
                                  "." +
                                  evcrit?.number +
                                  "_desc",
                                { ns: "evaluation" }
                              ),
                            }))
                            .filter((option) => {
                              return !scenario.evaluation_criteria_ids.includes(
                                option.value
                              );
                            })
                            .map((option, index) => (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  updateAndSaveScenarioEvalCriteria(
                                    option.value,
                                    i
                                  )
                                }
                              >
                                <div className="left_container">
                                  <div className="text_black_super_small">
                                    {option.label}
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>
                    );
                  }
                )}
                {scenario.evaluation_criteria_ids.filter((ev) => ev === "")
                  .length === 0 && (
                  <Button
                    name="add-eval-criteria"
                    size={"sm"}
                    onClick={() => addScenarioEvalCriteria()}
                    width={"100%"}
                    height={"4vh"}
                    style={{
                      fontFamily: "Causten",
                      fontSize: "0.7em",
                      backgroundColor: "#EEEEEE",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      color: "black",
                      borderRadius: "50px",
                      marginTop: "1%",
                      marginBottom: "1%",

                      // boxShadow: "0px 0px 5px 2px rgba( 0, 0, 0, 0.25 )"
                    }}
                    // ... (other props)
                  >
                    <div
                      className="text_black_super_small"
                      style={{
                        marginTop: "1%",
                        marginBottom: "1%",
                      }}
                    >
                      {t("add_eval_criteria")}
                    </div>
                  </Button>
                )}
              </div>
            </div>
            <div className="professor_edit_unit_menu_main_scenario_open_bottom_right_bottom">
              <div className="professor_edit_unit_menu_main_scenario_open_bottom_right_bottom_spec">
                {specificCompetences.map((competence, index) => {
                  const active: boolean = scenario.evaluation_criteria_ids
                    .map(
                      (id) =>
                        evaluationCriteria.find((ev) => ev.id === id)
                          ?.specific_competence_id
                    )
                    .includes(competence.id);
                  return (
                    <div
                      style={{
                        fontSize: "0.65em",
                        fontFamily: "Causten",
                        backgroundColor: active
                          ? "rgba(41, 47, 76, 1)"
                          : "rgba(41, 47, 76, 0.25)",
                        width: "100%",
                        borderRadius: "100px",
                        maxHeight: "1.3em",
                        color: "white",
                        padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                        textAlign: "center",
                        marginRight: "0.5vw",
                      }}
                    >
                      {prettifyCourseAcronyms(acronym) + (index + 1).toString()}
                    </div>
                  );
                })}
              </div>
              <div className="professor_edit_unit_menu_main_scenario_open_bottom_right_bottom_key">
                {simpleGlobalCompetences.map((competence, index: number) => {
                  const nonSelectedColor =
                    competence[1].substr(0, competence[1].length - 2) + "0.25)";
                  return (
                    <div
                      style={{
                        fontSize: "0.65em",
                        fontFamily: "Causten",
                        backgroundColor: selectedGlobalCompetences[index]
                          ? competence[1]
                          : nonSelectedColor,
                        width: "100%",
                        borderRadius: "100px",
                        maxHeight: "1.3em",
                        color: "white",
                        padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                        textAlign: "center",
                        marginRight: "0.5vw",
                      }}
                    >
                      {competence[0]}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div
            className="professor_edit_unit_menu_main_scenario_open_close_button"
            // whileHover={{ scale: 1.5, rotate: 360 }}
            // transition={{ type: "spring", stiffness: 500, damping: 30 }}
          >
            <img
              className="image"
              src={CollapseButton}
              style={{
                height: "100%",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              alt="close scenario button"
              onClick={() => setOpenScenario(false)}
            />
          </div> */}
        </div>
        {/* )} */}

        {/* {!openScenario && (
          <div
            className="professor_edit_unit_menu_main_scenario_closed"
            onClick={() => setOpenScenario(true)}
          // whileHover={{
          //   scale: 1.01,
          //   boxShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
          //   borderRadius: "10px",
          // }}
          // transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >
            <img
              className="image"
              src={
                _.isUndefined(scenario.image?.url)
                  ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg"
                  : scenario.image?.url
              }
              alt="scenario_image"
              style={{ borderRadius: "10px", maxHeight: "71px" }}
            />
            <div className="professor_edit_unit_menu_main_scenario_info">
              <div className="professor_edit_unit_menu_main_scenario_info_top left_container">
                <div className="text_black_very_small">
                  <b>{scenario.name ? scenario.name : t("scenario_name")}</b>{" "}
                  {" | " +
                    (scenario.know_hows[0]
                      ? scenario.know_hows[0]
                      : t("scenario_main_know_how"))}
                </div>
              </div>
              <div className="professor_edit_unit_menu_main_scenario_info_bottom left_container">
                {specificCompetences.map((competence, index) => {
                  const active: boolean = scenario.evaluation_criteria_ids
                    .map(
                      (id) =>
                        evaluationCriteria.find((ev) => ev.id === id)
                          ?.specific_competence_id
                    )
                    .includes(competence.id);
                  return (
                    <div
                      key={index}
                      style={{
                        fontSize: "0.8em",
                        backgroundColor: active
                          ? "rgba(41, 47, 76, 1)"
                          : "rgba(41, 47, 76, 0.25)",
                        borderRadius: "100px",
                        color: "white",
                        padding: "0.1em 1.5em 0em 1.5em",
                        textAlign: "center",
                        marginLeft: index !== 0 ? "1.5em" : undefined,
                      }}
                    >
                      {prettifyCourseAcronyms(props.acronym) +
                        (index + 1).toString()}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="professor_edit_unit_menu_main_scenario_delete"
              onClick={(e) => {
                e.stopPropagation();
                // deleteScenario();
                deleteMenuHandler();
              }}
            // whileHover={{
            //   scale: 1.1,
            //   boxShadow: "0px 0px 10px 1px #F0684F",
            // }}
            // transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <img src={bin} style={{ height: "2vh" }} alt="bin" />
              </div>
            </div>
          </div>
        )} */}
        {deleteMenu && renderDeleteMenu()}
      </div>
    </LazyMotion>
  );
};

export default ScenarioEditMenu;
