import env from "../../config/env.json";
import { ModifiedScenarioType } from "../content/scenario.ts";
import { ModifiedUnitType, UnitType } from "../content/unit.ts";
import { StudentType } from "./student.ts";
import { TeacherType } from "./teacher.ts";

export interface ClassType {
    pin: string,
    id: string;
    year: number;
    name: string;
    level: number;
    main_language: string;
    foreign_language: string;
    regulation_id: string;
    school_id: string;
    theme_id: string;
    // Teacher stuff
    teacher_in_class?: boolean
    // Student stuff
    students?: StudentType[]
}

export interface BundleType {
    id: string;
    start_date: string;
    end_date: string;
    weighing: number;
    unit?: ModifiedUnitType,
    unit_id: string;
    class_id: string;
    students?: StudentType[]
}

export interface ModifiedBundleType {
    id: string;
    start_date: string;
    end_date: string;
    weighing: number;
    unit: {
        id: string,
        name: string,
        language: string,
        visibility: number,
        owner_id: string,
        course_id: string,
        scenarios: ModifiedScenarioType[],
    },
    unit_id: string;
    class_id: string;
}

export const GetMyClassAPI = async (): Promise<ClassType | ClassType[] | null> => {
    var req = await fetch(env.backend_host + "/me/class", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    if (req.status === 409) {
        return null;
    }
    var data = await req.json();

    data = data || null
    return data;
}

export const GetMyClassesAPI = async (): Promise<ClassType[] | null> => {
    var req = await fetch(env.backend_host + "/class/mine", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });

    var data = await req.json();

    data = data || null
    return data;
}

export const UpdateClassAPI = async (id: string, year: (string | undefined) = undefined, name: (string | undefined) = undefined, level: (string | undefined) = undefined, main_language: (string | undefined) = undefined, foreign_language: (string | null) = null): Promise<ClassType | null> => {
    var req = await fetch(env.backend_host + "/class/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({
            name,
            level,
            main_language,
            foreign_language,
        }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const CreateClassAPI = async (year: (string | undefined) = undefined, name: (string | undefined) = undefined, regulation_id: (string | undefined) = undefined, main_language: (string | undefined) = undefined, foreign_language: (string | null) = null, theme_id: (string | null) = null, teacher_ids: string[] = []): Promise<ClassType | null> => {
    var req = await fetch(env.backend_host + "/class/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({
            year,
            name,
            main_language,
            foreign_language,
            theme_id,
            regulation_id,
            teacher_ids
        }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const DeleteClassAPI = async (id: string): Promise<void> => {
    var req = await fetch(env.backend_host + "/class/delete/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
}

export const GetClassTeachersAPI = async (id: string): Promise<TeacherType[] | null> => {
    var req = await fetch(env.backend_host + "/class/" + id + "/teachers", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetClassStudentsAPI = async (id: string): Promise<StudentType[] | null> => {
    var req = await fetch(env.backend_host + "/class/" + id + "/students", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const DeleteTeacherInClassAPI = async (class_id: string, teacher_id: string): Promise<TeacherType | null> => {
    var req = await fetch(env.backend_host + "/class/" + class_id + "/teacher/" + teacher_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "DELETE",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const ModifyTeachersInClassAPI = async (class_id: string, teachers: string[]): Promise<TeacherType[] | null> => {
    var req = await fetch(env.backend_host + "/class/" + class_id + "/teachers", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({
            teachers
        }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetClassBundlesAPI = async (id: string): Promise<BundleType[]> => {
    var req = await fetch(env.backend_host + "/class/" + id + "/bundles", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}