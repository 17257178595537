import env from "../../config/env.json";
import { ExperimentProgressType } from "../progress/student_scenario";
import { ExerciseType } from "./unit";

export interface ExperimentType {
    id: string,
    scenario_id: string,
    exercises: [ExerciseType, ExerciseType, ExerciseType, ExerciseType],
    experiment_progress: ExperimentProgressType
}

export const GetExperimentAPI = async (scenario_id: string): Promise<ExperimentType> => {
    var req = await fetch(env.backend_host + "/experiment/" + scenario_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    return data
}