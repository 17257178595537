import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlotType } from '../_newapios/gamecontent/plots';
import { ChallengeType } from '../_newapios/gamecontent/challenges';

export interface StudentState {
    menu: string,
    campusMenu: string,
    kilowatts: number,
    population: number,
    co2: number,
    points: number,
    updateMetrics: boolean,
    updateProgress: boolean,
    isLoading: boolean,
    totalModels: number,
    loadedModels: number,
    challenge: ChallengeType | undefined,
    challenge1Step: number,
    challengeBlackOut: Array<number>,
    infoBar: { display: string, name: string, population: number, power: number, co2: number, class_id: number, plot_id: number, student_id: number }
    tutorialStep: number,
    chosenPlot: PlotType | undefined
}

export const initialState: StudentState = {
    menu: 'globalMap',
    campusMenu: "campus",
    kilowatts: 0,
    population: 0,
    co2: 0,
    points: 0,
    updateMetrics: false,
    updateProgress: false,
    isLoading: false,
    totalModels: 1,
    loadedModels: 0,
    challenge: undefined,
    challenge1Step: 0,
    challengeBlackOut: [0, 0, 0, 0, 0, 0],
    infoBar: { display: "hidden", name: "", population: 0, power: 0, co2: 0, class_id: 0, plot_id: 0, student_id: 0 },
    tutorialStep: 0,
    chosenPlot: undefined
}



export const studentSlice = createSlice({
    name: 'student',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        menu: (state, action: PayloadAction<string>) => {
            state.menu = action.payload
        },
        campusMenu: (state, action: PayloadAction<string>) => {
            state.campusMenu = action.payload
        },
        kilowatts: (state, action: PayloadAction<number>) => {
            state.kilowatts = action.payload
        },
        population: (state, action: PayloadAction<number>) => {
            state.population = action.payload
        },
        co2: (state, action: PayloadAction<number>) => {
            state.co2 = action.payload
        },
        points: (state, action: PayloadAction<number>) => {
            if (typeof action.payload !== "number") return
            state.points = action.payload
        },
        updateMetrics: (state) => {
            state.updateMetrics = !state.updateMetrics
        },
        updateProgress: (state) => {
            state.updateProgress = !state.updateProgress
        },
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        totalModels: (state, action: PayloadAction<number>) => {
            state.totalModels = action.payload
        },
        loadedModels: (state, action: PayloadAction<number>) => {
            state.loadedModels = action.payload
        },
        setChallenge: (state, action: PayloadAction<ChallengeType | undefined>) => {
            state.challenge = action.payload
        },
        setChallenge1Step: (state, action: PayloadAction<number>) => {
            state.challenge1Step = action.payload
        },
        setChallengeBlackOut: (state, action: PayloadAction<Array<number>>) => {
            state.challengeBlackOut = action.payload
        },
        updateInfoBar: (state, action: PayloadAction<{ display: string, name: string, population: number, power: number, co2: number }>) => {
            const newAction = action.payload;
            state.infoBar = { ...state.infoBar, ...newAction }
        },
        increaseTutorialStep: (state) => {
            state.tutorialStep++
        },
        setTutorialStep: (state, action: PayloadAction<number>) => {
            state.tutorialStep = action.payload
        },
        setChosenPlot: (state, action: PayloadAction<PlotType | undefined>) => {
            state.chosenPlot = action.payload
        }
    },
});
export const { menu, campusMenu, kilowatts, population, co2, points, updateMetrics, updateProgress, isLoading, totalModels, loadedModels, setChallenge, setChallenge1Step, setChallengeBlackOut, updateInfoBar, increaseTutorialStep, setTutorialStep, setChosenPlot } = studentSlice.actions;

export default studentSlice.reducer;