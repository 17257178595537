import "./EnergyLab.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import closeButton from "../../assets/student/close_button.svg";
import kilowattsIcon from "../../assets/student/kilowatts_icon.svg";
import co2Icon from "../../assets/student/co2_icon.svg";
import pointsIcon from "../../assets/student/points_icon.svg";
import close from "../../assets/student/close_user_menu.svg";
import {
  co2,
  kilowatts,
  menu,
  points,
  updateMetrics,
} from "../../reducers/studentSlice";
import { Ii18nStringObject, IscreenSize } from "../../interfaces";
import { colors } from "../../constants";
import splashBackground from "../../assets/student/splashBackground.tsx";
import { motion } from "framer-motion";
import {
  GetAllPowerUpgradesAPI,
  PowerUpgradeType,
} from "../../_newapios/gamecontent/power_upgrades.ts";
import {
  GetStudentPowerAllAPI,
  SetStudentPowerAPI,
  StudentPowerType,
  UpdateStudentPowerAPI,
} from "../../_newapios/progress/student_power.ts";
import { BiomeType } from "../../_newapios/gamecontent/plots.ts";
import ReactPlayer from "react-player";
import { getBiomeTypePosition } from "../../utils/game_content.ts";
import useSound from "use-sound";

const EnergyLab = (props: { biome: BiomeType }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);

  const [buildingSound] = useSound(
    "../../assets/sounds/Energy+upgrade+-+01.wav",
    {
      volume: mainState.volumeSounds,
    }
  );

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const biomeNumber = getBiomeTypePosition(props.biome);

  const [progress, setMyProgress] = useState<StudentPowerType[]>([]);
  const [buildings, setBuildings] = useState<PowerUpgradeType[]>([]);
  const [selectedBuilding, setSelectedBuilding] =
    useState<PowerUpgradeType | null>(null);
  const [studentPoints, setStudentPoints] = useState<number>(
    studentState.points
  );
  const [playVideoConstruction, setPlayVideoConstruction] =
    useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    loadProgress();
    loadBuildings();
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const buildingProgressFinder = (id: string | undefined) => {
    if (id === undefined) return;
    return progress.find((b) => b.power_upgrade_id === id);
  };

  const loadProgress = async () => {
    var studentProgress = await GetStudentPowerAllAPI("me");
    setMyProgress(studentProgress);

    dispatch(updateMetrics());
  };

  const loadBuildings = async () => {
    const loadedBuildings = await GetAllPowerUpgradesAPI();

    setBuildings(loadedBuildings);
  };

  const closeButtonHandler = async () => {
    dispatch(menu("globalMap"));
  };

  const closeVideoHandler = () => {
    dispatch(menu("globalMap"));
    setPlayVideoConstruction(false);
    setSelectedBuilding(null);
  };

  const buildingHandler = (building: PowerUpgradeType) => {
    if (studentState.tutorialStep !== 8) {
      setSelectedBuilding(building);
    }
  };

  const confirmBuildingHandler = () => {
    var newPoints = studentPoints;
    if (selectedBuilding === null) return;
    const buildingProgress = buildingProgressFinder(selectedBuilding.id);

    if (buildingProgress === undefined) {
      //Purchase
      var neededPoints = selectedBuilding.points_purchase;

      console.log(studentPoints, neededPoints);
      if (studentPoints >= neededPoints) {
        buildingSound();
        console.log(selectedBuilding.animation);
        newPoints -= neededPoints;
        setStudentPoints(newPoints);
        SetStudentPowerAPI(selectedBuilding.id).then(() => {
          if (selectedBuilding.animation[biomeNumber].url !== "") {
            setPlayVideoConstruction(true);
            console.log("play video");
          } else {
            setPlayVideoConstruction(false);
          }
          loadProgress();
        });
      }
    } else {
      //Upgrade
      var neededPoints = selectedBuilding.points_upgrade;

      if (studentPoints >= neededPoints) {
        buildingSound();
        newPoints -= neededPoints;
        setStudentPoints(newPoints);
        UpdateStudentPowerAPI(buildingProgress.id).then(() => {
          loadProgress();
        });
      }
    }
  };

  const renderBanner = () => {
    return (
      <div className="energy_lab_banner_container">
        <img
          className="energy_lab_banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_town_hall.svg"
          }
          alt="banner"
        />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="energy_lab_close_button"
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => closeButtonHandler()}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="energy_lab_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("energy_lab")}</div>
        </div>
      </div>
    );
  };

  const renderBuildings = () => {
    return (
      <div
        className="energy_lab_buildings"
        style={
          screenSize.dynamicWidth > 991
            ? {
                gridTemplateRows:
                  "repeat(" + Math.ceil(buildings.length / 7) + ", 50%)",
              }
            : {
                gridTemplateRows:
                  "repeat(" + Math.ceil(buildings.length / 3) + ", 70%)",
              }
        }
      >
        {buildings.map((building, buildingIndex) => {
          return renderBuilding(building, buildingIndex);
        })}
      </div>
    );
  };

  const renderBuilding = (
    building: PowerUpgradeType,
    buildingIndex: number
  ) => {
    let tutorialBan = false;
    if (studentState.tutorialStep === 9) {
      switch (building.name) {
        case "energy_upgrade_concrete_name":
          tutorialBan = true;
          break;
        case "energy_upgrade_insulation_name":
          tutorialBan = true;
          break;
        case "energy_upgrade_reforestation_name":
          tutorialBan = true;
          break;
        case "energy_upgrade_material_name":
          tutorialBan = true;
          break;
        default:
          tutorialBan = false;
          break;
      }
    }
    return (
      <motion.div
        key={buildingIndex}
        className="energy_lab_building"
        whileHover={{ scale: tutorialBan ? 1 : 1.04 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        style={{
          boxShadow: "0px 0px 20px 1px " + building.color,
          filter: tutorialBan ? "brightness(0.5)" : "brightness(1)",
        }}
        onClick={tutorialBan ? () => {} : () => buildingHandler(building)}
      >
        {renderBuildingName(building)}
        {renderBuildingImage(building)}
        {buildingProgressFinder(building.id) !== undefined &&
          renderLevelBar(building)}
        {buildingProgressFinder(building.id) === undefined &&
          renderLevelBarBlocked()}
        {renderBonuses(building)}
        {renderResearchPoints(building)}
        {/* {(progress.townhall_progress_json.find(b => b.id === building.id) !== undefined &&
         progress.townhall_progress_json.find(b => b.id === building.id).level === building.levels) &&
         renderResearchPointsUnlocked()} */}
      </motion.div>
    );
  };

  const renderBuildingName = (building: PowerUpgradeType) => {
    return (
      <div className="center_container">
        <div className="text_black_very_small_bold">{t(building.name)}</div>
      </div>
    );
  };

  const renderBuildingImage = (building: PowerUpgradeType) => {
    return (
      <div className="center_container">
        <img className="image" src={building.image.url} alt="building" />
      </div>
    );
  };

  const renderLevelBar = (building: PowerUpgradeType) => {
    return (
      <div
        className="energy_lab_building_bar_background"
        style={{ backgroundColor: building.color }}
      >
        <div className="energy_lab_building_bar_background_text">
          <div className="center_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_super_small_bold"
                  : "text_white_very_small_bold"
              }
              style={{ textShadow: "2px 2px 4px #000000" }}
            >
              {t("level")} {buildingProgressFinder(building.id)?.level}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLevelBarBlocked = () => {
    return (
      <div className="energy_lab_building_bar_background">
        <div className="energy_lab_building_bar_background_text">
          <div className="center_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_super_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {t("upgrade_locked")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBonuses = (building: PowerUpgradeType) => {
    return (
      <div className="energy_lab_building_bonuses">
        {renderkW(building)}
        {renderCO2(building)}
      </div>
    );
  };

  const renderkW = (building: PowerUpgradeType) => {
    const buildingProgress = buildingProgressFinder(building.id);
    return (
      <div className="energy_lab_building_bonus_kilowatt">
        <div className="center_container">
          <img className="image" src={kilowattsIcon} alt="kw" />
        </div>
        <div className="center_container">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_white_super_small_bold"
                : "text_white_very_small_bold"
            }
          >
            +{" "}
            {buildingProgress !== undefined
              ? building.energy_purchase < 0
                ? Math.abs(building.energy_upgrade)
                : building.energy_upgrade
              : building.energy_purchase < 0
              ? Math.abs(building.energy_purchase)
              : building.energy_purchase}
            {building.energy_purchase < 0 ? "%" : null}
          </div>
        </div>
        <div className="center_container">
          <div className={"text_yellow_hiper_small_bold"}>kW</div>
        </div>
      </div>
    );
  };

  const renderCO2 = (building: PowerUpgradeType) => {
    const buildingProgress = buildingProgressFinder(building.id);
    let co2 = 0;
    if (building.co2_function !== null) {
      const x = (buildingProgress?.level || 0) + 1;
      co2 =
        Math.round(
          10 *
            (eval(building.co2_function) -
              (buildingProgress?.generated_co2 || 0))
        ) / 10;
    }
    return (
      <div className="energy_lab_building_bonus_co2">
        <div className="center_container">
          <img className="image" src={co2Icon} alt="co2" />
        </div>
        <div className="center_container">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_white_super_small_bold"
                : "text_white_very_small_bold"
            }
          >
            {building.co2_purchase >= 0 ? "+" : null}
            {building.co2_purchase >= 0
              ? buildingProgress !== undefined
                ? building.co2_upgrade
                : building.co2_purchase
              : co2}
            {building.co2_purchase < 0 ? "%" : null}
          </div>
        </div>
        <div className="center_container">
          <div className={"text_grey_hiper_small_bold"}>T CO₂</div>
        </div>
      </div>
    );
  };

  const renderResearchPoints = (building: PowerUpgradeType) => {
    return (
      <div className="energy_lab_building_research_points">
        <div className="center_container">
          <img
            className="image"
            style={{ width: "80%" }}
            src={pointsIcon}
            alt="points"
          />
        </div>
        <div className="center_container">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_white_super_small_bold"
                : "text_white_very_small_bold"
            }
          >
            -{" "}
            {buildingProgressFinder(building.id) !== undefined
              ? building.points_upgrade
              : building.points_purchase}
          </div>
        </div>
        <div className="left_container">
          <div className="energy_lab_building_research_points_blue_texts">
            {t("research_points_label")}
          </div>
        </div>
      </div>
    );
  };

  const renderResearchPointsUnlocked = () => {
    return (
      <div className="center_container">
        <div className="text_black_small_bold">{t("upgraded_max")}</div>
      </div>
    );
  };

  const renderPlayVideoConstruction = (
    building: PowerUpgradeType,
    biomeIndex: number
  ) => {
    return (
      <div className="energy_lab_video">
        <div className="center_container">
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={building.animation[biomeIndex].url}
            onEnded={() => closeVideoHandler()}
            onError={() => closeVideoHandler()}
            playing={true}
            controls={false}
          />
        </div>
      </div>
    );
  };

  const renderConfirmMenu = () => {
    if (selectedBuilding === null) return;
    return (
      <div className="energy_lab_confirm">
        <div
          className="energy_lab_confirm_exterior"
          onClick={() => setSelectedBuilding(null)}
        />

        <div
          className="energy_lab_confirm_menu"
          style={{ boxShadow: "0px 0px 20px 1px " + selectedBuilding.color }}
        >
          {renderConfirmMenuTitle()}
          {renderConfirmMenuImage()}
          {renderConfirmMenuDescription()}
          {renderConfirmMenuBonuses()}
          {renderConfirmMenuPurchase()}
          {/* {(progress.townhall_progress_json.find(b => b.id === selectedBuilding.id) !== undefined &&
          progress.townhall_progress_json.find(b => b.id === selectedBuilding.id).level === selectedBuilding.levels) &&
          renderConfirmMenuUpgradedMax()} */}
        </div>
      </div>
    );
  };

  const renderConfirmMenuTitle = () => {
    if (selectedBuilding === null) return;
    return (
      <div className="energy_lab_confirm_menu_title">
        <div
          className="energy_lab_confirm_menu_exit_button"
          onClick={() => setSelectedBuilding(null)}
        >
          <motion.div
            className="center_container"
            whileHover={{ scale: 1.5, rotate: 180 }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
          >
            <img className="image" src={close} alt="close" />
          </motion.div>
        </div>
        <div className="center_container">
          <div className="text_black_medium_bold">
            {t(selectedBuilding.name)}
          </div>
        </div>
      </div>
    );
  };

  const renderConfirmMenuImage = () => {
    return (
      <div className="center_container">
        <img
          className="image"
          src={selectedBuilding?.image.url}
          alt="building"
        />
      </div>
    );
  };

  const renderConfirmMenuDescription = () => {
    if (selectedBuilding === null) return;
    return (
      <div className="center_container">
        <div className="text_black_very_small" style={{ padding: "1em" }}>
          {t(selectedBuilding.description)}
        </div>
      </div>
    );
  };

  const renderConfirmMenuBonuses = () => {
    if (buildingProgressFinder(selectedBuilding?.id) !== undefined) {
      return (
        <div className="energy_lab_confirm_menu_bonuses">
          {renderBonusKilowatt()}
          {renderBonusCO2()}
        </div>
      );
    } else {
      return (
        <div className="energy_lab_confirm_menu_bonuses">
          {renderBonusKilowattPurchase()}
          {renderBonusCO2Purchase()}
        </div>
      );
    }
  };

  const renderBonusKilowatt = () => {
    if (selectedBuilding === null) return;
    const buildingProgress = buildingProgressFinder(
      selectedBuilding.id
    ) as StudentPowerType;
    return (
      <div className="energy_lab_confirm_menu_bonus">
        <div className="center_container" style={{ height: "90%" }}>
          <img
            className="image"
            src={kilowattsIcon}
            alt="kw"
            style={{ minWidth: "35px" }}
          />
        </div>
        <div className="energy_lab_confirm_menu_bonus_data">
          <div className="energy_lab_confirm_menu_bonus_data_row">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {selectedBuilding.energy_purchase < 0
                ? Math.abs(buildingProgress.generated_power || 0)
                : buildingProgress.generated_power}
              {selectedBuilding.energy_purchase < 0 ? "%" : null}
            </div>

            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_yellow_small_bold"
                  : "text_yellow_very_small_bold"
              }
            >
              kW
            </div>

            <div className="energy_lab_confirm_line_through" />
          </div>
          <div className="energy_lab_confirm_menu_bonus_data_row">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_light_green_small_bold"
                  : "text_light_green_very_small_bold"
              }
            >
              {selectedBuilding.energy_purchase < 0
                ? Math.abs(
                    buildingProgress.generated_power +
                      selectedBuilding.energy_upgrade
                  )
                : buildingProgress.generated_power +
                  selectedBuilding.energy_upgrade}
              {selectedBuilding.energy_purchase < 0 ? "%" : null}
            </div>
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_yellow_small_bold"
                  : "text_yellow_very_small_bold"
              }
            >
              kW
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBonusCO2 = () => {
    if (selectedBuilding === null) return;
    const buildingProgress = buildingProgressFinder(
      selectedBuilding.id
    ) as StudentPowerType;
    let co2 = 0;
    if (selectedBuilding.co2_function !== null) {
      const x = buildingProgress.level + 1;
      co2 = Math.round(10 * eval(selectedBuilding.co2_function)) / 10;
    }
    return (
      <div className="energy_lab_confirm_menu_bonus">
        <div className="center_container" style={{ height: "90%" }}>
          <img
            className="image"
            src={co2Icon}
            alt="co2"
            style={{ minWidth: "35px" }}
          />
        </div>
        <div className="energy_lab_confirm_menu_bonus_data">
          <div className="energy_lab_confirm_menu_bonus_data_row">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {buildingProgress.generated_co2}
              {selectedBuilding.co2_purchase < 0 ? "%" : null}
            </div>
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_grey_small_bold"
                  : "text_grey_very_small_bold"
              }
            >
              T CO₂
            </div>
            <div className="energy_lab_confirm_line_through" />
          </div>
          <div className="energy_lab_confirm_menu_bonus_data_row">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? selectedBuilding.co2_purchase < 0
                    ? "text_light_green_small_bold"
                    : "text_red_small_bold"
                  : "text_white_very_small_bold"
              }
              style={{ height: "80%" }}
            >
              {selectedBuilding.co2_purchase < 0
                ? co2
                : buildingProgress.generated_co2 + selectedBuilding.co2_upgrade}
              {selectedBuilding.co2_purchase < 0 ? "%" : null}
            </div>
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_grey_small_bold"
                  : "text_grey_very_small_bold"
              }
            >
              T CO₂
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBonusKilowattPurchase = () => {
    if (selectedBuilding === null) return;
    return (
      <div
        className="energy_lab_confirm_menu_bonus"
        style={{ height: "80%", top: "10%" }}
      >
        <div className="center_container">
          <img
            className="image"
            src={kilowattsIcon}
            alt="kw"
            style={{ minWidth: "35px" }}
          />
        </div>
        <div className="energy_lab_confirm_menu_bonus_data_row">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_light_green_medium_bold"
                : "text_light_green_very_small_bold"
            }
          >
            {selectedBuilding.energy_purchase < 0
              ? Math.abs(selectedBuilding.energy_purchase)
              : selectedBuilding.energy_purchase}
            {selectedBuilding.energy_purchase < 0 ? "%" : null}
          </div>
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_yellow_medium_bold"
                : "text_yellow_very_small_bold"
            }
          >
            kW
          </div>
        </div>
      </div>
    );
  };

  const renderBonusCO2Purchase = () => {
    if (selectedBuilding === null) return;
    let co2 = 0;
    if (selectedBuilding.co2_function !== null) {
      const x = 1;
      co2 = Math.round(10 * eval(selectedBuilding.co2_function)) / 10;
    }
    return (
      <div
        className="energy_lab_confirm_menu_bonus"
        style={{ height: "80%", top: "10%" }}
      >
        <div className="center_container">
          <img
            className="image"
            src={co2Icon}
            alt="co2"
            style={{ minWidth: "35px" }}
          />
        </div>
        <div className="energy_lab_confirm_menu_bonus_data_row">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? selectedBuilding.co2_purchase < 0
                  ? "text_light_green_medium_bold"
                  : "text_red_medium_bold"
                : "text_white_very_small_bold"
            }
            style={{ height: "55%" }}
          >
            {selectedBuilding.co2_purchase < 0
              ? co2
              : selectedBuilding.co2_purchase}
            {selectedBuilding.co2_purchase < 0 ? "%" : null}
          </div>
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_grey_medium_bold"
                : "text_grey_very_small_bold"
            }
          >
            T CO₂
          </div>
        </div>
      </div>
    );
  };

  const renderConfirmMenuPurchase = () => {
    if (selectedBuilding === null) return;
    const buildingProgress = buildingProgressFinder(selectedBuilding.id);
    return (
      <motion.div
        className="energy_lab_confirm_menu_purchase"
        onClick={() => confirmBuildingHandler()}
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className="center_container" style={{ height: "80%" }}>
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_light_blue_small_bold"
                : "text_light_blue_very_small_bold"
            }
          >
            {buildingProgress !== undefined ? t("upgrade") : t("unlock")}
          </div>
        </div>
        <div className="energy_lab_confirm_menu_purchase_points">
          <div className="center_container" style={{ height: "80%" }}>
            <img className="image" src={pointsIcon} alt="points" />
          </div>
          <div className="center_container" style={{ height: "90%" }}>
            <div className="text_white_medium_bold">
              -{" "}
              {buildingProgress !== undefined
                ? selectedBuilding.points_upgrade
                : selectedBuilding.points_purchase}
            </div>
          </div>
          <div className="center_container" style={{ height: "90%" }}>
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_light_blue_small_bold"
                  : "text_light_blue_very_small_bold"
              }
            >
              {t("research_points_label_reduced")}
            </div>
          </div>
          <div />
        </div>
      </motion.div>
    );
  };

  const renderConfirmMenuUpgradedMax = () => {
    return (
      <div className="center_container">
        <div className="text_light_blue_small_bold">{t("upgraded_max")}</div>
      </div>
    );
  };

  return (
    <div className="energy_lab">
      {renderBanner()}
      <div className="energy_lab_background" />
      {renderCloseButton()}
      {renderTitle()}
      {renderBuildings()}
      {selectedBuilding !== null &&
        !playVideoConstruction &&
        renderConfirmMenu()}
      {selectedBuilding !== null &&
        playVideoConstruction &&
        renderPlayVideoConstruction(selectedBuilding, biomeNumber)}
    </div>
  );
};

export default EnergyLab;
