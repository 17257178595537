import env from "../../config/env.json";

export interface StudentChallengeType {
    id: string,
    done: boolean,
    student_id: string,
    challenge_id: string,
}

export const SetStudentChallengeAPI = async (challenge_id: string): Promise<StudentChallengeType> => {
    var req = await fetch(env.backend_host + "/student_challenge/" + challenge_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();
    return data;
}