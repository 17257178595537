import env from "../../config/env.json";
import { ExerciseType } from "./unit";

export const CreateExerciseAPI = async (language: string, type: string,  priority_index: number, priority_index_array: number[], research_id: string | null = null, experiment_id: string | null = null ): Promise<{exercises: ExerciseType[]}> => {
    if (research_id === null && experiment_id === null) throw new Error("You must provide a research id or an experiment id")
    var req = await fetch(env.backend_host + "/exercise/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({ language, type, priority_index, priority_index_array, research_id, experiment_id }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = req.json()
    return data
}

export const UpdateExerciseAPI = async (exercise_id: string, exercise: ExerciseType): Promise<ExerciseType> => {
    var req = await fetch(env.backend_host + "/exercise/" + exercise_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "body": JSON.stringify({ exercise }),
        "mode": "cors",
        "credentials": "include"
    });
    var data = req.json()
    return data
}

export const DeleteExerciseAPI = async (exercise_id: string, priority_index: number, research_id: string | null = null, experiment_id: string | null = null): Promise<{exercises: ExerciseType[]}> => {
    var req = await fetch(env.backend_host + "/exercise/" + exercise_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "DELETE",
        "mode": "cors",
        "body": JSON.stringify({ priority_index, research_id, experiment_id }),
        "credentials": "include"
    });
    var data = req.json()
    return data
}