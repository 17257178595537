import { GlobalCompetencePoints } from "../../_newapios/progress/student_global_competences"

export type AggregatedGlobalCompetencePoints = GlobalCompetencePoints

export const getGlobalCompetenceAggregates = (globalCompetences: GlobalCompetencePoints): AggregatedGlobalCompetencePoints => {
    /*[
        STEM: 3049382,
        CCD = 43892758492,
        CP: inf,
    ]*/
    const subcompetences = Object.keys(globalCompetences)
    const aggregatedGlobalCompetencePoints: AggregatedGlobalCompetencePoints = {}
    for (let i = 0; i < subcompetences.length; i++) {
        const subCompetencePoints = globalCompetences[subcompetences[i]]
        const competence = subcompetences[i].replace(/[^A-Za-z]/g, '');

        if (aggregatedGlobalCompetencePoints[competence] === undefined) {
            aggregatedGlobalCompetencePoints[competence] = subCompetencePoints
            continue
        }
        aggregatedGlobalCompetencePoints[competence] += subCompetencePoints
    }

    return aggregatedGlobalCompetencePoints
}