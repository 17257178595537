import "./InfoBar.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/hooks";
import kilowattsIcon from "../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../assets/student/population_icon.svg";
import co2Icon from "../../../assets/student/co2_icon.svg";

const InfoBar = ({ ...props }) => {
  const { t } = useTranslation();
  const studentInfo = useAppSelector((state) => state.student).infoBar;

  if (studentInfo == null || studentInfo.display == "hidden") {
    return <></>;
  }

  const studentName = studentInfo.name;
  const studentPower = studentInfo.power;
  const studentCO2 = studentInfo.co2;
  const studentPopulation = studentInfo.population;

  if (props.localMap) {
    return (
      <div
        className="plot_details_main"
        style={props.isTeacher ? { top: "89%" } : {}}
      >
        <div className="plot_details_text_wrapper">
          <div className="plot_details_text">
            <div className="center_container">
              <div className="text_white_medium_bold">{t("visit_text")}</div>
            </div>
          </div>
          <div
            className="plot_details_background"
            style={{ padding: 0, background: "none" }}
          >
            <div className="plot_details_title">{studentName}</div>
            <div className="plot_details_left">
              <div className="center_container">
                <img
                  className="image"
                  src={kilowattsIcon}
                  alt="kilowatts"
                  style={{ maxHeight: "20px" }}
                />
                <div className="text_white_very_small_bold">
                  {studentPower}kW
                </div>
              </div>
            </div>
            <div className="plot_details_center">
              <div className="center_container">
                <img
                  className="image"
                  src={co2Icon}
                  alt="co2"
                  style={{ maxHeight: "25px" }}
                />
                <div className="text_white_very_small_bold">{studentCO2}T</div>
              </div>
            </div>
            <div className="plot_details_right">
              <div className="center_container">
                <img
                  className="image"
                  src={populationIcon}
                  alt="population"
                  style={{ maxHeight: "20px" }}
                />
                <div className="text_white_very_small_bold">
                  {studentPopulation}
                  {t("ppl")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="plot_details_main"
        style={props.isTeacher ? { top: "89%" } : {}}
      >
        <div className="plot_details_background">
          <div className="plot_details_title">{studentName}</div>
          <div className="plot_details_left">
            <div className="center_container">
              <img
                className="image"
                src={kilowattsIcon}
                alt="kilowatts"
                style={{ maxHeight: "20px" }}
              />
              <div className="text_white_very_small_bold">{studentPower}kW</div>
            </div>
          </div>
          <div className="plot_details_center">
            <div className="center_container">
              <img
                className="image"
                src={co2Icon}
                alt="co2"
                style={{ maxHeight: "25px" }}
              />
              <div className="text_white_very_small_bold">{studentCO2}T</div>
            </div>
          </div>
          <div className="plot_details_right">
            <div className="center_container">
              <img
                className="image"
                src={populationIcon}
                alt="population"
                style={{ maxHeight: "20px" }}
              />
              <div className="text_white_very_small_bold">
                {studentPopulation}
                {t("ppl")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default InfoBar;
