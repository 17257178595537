import { GetMyStudentProgressAPI } from './../_newapios/progress/student_scenario';
import { StudentType } from "../_newapios/user/student";
import { GetStudentPowerAllAPI } from '../_newapios/progress/student_power';
import { GetStudentHouseAllAPI } from '../_newapios/progress/student_house';
import { GetStudentPointsAPI } from '../_newapios/progress/student_game_metrics';


export const calcTutorialPart = async (student: StudentType | undefined, localMapStudentId: string | undefined, end: boolean) => {

    if (student === undefined  || localMapStudentId !== undefined) {
        return "none";
    }

    if (student.plot_id === null) {
        return "choose_plot";
    }

    const scenarioProgresses = await GetMyStudentProgressAPI("me")
    const scenarioProgress = scenarioProgresses.find((progress) => {
        return progress.experiment_progress.length > 0;
    });
    console.log(scenarioProgresses, scenarioProgress)
    if (scenarioProgresses.length === 0 || (scenarioProgress !== undefined && scenarioProgress.experiment_progress.length === 0)) {
        return "campus";
    }

    const researchPoints = await GetStudentPointsAPI("me")
    const powerProgress = await GetStudentPowerAllAPI("me")

    if (powerProgress.length === 0) {
        if (researchPoints < 2) {
            return "repeat_campus";
        } else {
            return "energy_lab";
        }
    }

    const houseProgress = await GetStudentHouseAllAPI("me")
    if (houseProgress.length === 0) {
        return "workshop";
    }

    if (end) {
        return "end";
    }

    return "none";
}