import "./Definition.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { ResearchProgressType } from "../../../_newapios/progress/student_scenario";

const Definition = (props: {
  exercise: ExerciseType;
  researchProgress: ResearchProgressType | null;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  backHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const definition = props.exercise.main_text;
  const researchProgress = props.researchProgress;
  const totalExercises = props.totalExercises;
  const selectedExercise = props.exerciseIndex;
  const maxExercise = props.maxExercise;
  const research = props.research;

  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })
  // }, [])
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    if (selectedExercise < maxExercise) {
      props.updateLevel(direction, null, null);
    } else {
      props.updateLevel(direction, [""], true);
    }
  };

  const exitButtonHandler = () => {
    if (researchProgress !== null && researchProgress?.completed_on !== null) {
      props.backHandler();
    } else {
      arrowsHandler("right");
    }
  };

  const renderProfessor = () => {
    return (
      <div className="drag_drop_list_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="definition_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderExitButton = () => {
    return (
      <motion.div
        className="definition_exit_button"
        onClick={() => exitButtonHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("exit_inv")}</div>
        </div>
      </motion.div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="definition_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <div
          className="center_container"
          onClick={
            selectedExercise === totalExercises - 1
              ? () => {}
              : () => arrowsHandler("right")
          }
          style={{
            cursor:
              selectedExercise === totalExercises - 1
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={
              selectedExercise === totalExercises - 1 ? rightTransparent : right
            }
            alt="right"
          />
        </div>
      </div>
    );
  };

  const renderDefinition = () => {
    return (
      <div className="definition_definition_box">
        <div className="center_container">
          {!_.isUndefined(definition) && (
            <div
              className={
                definition.length < 265
                  ? "text_black_medium"
                  : "text_black_small"
              }
              style={{ padding: "2%" }}
            >
              {definition}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="definition">
      <img className="background" src={blueBackground} alt="bluebackground" />
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {selectedExercise === totalExercises - 1 && renderExitButton()}
      {research && renderArrows()}
      {renderDefinition()}
    </div>
  );
};

export default Definition;
