import "./DragDropOptionsEdit.css";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import { useState, useEffect } from "react";
import { Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const DragDropOptionsEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateDragDropOptions: (newDragDropOptions: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const [mainText, setMainText] = useState(props.exercise.main_text as string);
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [saveToDB, setSaveToDB] = useState<boolean>(false);

  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.main_text = mainText;
      exercise.options = options;
      props.updateDragDropOptions(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setMainText(props.exercise.main_text as string);
    setOptions(props.exercise.options as string[]);
  }, [props.exercise.text, props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const mainTextHandler = (newMainText: string) => {
    setMainText(newMainText);
  };

  const optionHandler = (newOption: string, newOptionIndex: number) => {
    var updatedOptions = [...options];
    // Editing option & solution routine
    updatedOptions[newOptionIndex] = newOption;
    // Deleting option & solution routine
    if (newOption == "") {
      updatedOptions.splice(newOptionIndex, 1);
    }
    // Updating state
    setOptions(updatedOptions);
    console.log(updatedOptions);
  };

  const addSolutionHandler = () => {
    var updatedOptions = [...options];
    updatedOptions.push("");
    setOptions(updatedOptions);
    console.log(updatedOptions);
  };

  const deleteOptionHandler = (optionIndex: number) => {
    var updatedOptions = [...options];
    updatedOptions.splice(optionIndex, 1);
    setOptions(updatedOptions);
    setSaveToDB(true);
    console.log(updatedOptions);
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_drag_drop_options_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderProfessor = () => {
    return (
      <div className="edit_drag_drop_options_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_drag_drop_options_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_drag_drop_options_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderTextBoxMain = () => {
    return (
      <motion.div
        className="edit_drag_drop_options_text_box_main"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(mainText) && (
          <div
            className={
              mainText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={mainText}
              resize={"none"}
              onChange={(e) => mainTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderAddButton = (currentOptions: string[]) => {
    if (currentOptions?.length < 5) {
      return (
        <motion.div
          className="edit_drag_drop_options_add_option"
          onClick={() => addSolutionHandler()}
          layout
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "4vh" }} />
          </div>
          <div />
        </motion.div>
      );
    }
  };

  const renderDragDrop = () => {
    return (
      <div className="edit_drag_drop_options_background">
        <motion.div
          className="edit_drag_drop_options_background_grid"
          layoutRoot
          style={
            options?.length < 5
              ? {
                gridTemplateColumns:
                  "repeat(" +
                  (options?.length + 1) +
                  ", " +
                  100 / (options?.length + 1) +
                  "%)",
              }
              : {
                gridTemplateColumns:
                  "repeat(" +
                  options?.length +
                  ", " +
                  100 / options?.length +
                  "%)",
              }
          }
        >
          {options?.map((column, columnIndex) => {
            return (
              <motion.div
                className="edit_drag_drop_options_background_column"
                layout
                key={"column" + columnIndex}
              >
                <div className="edit_drag_drop_options_background_column_left">
                  <div className="center_container">
                    <div className="text_white_medium">{columnIndex + 1}</div>
                  </div>
                  <motion.div
                    className="edit_drag_drop_options_draggable"
                    whileHover={{
                      scale: 1.02,
                      boxShadow: "0px 0px 10px 1px #5090F0",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <Textarea
                        variant="unstyled"
                        placeholder={"Opción " + (columnIndex + 1)}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          fontFamily: "Causten",
                          fontSize: "1.5em",
                          fontWeight: "bold",
                        }}
                        value={column}
                        resize={"none"}
                        onChange={(e) =>
                          optionHandler(e.target.value, columnIndex)
                        }
                        onBlur={() => setSaveToDB(true)}
                      />
                    </div>
                  </motion.div>
                  <div className="top_center_container">
                    <motion.div
                      className="edit_drag_drop_options_option_delete"
                      onClick={() => deleteOptionHandler(columnIndex)}
                      whileHover={{
                        scale: 1.1,
                        boxShadow: "0px 0px 10px 1px #F0684F",
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      style={{
                        cursor:
                          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      }}
                    >
                      <div className="center_container">
                        <img src={bin} style={{ height: "4vh" }} />
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            );
          })}
          {renderAddButton(options)}
        </motion.div>
      </div>
    );
  };

  return (
    <div className="edit_drag_drop_options">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderTextBoxMain()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropOptionsEdit;
