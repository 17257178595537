import { useTranslation } from "react-i18next";
import {
  Text3D,
  Center,
  Float,
  useGLTF,
  useAnimations,
} from "@react-three/drei";
import { Group, MeshPhongMaterial, Vector3 } from "three";
import { useEffect, useRef } from "react";
import { DreiGLTF } from "../../../../interfaces.ts";
import { convertToVector3 } from "../../../../utils/R3F.ts";

const StaticClickableAssets = ({ ...props }) => {
  const { t } = useTranslation();
  const flechaRef = useRef<Group>(null!);
  const tutorialPart = props.tutorial;

  const flecha = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/globalMap/indicador_rombo_global.gltf"
  ) as DreiGLTF;

  const bakedflechas = new MeshPhongMaterial({
    color: 0xfb9e12,
    emissive: 0xfb9e12,
    emissiveIntensity: 0,
    shininess: 0,
    fog: true,
  });

  const { actions } = useAnimations(flecha.animations, flechaRef);

  useEffect(() => {
    if (
      actions["CubeAction.001"] !== null &&
      (tutorialPart === "campus" ||
        tutorialPart === "energy_lab" ||
        tutorialPart === "workshop")
    ) {
      if (props.renderAnimation()) {
        actions["CubeAction.001"].play();
      } else {
        actions["CubeAction.001"].stop();
      }
    }
  }, [props.renderAnimation()]);

  const calcArrowPosition = (part: string) => {
    switch (part) {
      case "campus":
        return [-3.2, 4.63, 4.35];
      case "energy_lab":
        return [2.72, 4.63, -5.7];
      case "workshop":
        return [9.14, 4.63, 5.08];
      default:
        return [0, 0, 0];
    }
  };

  const renderArrow = (position: number[]) => {
    return (
      <Float
        rotationIntensity={0}
        speed={props.renderAnimation() ? 5 : 0}
        floatingRange={[-0.5, 0.5]}
      >
        <group ref={flechaRef} {...props} dispose={null}>
          <group name="Scene">
            <mesh
              name="Cube"
              castShadow
              receiveShadow
              geometry={flecha.nodes.Cube.geometry}
              material={bakedflechas}
              position={convertToVector3(position)}
              scale={[0.33, 0.45, 0.33]}
            />
          </group>
        </group>
      </Float>
    );
  };

  return (
    <group {...props} dispose={null}>
      {/* <mesh
        castShadow = {props.renderShadow()}
        receiveShadow = {props.renderShadow()}
        geometry={props.GLTF.nodes["building-lotus-india"].geometry}
        material={props.bakedAssetsMaterial}
        position={[-5.54, 0.3, 9.56]}
        rotation={[0, -0.14, 0]}
        scale={0.09}
        onClick={() => props.openChallenges()}
      /> */}

      {(tutorialPart === "campus" ||
        tutorialPart === "energy_lab" ||
        tutorialPart === "workshop") &&
        renderArrow(calcArrowPosition(tutorialPart))}

      <Float
        rotationIntensity={0}
        speed={props.renderAnimation() ? 3 : 0}
        floatingRange={[0, 0.5]}
      >
        <Center
          position={[-13.87, 5.73, -0.41]}
          rotation={[-0.03, 2.25, -0.03]}
        >
          <Text3D
            font="./Causten Bold_Regular.json"
            size={0.5}
            height={0.01}
            curveSegments={12}
            bevelEnabled
            bevelThickness={0.02}
            bevelSize={0.02}
            bevelOffset={0}
            bevelSegments={5}
          >
            {t("balloon")}
            <meshStandardMaterial color={"#4da7FF"} />
          </Text3D>
        </Center>
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes.Globo.geometry}
          material={props.bakedAssetsMaterial}
          position={[-13.87, 2.23, -0.41]}
          scale={[0.08, 0.08, 0.1]}
          onClick={() => {
            props.toggleMap();
          }}
        />
      </Float>
      <Center position={[-13.88, 3.02, 5.69]} rotation={[-0.03, 2.4, -0.03]}>
        <Text3D
          font="./Causten Bold_Regular.json"
          size={0.5}
          height={0.01}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
        >
          {t("my_home")}
          <meshStandardMaterial color={"#4da7FF"} />
        </Text3D>
      </Center>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.casa_alumno.geometry}
        material={props.bakedAssetsMaterial}
        position={[-13.895, 1.077, 5.701]}
        rotation={[Math.PI, -0.037, Math.PI]}
        scale={0.14}
        onClick={() => props.openHome()}
      />
      {/* <mesh
        castShadow = {props.renderShadow()}
        receiveShadow = {props.renderShadow()}
        geometry={props.GLTF.nodes.taller.geometry}
        material={props.bakedAssetsMaterial}
        position={[9.11, 0.38, 5.09]}
        rotation={[3.09, 0.99, -3.1]}
        scale={0.11}
        onClick={() => props.openWorkshop()}
      /> */}
      <Center position={[9.14, 2.98, 5.08]} rotation={[-0.03, 2.8, -0.03]}>
        <Text3D
          font="./Causten Bold_Regular.json"
          size={0.5}
          height={0.01}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
        >
          {t("workshop")}
          <meshStandardMaterial
            color={tutorialPart === "workshop" ? "#fb9e12" : "#4da7FF"}
          />
        </Text3D>
      </Center>
      <group
        position={[9.14, 0.48, 5.08]}
        rotation={[3.05, 0.98, -3.11]}
        scale={0.11}
        onClick={() => props.openWorkshop()}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_1"].geometry}
          material={props.GLTF.nodes["industry-factory-hall002_1"].material}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_2"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_3"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_4"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_5"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["industry-factory-hall002_6"].geometry}
          material={props.bakedAssetsMaterial}
        />
      </group>
      {/* <mesh
        castShadow = {props.renderShadow()}
        receiveShadow = {props.renderShadow()}
        geometry={props.GLTF.nodes.ayuntamiento.geometry}
        material={props.bakedAssetsMaterial}
        position={[-3.2, 1.14, 4.35]}
        rotation={[0, 1.56, 0]}
        scale={0.1}
        onClick={() => props.openTownhall()}
      /> */}

      <Center position={[-3.2, 3.13, 4.35]} rotation={[-0.03, 2.5, -0.03]}>
        <Text3D
          font="./Causten Bold_Regular.json"
          size={0.5}
          height={0.01}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
        >
          {t("campus")}
          <meshStandardMaterial
            color={tutorialPart === "campus" ? "#fb9e12" : "#4da7FF"}
          />
        </Text3D>
      </Center>
      <group
        position={[-3.2, 1.12, 4.35]}
        rotation={[0, 1.56, 0]}
        scale={0.1}
        onClick={() => props.openLab()}
      >
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002_1"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002_2"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002_3"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002_4"].geometry}
          material={props.bakedAssetsMaterial}
        />
        <mesh
          castShadow={props.renderShadow()}
          receiveShadow={props.renderShadow()}
          geometry={props.GLTF.nodes["building-bank002_5"].geometry}
          material={props.bakedAssetsMaterial}
        />
      </group>
      <Center
        position={[2.72, 3.04, -5.7]}
        rotation={[Math.PI / 8, 2.1, -Math.PI / 12]}
      >
        <Text3D
          font="./Causten Bold_Regular.json"
          size={0.5}
          height={0.01}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
        >
          {t("energy_lab")}
          <meshStandardMaterial
            color={tutorialPart === "energy_lab" ? "#fb9e12" : "#4da7FF"}
          />
        </Text3D>
      </Center>
      <mesh
        castShadow={props.renderShadow()}
        receiveShadow={props.renderShadow()}
        geometry={props.GLTF.nodes.laboratorio.geometry}
        material={props.bakedAssetsMaterial}
        position={[2.72, 0.54, -5.7]}
        rotation={[0, 1.57, 0]}
        scale={0.12}
        onClick={() => props.openEnergyLab()}
      />
    </group>
  );
};

export default StaticClickableAssets;
