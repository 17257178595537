import env from "../../config/env.json";

export interface StudentPowerType {
    id: string,
    level: number,
    points_used: number,
    generated_power: number,
    generated_co2: number,
    power_upgrade_id: string,
    student_id: string,
}


export const SetStudentPowerAPI = async (power_upgrade_id: string): Promise<StudentPowerType> => {
    var req = await fetch(env.backend_host + "/me/student_power/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "body": JSON.stringify({
            power_upgrade_id: power_upgrade_id
        }),
        "credentials": "include"
    });
    var data = await req.json();
    return data;
}

export const UpdateStudentPowerAPI = async (student_power_id: string): Promise<StudentPowerType> => {
    var req = await fetch(env.backend_host + "/me/student_power/" + student_power_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "POST",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();
    return data;
}

export const GetStudentPowerAllAPI = async (student_id: string): Promise<StudentPowerType[]> => {
    var req = await fetch(env.backend_host + "/student_power/" + student_id + "/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}
