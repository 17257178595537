import env from "../../config/env.json";

export interface RegulationType {
    id: string,
    country: string
    name: string,
    age: number,
    color: string,
}

export const GetAllRegulationsAPI = async (): Promise<RegulationType[]> => {
    var req = await fetch(env.backend_host + "/regulation/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || []
    return data;
}

