import env from "../../config/env.json";
import { ImageType } from "../content/unit";
import { StudentChallengeType } from "../progress/student_challenge";

export interface ChallengeType {
    id: string,
    name: string,
    description: string,
    multiplayer: boolean,
    theme_id: string,
    image: ImageType,
    student_challenge?: StudentChallengeType[],
}


export const GetAllChallengesAPI = async (): Promise<ChallengeType[]> => {
    var req = await fetch(env.backend_host + "/challenge/all", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}