import "./Leaderboard.css";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LeaderboardButton from "../../../assets/student/leaderboard_icon.svg";
import co2Icon from "../../../assets/student/co2_icon.svg";
import kilowattsIcon from "../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../assets/student/population_icon.svg";
import { useAppSelector } from "../../../hooks/hooks";
import {
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
} from "../../../_newapios/progress/student_game_metrics";
import { StudentType } from "../../../_newapios/user/student";
import _ from "lodash";

export interface StudentMetrics {
  name: string;
  population: number;
  power: number;
  co2: number;
  points: number;
}

const Leaderboard = () => {
  const { t } = useTranslation();

  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);

  const mainState = useAppSelector((state) => state.main);
  const students = mainState.students;

  const [studentsMetrics, setStudentsMetrics] = useState<StudentMetrics[]>([]);

  useEffect(() => {
    loadStudentsMetrics();
  }, [students]);

  if (students === null) {
    return;
  }

  const loadStudentsMetrics = async () => {
    const temp_students_metrics = await Promise.all(
      students?.map(async (student) => {
        const temp_student_metrics = await calcStudentMetrics(student);

        return temp_student_metrics;
      })
    );
    setStudentsMetrics(temp_students_metrics);
  };

  const calcStudentMetrics = async (student: StudentType) => {
    const studentPopulation = await GetStudentPopulationAPI(student.id);
    const studentPower = await GetStudentWattsAPI(student.id);
    const studentCO2 = await GetStudentCO2API(student.id);
    // const studentPoints = await GetStudentPointsAPI(student.id);

    const studentPopulationValue = studentPopulation
      ? Math.round(studentPopulation * 100) / 100
      : 0;
    const studentPowerValue = studentPower
      ? Math.round(studentPower * 100) / 100
      : 0;
    const studentCO2Value = studentCO2 ? Math.round(studentCO2 * 100) / 100 : 0;
    const studentPointsValue = 0; // studentPoints || 0;

    return {
      name: student.username,
      population: studentPopulationValue,
      power: studentPowerValue,
      co2: studentCO2Value,
      points: studentPointsValue,
    };
  };

  const renderSingleStudentLeaderboard = (student: StudentMetrics) => {
    const { name, population, power, co2, points } = student;
    return (
      <div className="student_leaderboard">
        <div className="left_container" style={{ paddingLeft: "15px" }}>
          <div className="text_white_very_small_bold">{name}</div>
        </div>
        <div className="center_container">
          <img
            className="image"
            src={kilowattsIcon}
            alt="kilowatts"
            style={{ maxHeight: "20px" }}
          />
          <div className="text_white_very_small_bold">{power}kW</div>
        </div>
        <div className="center_container">
          <img
            className="image"
            src={co2Icon}
            alt="co2"
            style={{ maxHeight: "25px" }}
          />
          <div className="text_white_very_small_bold">{co2}T</div>
        </div>
        <div className="center_container">
          <img
            className="image"
            src={populationIcon}
            alt="population"
            style={{ maxHeight: "20px" }}
          />
          <div className="text_white_very_small_bold">
            {population}
            {t("ppl")}
          </div>
        </div>
      </div>
    );
  };

  const renderLeaderboard = () => {
    if (isLeaderboardOpen) {
      return (
        <div
          className="leaderboard_students_container"
          style={{ gridTemplateRows: "1fr 9fr" }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">{t("leaderboard")}</div>
          </div>
          <div>
            {_.orderBy(studentsMetrics, ["population"], ["desc"]).map(
              (student) => renderSingleStudentLeaderboard(student)
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="center_container">
          <img
            className="image"
            src={LeaderboardButton}
            alt="leaderboardButton"
            style={{ height: "3vh" }}
          />
        </div>
      );
    }
  };

  return (
    <motion.div
      layout
      data-isOpen={isLeaderboardOpen}
      initial={{}}
      whileHover={!isLeaderboardOpen ? { scale: 1.1, rotate: 360 } : {}}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      onClick={() => setIsLeaderboardOpen(!isLeaderboardOpen)}
      className="leaderboard_button"
    >
      {renderLeaderboard()}
    </motion.div>
  );
};

export default Leaderboard;
