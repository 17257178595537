import env from "../../../config/env.json";
import "./UserMenu.css";
import { useState } from "react";
import { useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import LogButton from "../../../assets/professor/log_button.tsx";
import close from "../../../assets/professor/close_user_menu.svg";
import { LazyMotion, m } from "framer-motion";
import { LogoutAPI } from "../../../_newapios/auth/local.ts";

const UserMenu = ({ ...props }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState(false);

  const teacherSlice = useAppSelector((state) => state.teacher);

  const userHandler = () => {
    setUser(!user);
  };

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const renderUserMenu = () => {
    return (
      <div className="user_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="user_menu">
            <div className="user_menu_close" onClick={() => userHandler()}>
              <div className="right_container">
                <m.img
                  className="image"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.2, rotate: 180 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 10,
                    duration: 1,
                  }}
                />
              </div>
            </div>
            <div className="center_container">
              <div className="user_icon" style={{ top: "10%" }}>
                <div className="center_container">
                  <div className="text_white_big_bold">
                    {teacherSlice.fullName.charAt(0).toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
            <div className="user_fullname">
              <div className="center_container">
                <div className="text_black_medium_bold">
                  {teacherSlice.fullName}
                </div>
              </div>
            </div>
            <m.div
              className="logout_button"
              onClick={async () => {
                LogoutAPI().then(
                  (logout) => window.location.reload()
                  //env.backend_host + "/logout?path=" + window.location.href
                );
              }}
              whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 0px #F06D50" }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
                duration: 1,
              }}
            >
              <div className="center_container">
                <div className="text_white_small_bold">{t("log_out")}</div>
              </div>
            </m.div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  return (
    <div>
      {user != false && renderUserMenu()}
      <LazyMotion features={loadFeatures}>
        <m.div
          whileHover={{ scale: 1.3 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          className="professor_user_button"
        >
          <div className="center_container"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={userHandler}>
            {/* <img
              className="image"
              src={userButton}
              alt="UserIcon"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={() => userHandler()}
            /> */}
            <LogButton
              color={props.color}
              size={36}
            />
          </div>
        </m.div>
      </LazyMotion>
    </div>
  );
};

export default UserMenu;
