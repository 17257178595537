import "./DragDropList.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import help from "../../../assets/exercises/help.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";
import { Idirection } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";

const DragDropList = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const options = props.exercise.options as string[];
  const labels = props.exercise.labels as string[];

  const [answers, setAnswers] = useState<string[]>([]);

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);

  const [helpOpen, setHelpOpen] = useState(false);
  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);
  // useEffect (() => {
  //   lottie.loadAnimation({
  //     container: blueAnimation.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice"
  //     },
  //     animationData: blueBackground
  //   })
  // }, [])
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    } else {
      setAnswers(_.shuffle(options));
    }
  }, [options]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const arrowsHandler = (direction: Idirection) => {
    props.updateLevel(direction, null, null);
  };

  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i] !== answers[i]) {
        correct = false;
        wrongIndexes.push(i);
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const updateOrder = (src: number, des: number) => {
    let newOrder = [...answers];

    //Remove item
    const [removed] = newOrder.splice(src, 1);
    //Add item to the column in the new position.
    newOrder.splice(des, 0, removed);

    setAnswers(newOrder);
  };

  const renderProfessor = () => {
    return (
      <div className="drag_drop_list_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <div className="drag_drop_list_text_box">
        <div className="center_container">
          {!_.isUndefined(text) && (
            <div
              className={
                text.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArrows = () => {
    return (
      <div className="choose_many_arrows">
        <motion.div
          className="center_container"
          onClick={
            selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
          }
          whileHover={{ scale: selectedExercise === 0 ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === 0
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === 0 ? leftTransparent : left}
            alt="left"
          />
        </motion.div>
        <motion.div
          className="center_container"
          onClick={() => {
            if (selectedExercise < maxExercise) arrowsHandler("right");
          }}
          whileHover={{ scale: selectedExercise === maxExercise ? 1 : 1.3 }}
          style={{
            cursor:
              selectedExercise === maxExercise
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img
            className="icon"
            src={selectedExercise === maxExercise ? rightTransparent : right}
            alt="right"
          />
        </motion.div>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <motion.div
        className="drag_drop_list_confirm"
        onClick={() => confirmHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("confirm")}</div>
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="drag_drop_list_help_button">
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_list_background">
        <div className="drag_drop_list_background_components">
          <div className="drag_drop_list_top">
            <div className="center_container">
              {labels && labels[0] !== undefined && (
                <div
                  className={
                    labels[0].length < 20
                      ? "text_white_medium_bold"
                      : "text_white_small_bold"
                  }
                >
                  {labels[0]}
                </div>
              )}
            </div>
          </div>
          <div className="drag_drop_list_center">
            <DragDropContext
              onDragEnd={(param) => {
                const src = param.source.index;
                const des =
                  param.destination === undefined || param.destination === null
                    ? param.source.index
                    : param.destination.index;

                updateOrder(src, des);
              }}
            >
              <Droppable droppableId={"1"} key={"1"}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="drag_drop_list_droppable"
                  >
                    {answers.map((item, i) => (
                      <Draggable
                        key={item}
                        draggableId={"draggable-" + item}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="drag_drop_list_draggable"
                            style={{
                              top: 7 + 5.5 * i + "%",
                              ...provided.draggableProps.style,

                              backgroundColor:
                                incorrectFeedback === true
                                  ? incorrectIndexes.includes(i)
                                    ? "#F6A495"
                                    : ""
                                  : "",
                            }}
                          >
                            <motion.div
                              className="center_container"
                              whileHover={{ scale: 1.04 }}
                              transition={{
                                type: "spring",
                                stiffness: 400,
                                damping: 10,
                              }}
                            >
                              <div
                                className={
                                  item.length < 30
                                    ? "text_black_medium"
                                    : "text_black_small"
                                }
                              >
                                {item}
                              </div>
                            </motion.div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="drag_drop_list_bottom">
            <div className="center_container">
              {labels && labels[1] !== undefined && (
                <div
                  className={
                    labels[1].length < 20
                      ? "text_white_medium_bold"
                      : "text_white_small_bold"
                  }
                >
                  {labels[1]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBackground = () => {
    if (incorrectFeedback) {
      setTimeout(() => {
        setIncorrectFeedback(false);
      }, 2000);
    }
    return (
      <img
        className="background"
        src={incorrectFeedback ? redBackground : blueBackground}
        alt="background"
      />
    );
  };

  const renderHelpMenu = () => {
    return (
      <div className="drag_drop_list_help_menu">
        <div className="center_container">
          {!_.isUndefined(helpText) && (
            <div
              className={
                helpText.length < 265 ? "text_black_medium" : "text_black_small"
              }
            >
              {helpText}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="drag_drop_list">
      {renderBackground()}
      {screenSize.dynamicWidth > 991 && renderProfessor()}
      {renderTextBox()}
      {props.research && renderArrows()}
      {renderConfirm()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropList;
