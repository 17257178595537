import env from "../../config/env.json";


export type SpecificCompetencePoints = { [key: string]: number };

export const GetStudentSpecificCompetencesAPI = async (id: string, course_id: string): Promise<SpecificCompetencePoints> => {
    var req = await fetch(env.backend_host + "/student/" + id + "/specific_competences/" + course_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}

export const GetClassSpecificCompetencesAPI = async (id: string, course_id: string): Promise<SpecificCompetencePoints> => {
    var req = await fetch(env.backend_host + "/class/" + id + "/specific_competences/" + course_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();

    data = data || null
    return data;
}
