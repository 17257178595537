// import env from "../../config/env.json";
import "./LandingPage.css";
import { useAppDispatch } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState, useEffect, createRef } from "react";
import {
  Input,
  Button,
  Stack,
  InputGroup,
  InputRightElement,
  Flex,
  Spacer,
  Divider,
  Center,
  Heading,
  Text,
  Checkbox,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { CloseIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";

import {
  TeacherLoginAPI,
  StudentLoginAPI,
  StudentRegisterAPI,
  TeacherRegisterAPI,
  TeacherVerificationAPI,
  TeacherRequestResetAPI,
  ErrorType,
  TeacherResetPasswordAPI,
} from "../../_newapios/auth/local.ts";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "universal-cookie";

import pass0 from "../../assets/Login/0.svg";
import pass1 from "../../assets/Login/1.svg";
import pass2 from "../../assets/Login/2.svg";
import pass3 from "../../assets/Login/3.svg";
import pass4 from "../../assets/Login/4.svg";
import pass5 from "../../assets/Login/5.svg";
import pass6 from "../../assets/Login/0.svg"; // TODO - CHANGE THIS TO BLANK IMAGE
import pass7 from "../../assets/Login/backbutton.svg";
import BackArrow from "../../assets/exercises/back_arrow";
import { updateLogin, updateUser } from "../../reducers/loginSlice";
import { UserType } from "../../_newapios/user/user.ts";

const LandingPage = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const { t, i18n } = useTranslation();

  let newProfanityWords = [
    "baboso",
    "beocio",
    "beocia",
    "besugo",
    "besuga",
    "bobo",
    "boba",
    "bruto",
    "bruta",
    "bucéfalo",
    "bucefalo",
    "calilo",
    "calila",
    "lilo",
    "lila",
    "calzonudo",
    "calzonuda",
    "capullo",
    "carechimba",
    "cazurro",
    "cazurra",
    "cebollino",
    "cebollina",
    "cenutrio",
    "ceporro",
    "cipote",
    "zipote",
    "cojudo",
    "cojuda",
    "cojonudo",
    "cojonuda",
    "cretino",
    "cretina",
    "cutama",
    "cutre",
    "chango",
    "changa",
    "chorra",
    "estúpido",
    "estúpida",
    "estupido",
    "estupida",
    "ganso",
    "gansa",
    "gilí",
    "gili",
    "gilipollas",
    "jilipollas",
    "gonorrea",
    "gordo",
    "gorda",
    "idiota",
    "insensato",
    "insensata",
    "lerdo",
    "lerda",
    "lipendi",
    "madero",
    "madrero",
    "malparido",
    "mamacallos",
    "mameluco",
    "mastuerzo",
    "maricón",
    "maricón",
    "maricona",
    "melón",
    "melon",
    "melona",
    "memo",
    "mema",
    "mendrugo",
    "mendruga",
    "menguado",
    "menguada",
    "menso",
    "mensa",
    "mentecato",
    "mentecata",
    "merluzo",
    "minguado",
    "minguada",
    "molondro",
    "muérgano",
    "pendejo",
    "puto",
    "puta",
    "rácano",
    "racano",
    "esgraciado",
    "desgraciada",
    "tarado",
    "tarada",
    "tolondrón",
    "tolondróna",
    "valeverga",
    "verga",
    "polla",
    "mierda",
    "mohon",
    "shit",
    "fuck",
    "fook",
    "motherfucker",
    "zopenco",
    "zopenca",
    "cabron",
    "bastardo",
    "bastarda",
    "asqueroso",
    "asquerosa",
    "feo",
    "fea",
    "tarado",
    "tarada",
    "puñetas",
    "culero",
    "carechimba",
    "gonorrea",
    "chocho",
    "coño",
    "zoro",
    "zorra",
    "tetas",
    "pito",
    "webos",
    "follar",
    "cascarla",
    "faggot",
    "chuparla",
    "mamarla",
    "correrse",
    "sobar",
    "sobarsela",
    "soplapollas",
    "tortillera",
    "cornudo",
    "zorrilla",
    "cagar",
    "cago",
    "cagada",
    "mierda",
    "meada",
    "cajetuda",
    "argolluda",
    "cojón",
    "cojon",
    "cojones",
    "comemierda",
    "culeado",
    "facha",
    "fachas",
    "chapas",
    "joputa",
    "joeputa",
    "pinche",
    "bambarria",
    "boludo",
    "pelotudo",
    "arsehole",
    "barmpot",
    "barmy",
    "berk",
    "billy",
    "bint",
    "blighter",
    "bloody",
    "blooming",
    "bollocks",
    "charva",
    "chav",
    "cheeky",
    "codger",
    "dago",
    "divvy",
    "dodgy",
    "dozy",
    "eejit",
    "git",
    "gormless",
    "grotty",
    "manky",
    "munter",
    "nancy",
    "numpty",
    "nutter",
    "pikey",
    "pillock",
    "poxy",
    "prat",
    "scrubber",
    "shite",
    "skanky",
    "wazzack",
    "goofy",
    "bobalias",
    "bobatel",
    "silly",
    "fool",
    "putain",
    "chienne",
    "salaud",
    "salope",
    "chatte",
    "chatte",
    "bite",
    "merde",
    "paja",
  ];

  var Filter = require("bad-words-es"),
    filter = new Filter();
  filter.addWords(...newProfanityWords);

  const [IsErraneousStudent, setErrorStudent] = useState(false);
  const [IsErraneousTeacher, setErrorTeacher] = useState(false);

  const [IsErraneousStudentUsernameRegister, setErrorStudentUsernameRegister] =
    useState(false);
  const [IsErraneousStudentPinRegister, setErrorStudentPinRegister] =
    useState(false);

  const [IsErraneousTeacherFullnameRegister, setErrorTeacherFullnameRegister] =
    useState(false);
  const [IsErraneousTeacherUsernameRegister, setErrorTeacherUsernameRegister] =
    useState(false);
  const [IsErraneousTeacherPasswordRegister, setErrorTeacherPasswordRegister] =
    useState(false);
  const [
    IsErraneousTeacherSchoolRegister,
    setIsErraneousTeacherSchoolRegister,
  ] = useState(false);
  const [IsErraneousSchoolPinRegister, setErrorSchoolPinRegister] =
    useState(false);
  const [IsErraneousTeacherRegistrationCode, setErrorTeacherRegistrationCode] =
    useState(false);

  // const [IsErraneousPin, setErrorPin] = useState(false);
  // const [licenseError, setLicenseError] = useState(false);

  const [TeacherLogin, setTeacherLogin] = useState(false);
  const [TeacherRequestPassword, setTeacherRequestPassword] = useState(false);
  const [TeacherInputPassword, setTeacherInputPassword] = useState(false);
  const [StudentLogin, setStudentLogin] = useState(false);

  const [classPinInput, setClassPinInput] = useState<string>("");
  const [schoolPinInput, setSchoolPinInput] = useState<string>("");

  const [TeacherRegistration, setTeacherRegistration] = useState(false);
  const [TeacherRegistrationCode, setTeacherRegistrationCode] = useState(false);
  const [StudentRegistration, setStudentRegistration] = useState(false);

  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [newUserPassword, setNewUserPassword] = useState("");
  const [checkNewUserPassword, setCheckNewUserPassword] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFullname, setNewUserFullname] = useState("");
  const [newUserSchool, setNewUserSchool] = useState("");

  const [verificationCode, setVerificationCode1] = useState("");

  // const [userPin, setUserPin] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [pinExists, setPinExists] = useState(false);

  // this is used to DISPLAY student password
  const [studentPassword, setStudentPassword] = useState<string | null>(null);

  const [studentLoginPassword, setStudentLoginPassword] = useState("");

  const [studentName, setStudentName] = useState("");

  const recaptchaRef = createRef<ReCAPTCHA>();

  const color0 = "#F0684F";
  const color1 = "#F3BF81";
  const color2 = "#F9D45F";
  const color3 = "#BAF381";
  const color4 = "#81B6F3";
  const color5 = "#C881F3";
  const color6 = "#FFFFFF";
  const color7 = "#AAAAAA";

  const passSymbols = [pass0, pass1, pass2, pass3, pass4, pass5, pass6, pass7];
  const passColors = [
    color0,
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
  ];

  const languages = ["es", "en", "de", "cat", "val"];

  useEffect(() => {
    if (cookies.get("lang") == null) {
      cookies.set("lang", i18n.language, { path: "/" });
    } else {
      const lang = cookies.get("lang").split("-")[0];
      i18n.changeLanguage(lang);
    }
    if (!languages.includes(i18n.language)) {
      // If language is ill defined, fallback to S🅱anish. Probably need to change it when we are mr worldwide.
      i18n.changeLanguage("es");
      cookies.set("lang", i18n.language, { path: "/" });
      // window.location.reload()
    }
    // i18n.changeLanguage("es");
  }, [i18n.language]);

  const handleError = (type: string) => {
    if (type === "teacher") {
      setErrorTeacher(true);
    }
    if (type === "student") {
      setErrorStudent(true);
    }
    if (type === "studentregister") {
      setErrorStudentUsernameRegister(true);
    }
    if (type === "userteacherregister") {
      setErrorTeacherUsernameRegister(true);
    }
    if (type === "passwordteacherregister") {
      setErrorTeacherPasswordRegister(true);
    }
  };

  const changeLanguage = (lng: any) => {
    cookies.set("lang", lng, { path: "/" });
    i18n.changeLanguage(lng);
  };

  const handlePage = (state: string) => {
    switch (state) {
      case "teacherlogin":
        setTeacherLogin(true);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(false);
        break;
      case "teacherregistration":
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(true);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(false);
        break;
      case "studentlogin":
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(true);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(false);
        break;
      case "studentregistration":
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(true);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(false);
        break;
      case "teacherregistrationcode":
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(true);
        setTeacherInputPassword(false);
        break;
      case "requestpassword":
        setTeacherLogin(false);
        setTeacherRequestPassword(true);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(false);
        break;
      case "teacherinputpassword":
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
        setTeacherInputPassword(true);
        break;
      case "initial":
      default:
        setTeacherLogin(false);
        setTeacherRequestPassword(false);
        setStudentLogin(false);
        setTeacherRegistration(false);
        setStudentRegistration(false);
        setTeacherRegistrationCode(false);
    }
  };

  const handleRequestPassword = async (event: any) => {
    const username = userEmail;
    const captcha = recaptchaRef.current?.getValue() || "";

    event.preventDefault();
    await TeacherRequestResetAPI(username, captcha);
    handlePage("teacherinputpassword");
  };

  const handleLoginTeacher = async (event: any) => {
    event.preventDefault();

    var data = (await TeacherLoginAPI(userEmail, userPassword)) as UserType;

    if (
      data !== null &&
      (data as unknown as ErrorType).message === "Email verification pending"
    ) {
      //NEEDS VERIFICATION
      handlePage("teacherregistrationcode");
    } else if (data !== null && data.type === "teacher") {
      //LOGIN SUCCESSFULL
      props.handleLogin();
    } else {
      handleError("teacher");
    }
  };

  const handleLoginStudent = async (event: any) => {
    event.preventDefault();

    var data = await StudentLoginAPI(studentName, studentLoginPassword);
    if (data !== null) {
      //LOGIN SUCCESSFULL

      props.handleLogin();
    } else {
      handleError("student");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event: any) => {
  //     event.preventDefault();
  // };

  const passwordHandler = (event: any) => {
    setUserPassword(event.target.value);
  };

  const newPasswordHandler = (event: any) => {
    setErrorTeacherPasswordRegister(false);
    setNewUserPassword(event.target.value);
  };

  const newCheckPasswordHandler = (event: any) => {
    setErrorTeacherPasswordRegister(false);
    setCheckNewUserPassword(event.target.value);
  };
  const newSchoolPinHandler = (event: any) => {
    setErrorSchoolPinRegister(false);
    setSchoolPinInput(event.target.value);
  };
  const studentLoginPasswordHandler = (char: number) => {
    if (char === 7) {
      setStudentLoginPassword(
        studentLoginPassword.substr(0, studentLoginPassword.length - 1)
      );
      return;
    }
    if (studentLoginPassword.length < 6) {
      setStudentLoginPassword(studentLoginPassword + String(char));
      return;
    }
    //setStudentLoginPassword('');
  };

  // const pinHandler = (event: any) => {
  //   setUserPin(event.target.value);
  // };

  const emailHandler = (event: any) => {
    setUserEmail(event.target.value);
  };

  const fullnameHandler = (event: any) => {
    setErrorTeacherFullnameRegister(false);
    setNewUserFullname(event.target.value);
  };

  const newEmailHandler = (event: any) => {
    setErrorTeacherUsernameRegister(false);
    setNewUserEmail(event.target.value);
  };

  const newSchoolHandler = (event: any) => {
    setNewUserSchool(event.target.value);
  };

  const verificationCodeHandler = (value: any) => {
    setVerificationCode1(value);
  };

  const nameStudentHandler = (event: any) => {
    setStudentName(event.target.value);
  };

  const registerStudentHandler = async () => {
    const username = (
      document.getElementById("username-register") as HTMLInputElement
    ).value;
    const pin = classPinInput;
    const captcha = recaptchaRef.current?.getValue();
    var profane = filter.isProfane(
      username
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );

    if (username.length <= 40 && !profane) {
      const registeredUser = await StudentRegisterAPI(
        username,
        pin,
        captcha || ""
      );

      if (registeredUser === 409) {
        setErrorStudentUsernameRegister(true);
        return;
      }
      if (registeredUser === 404) {
        setErrorStudentPinRegister(true);
        return;
      }

      if (registeredUser !== null) {
        //LOGIN SUCCESSFULL
        setStudentPassword(registeredUser.password);
      } else {
        handleError("studentregister");
      }
    } else {
      handleError("studentregister");
    }
  };

  const validateEmail = (email: string): boolean => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const verifyTeacherHandler = async () => {
    const code = verificationCode;
    const username = newUserEmail || userEmail;
    const password = newUserPassword || userPassword;

    if (code.length === 0 || isNaN(parseInt(code))) {
      setErrorTeacherRegistrationCode(true);
      return;
    }

    await TeacherVerificationAPI(username, code).then(async (_) => {
      const data = (await TeacherLoginAPI(username, password)) as UserType;
      if (data !== null) {
        dispatch(updateUser(data));
      } else {
        handleError("teacher");
      }
    });
  };

  // TO DO: change function
  const registerTeacherHandler = async () => {
    const email = newUserEmail;
    const fullname = newUserFullname;
    const password = newUserPassword;
    const school = newUserSchool;
    const checkPassword = checkNewUserPassword;
    const pin = schoolPinInput;
    const captcha = recaptchaRef.current?.getValue() || "";
    var profane = filter.isProfane(
      email
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );

    if (fullname.length === 0) {
      setErrorTeacherFullnameRegister(true);
      return;
    }
    if (email.length === 0 || !validateEmail(email)) {
      setErrorTeacherUsernameRegister(true);
      return;
    }
    if (password.length === 0 || password !== checkPassword) {
      setErrorTeacherPasswordRegister(true);
      return;
    }
    if (checkPassword.length === 0) {
      setErrorTeacherPasswordRegister(true);
      return;
    }
    if (school.length === 0) {
      setIsErraneousTeacherSchoolRegister(true);
      return;
    }
    if (pinExists && pin.length !== 6) {
      setErrorSchoolPinRegister(true);
      return;
    }

    if (password === checkNewUserPassword && !profane) {
      const registeredUser = await TeacherRegisterAPI(
        fullname,
        email,
        password,
        school,
        captcha,
        pinExists ? pin : undefined
      );

      if (
        registeredUser !== null &&
        registeredUser.verification_code === "not_verified"
      ) {
        handlePage("teacherregistrationcode");
      } else {
        handleError("userteacherregister");
      }
    } else {
      handleError("userteacherregister");
    }
  };

  const changePasswordHandler = async () => {
    const username = newUserEmail || userEmail;
    const password = newUserPassword;
    const checkPassword = checkNewUserPassword;
    const code = verificationCode;

    if (
      password.length === 0 ||
      checkPassword.length === 0 ||
      password !== checkPassword
    ) {
      setErrorTeacherPasswordRegister(true);
      return;
    }

    if (password === checkNewUserPassword) {
      await TeacherResetPasswordAPI(username, password, code).then(
        async (_) => {
          const data = (await TeacherLoginAPI(username, password)) as UserType;
          if (data !== null) {
            props.handleLogin();
          } else {
            handleError("teacher");
          }
        }
      );
    } else {
      handleError("userteacherregister");
    }
  };

  const passwordPlotter = (pass: string) => {
    var passRender = [];
    var passCode = pass.split("");

    for (let i = 0; i < passCode.length; i++) {
      passRender.push(
        <img
          key={i}
          src={passSymbols[parseInt(passCode[i])]}
          alt={passSymbols[parseInt(passCode[i])]}
          style={{
            backgroundColor: passColors[parseInt(passCode[i])],
            height: "50px",
            width: "50px",
            margin: "1%",
            padding: "1%",
            borderRadius: "10px",
          }}
        />
      );
    }

    return passRender;
  };

  const studentLoginPasswordInputRenderer = () => {
    var passRender = [];
    var passCode = "012345".split("");

    for (let i = 0; i < 6; i++) {
      passRender.push(
        <motion.img
          key={i}
          id="student-password-element1"
          className="student-password-element"
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            backgroundColor: passColors[parseInt(passCode[i])],
          }}
          src={passSymbols[parseInt(passCode[i])]}
          onClick={() => studentLoginPasswordHandler(i)}
          whileHover={{
            scale: 1.1,
            boxShadow: "0px 0px 10px 1px " + passColors[parseInt(passCode[i])],
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        />
      );
    }
    return passRender;
  };

  const studentLoginPasswordDisplayRenderer = (pass: string) => {
    var passRender = [];
    var passCode = pass.split("");
    for (let i = 0; i < 6; i++) {
      if (passCode[i] == null) {
        passCode[i] = "6";
      }

      passRender.push(
        <img
          key={i}
          id="student-password-element1"
          className="student-password-element"
          alt={passSymbols[parseInt(passCode[i])]}
          style={{ backgroundColor: passColors[parseInt(passCode[i])] }}
          src={passSymbols[parseInt(passCode[i])]}
        />
      );
    }

    passRender.push(
      <motion.img
        key={7}
        id="student-password-element7"
        className="student-password-element"
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          backgroundColor: passColors[7],
        }}
        src={passSymbols[7]}
        onClick={() => studentLoginPasswordHandler(7)}
        whileHover={{
          scale: 1.1,
          boxShadow: "0px 0px 10px 1px " + passColors[7],
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      />
    );
    return passRender;
  };

  const renderPage = () => {
    if (studentPassword !== null) {
      return (
        <div style={{ boxSizing: "border-box", padding: "3%" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {passwordPlotter(studentPassword)}
          </div>
          <div className="password_container">
            <div className="text_black_small_bold">
              {t("save_password_prompt")}
            </div>
          </div>
          <Center style={{ textAlign: "center", marginTop: "2em" }}>
            <Button
              variant="solid"
              size="lg"
              colorScheme="blue"
              onClick={() => window.location.reload()}
              style={{
                width: "30%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              as={motion.div}
              whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #5090F0" }}
              transition="0.2s ease"
            >
              <span style={{ fontFamily: "Causten" }}>{t("ok")}</span>
            </Button>
          </Center>
        </div>
      );
    }

    if (TeacherLogin) {
      return (
        <div
          className="teacher_main_container"
          style={{
            height: "fit-content",
            boxSizing: "border-box",
            padding: "2%",
          }}
        >
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("initial")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_teacher")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" h={"100%"}>
              <Input
                isInvalid={IsErraneousTeacher}
                value={userEmail}
                onChange={emailHandler}
                id="email_input"
                placeholder={t("email")}
                size="lg"
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                mb={2}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <InputGroup
                size="lg"
                as={motion.div}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                  borderRadius: "5px",
                }}
                transition="0.1s ease"
              >
                <Input
                  isInvalid={IsErraneousTeacher}
                  value={userPassword}
                  type={showPassword ? "text" : "password"}
                  onChange={passwordHandler}
                  id="password_input"
                  placeholder={t("password")}
                  size="lg"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginTeacher(e);
                    }
                  }}
                  style={{ fontFamily: "Causten" }}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClickShowPassword}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Spacer />
              <Button
                colorScheme="blue"
                size="lg"
                style={{
                  width: "100%",
                  marginTop: "2vh",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={handleLoginTeacher}
                as={motion.div}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>{t("enter")}</span>
              </Button>
              <Spacer />
              <Button
                colorScheme="blue"
                variant="link"
                size="lg"
                style={{
                  width: "100%",
                  marginTop: "2%",
                  marginBottom: "2%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => handlePage("requestpassword")}
              >
                <span style={{ fontFamily: "Causten" }}>
                  {t("did_you_forget_your_password")}
                </span>
              </Button>
              <Spacer />
              <Divider
                borderColor="rgba(188, 209, 239, 1)"
                borderBottomWidth="2px"
                opacity={1}
              />
              <Spacer />
              <Center>
                <Button
                  variant="solid"
                  size="lg"
                  colorScheme="whatsapp"
                  style={{
                    width: "50%",
                    marginTop: "2%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    // window.open("https://forms.fillout.com/t/wn8cDriiC8us");
                    handlePage("teacherregistration");
                  }}
                >
                  <span style={{ fontFamily: "Causten" }}>
                    {t("create_new_account")}
                  </span>
                </Button>
              </Center>
            </Flex>
          </div>
        </div>
      );
    } else if (TeacherRequestPassword) {
      return (
        <div
          className="teacher_main_container"
          style={{
            height: "fit-content",
            boxSizing: "border-box",
            padding: "2%",
          }}
        >
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("teacherlogin")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_teacher")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" h={"100%"}>
              <Input
                isInvalid={IsErraneousTeacher}
                value={userEmail}
                onChange={emailHandler}
                id="email_input"
                placeholder={t("email")}
                size="lg"
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                mb={2}
                mt={2}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Center mt={2}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcFxqwiAAAAAM8E4LZLvHSk7bnuQ10Z6X028Y2E" //6LfYAp0dAAAAAJ_Is7ZDS7NXzZfZycgbbNXOTVyW
                />
              </Center>
              <Button
                colorScheme="blue"
                size="lg"
                style={{
                  width: "100%",
                  marginTop: "2vh",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={handleRequestPassword}
                as={motion.div}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>
                  {t("request_new_password")}
                </span>
              </Button>
            </Flex>
          </div>
        </div>
      );
    } else if (StudentLogin) {
      return (
        <div className="teacher_main_container" style={{ height: "50vh" }}>
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("initial")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_student")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" h={"100%"}>
              <Input
                isInvalid={IsErraneousStudent}
                value={studentName}
                id="student_input"
                placeholder={t("username")}
                onChange={nameStudentHandler}
                size="lg"
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Spacer />
              <div className="student-password-container">
                {studentLoginPasswordDisplayRenderer(studentLoginPassword)}
              </div>
              <Spacer />
              <div className="student-password-container">
                {studentLoginPasswordInputRenderer()}
              </div>
              <Spacer />
              <Button
                colorScheme="blue"
                size="lg"
                style={{
                  width: "100%",
                  marginTop: "2%",
                  marginBottom: "2%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={handleLoginStudent}
                as={motion.div}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>{t("enter")}</span>
              </Button>
              <Spacer />
              <Divider
                borderColor="rgba(188, 209, 239, 1)"
                borderBottomWidth="2px"
                opacity={1}
              />
              <Spacer />
              <Center>
                <Button
                  variant="solid"
                  size="lg"
                  colorScheme="whatsapp"
                  onClick={() => handlePage("studentregistration")}
                  style={{
                    width: "50%",
                    marginTop: "2%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  as={motion.div}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px #22C35E",
                  }}
                  transition="0.2s ease"
                >
                  <span style={{ fontFamily: "Causten" }}>
                    {t("create_new_account")}
                  </span>
                </Button>
              </Center>
            </Flex>
          </div>
        </div>
      );
    } else if (StudentRegistration) {
      return (
        <div className="student_register_main_container">
          <CloseIcon
            style={{
              right: "2vh",
              top: "2vh",
              position: "absolute",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => handlePage("studentlogin")}
          />
          <Flex direction="column" h={"100%"}>
            <Heading size="3xl">
              <div className="text_black_big_bold">{t("register")}</div>
            </Heading>
            <Spacer />
            <Text
              fontSize="2xl"
              color="rgba(188, 209, 239, 1)"
              style={{ fontFamily: "Causten", margin: 0 }}
            >
              {t("its_quick_and_very_easy")}
            </Text>
            <Spacer />
            <Divider
              borderColor="rgba(188, 209, 239, 1)"
              borderBottomWidth="2px"
              opacity={1}
            />
            <Spacer />
            <Input
              marginTop={2}
              size="lg"
              isInvalid={IsErraneousStudentUsernameRegister}
              id="username-register"
              placeholder={t("username")}
              style={{ fontFamily: "Causten", marginTop: "2%" }}
              as={motion.input}
              whileHover={{
                scale: 1.01,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition="0.2s ease"
              onChange={(e) =>
                console.log(
                  filter.isProfane(
                    e.target.value
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  )
                )
              }
            />
            <Spacer />
            <Text style={{ fontFamily: "Causten", margin: "1vh" }}>
              {t("write_down_this_name")}
            </Text>

            <Input
              marginBottom={"2"}
              size="lg"
              isInvalid={IsErraneousStudentPinRegister}
              id="pin-register"
              placeholder={t("PIN de la clase")}
              style={{ fontFamily: "Causten", marginTop: "2%" }}
              as={motion.input}
              whileHover={{
                scale: 1.01,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition="0.2s ease"
              value={classPinInput}
              onChange={(e) => {
                const reg = new RegExp("^\\d{0,6}$");
                if (reg.test(e.target.value)) {
                  setClassPinInput(e.target.value);
                }
              }}
            />
            <Spacer />

            <div className="center_container">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcFxqwiAAAAAM8E4LZLvHSk7bnuQ10Z6X028Y2E" //6LfYAp0dAAAAAJ_Is7ZDS7NXzZfZycgbbNXOTVyW
              />
            </div>
            <Spacer />
            <Center style={{ marginTop: "2vh" }}>
              <Button
                variant="solid"
                size="lg"
                colorScheme="whatsapp"
                onClick={() => registerStudentHandler()}
                style={{
                  width: "50%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                as={motion.div}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 10px 1px #22C35E",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>{t("register")}</span>
              </Button>
            </Center>
          </Flex>
        </div>
      );
    } else if (TeacherRegistration) {
      return (
        <div
          className="teacher_main_container"
          style={{
            height: "fit-content",
            boxSizing: "border-box",
            padding: "2%",
          }}
        >
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("teacherLogin")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_teacher")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" height={"100%"}>
              <Input
                isInvalid={IsErraneousTeacherFullnameRegister}
                value={newUserFullname}
                onChange={fullnameHandler}
                id="fullname_input"
                placeholder={t("full_name")}
                size="lg"
                style={{ fontFamily: "Causten" }}
                mb={2}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Input
                isInvalid={IsErraneousTeacherUsernameRegister}
                // isInvalid={pinExists}
                value={newUserEmail}
                onChange={newEmailHandler}
                id="new_email_input"
                placeholder={t("email")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Input
                isInvalid={IsErraneousTeacherPasswordRegister}
                value={newUserPassword}
                type="password"
                onChange={newPasswordHandler}
                id="new_password_input"
                placeholder={t("password")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Input
                isInvalid={IsErraneousTeacherPasswordRegister}
                value={checkNewUserPassword}
                type="password"
                onChange={newCheckPasswordHandler}
                id="new_password_input"
                placeholder={t("confirm_password")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Input
                isInvalid={IsErraneousTeacherSchoolRegister}
                value={newUserSchool}
                onChange={newSchoolHandler}
                id="new_school_input"
                placeholder={t("school")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Checkbox
                size="lg"
                colorScheme="blue"
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  fontFamily: "Causten",
                  color: "#5090F0",
                  fontWeight: "bold",
                }}
                mb={2}
                isChecked={pinExists}
                onChange={() => setPinExists(!pinExists)} // Provide an onChange handler to toggle the state
              >
                {t("checkbox_teacher_register")}
              </Checkbox>
              {pinExists && (
                <Input
                  value={schoolPinInput}
                  id="school_pin_input"
                  placeholder={t("school_pin")}
                  onChange={newSchoolPinHandler}
                  isInvalid={IsErraneousSchoolPinRegister}
                  size="lg"
                  style={{ fontFamily: "Causten" }}
                  as={motion.input}
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "0px 0px 10px 1px #5090F0",
                  }}
                  transition="0.1s ease"
                />
              )}
              <Center mt={2}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcFxqwiAAAAAM8E4LZLvHSk7bnuQ10Z6X028Y2E" //6LfYAp0dAAAAAJ_Is7ZDS7NXzZfZycgbbNXOTVyW
                />
              </Center>
              <Center>
                <Button
                  variant="solid"
                  size="lg"
                  colorScheme="whatsapp"
                  style={{
                    width: "50%",
                    marginTop: "2%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    registerTeacherHandler();
                  }}
                >
                  <span style={{ fontFamily: "Causten" }}>
                    {t("create_new_account")}
                  </span>
                </Button>
              </Center>
            </Flex>
          </div>
        </div>
      );
    } else if (TeacherRegistrationCode) {
      return (
        <div
          className="teacher_main_container"
          style={{
            height: "fit-content",
            boxSizing: "border-box",
            padding: "2%",
          }}
        >
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("teacherLogin")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_teacher")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" height={"100%"}>
              <div
                style={{
                  display: "flex",
                  marginTop: "1vh",
                  justifyContent: "center",
                  flexDirection: "column",
                  boxShadow: "0px 0px 4px 0px rgb(80, 144, 240,0.5)",
                  borderRadius: "10px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Causten",
                    margin: "1vh",
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    color: "#5090F0",
                  }}
                >
                  <div className="center_container">
                    {t("verification_code")}
                  </div>
                </Text>
                <Center>
                  <HStack mb={2} mt={2}>
                    <PinInput
                      isInvalid={IsErraneousTeacherRegistrationCode}
                      value={verificationCode}
                      onChange={verificationCodeHandler}
                      id="verification_code_input"
                      manageFocus={true}
                    >
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <span style={{ fontFamily: "Causten", margin: "1vh" }}>
                        -
                      </span>
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                    </PinInput>
                  </HStack>
                </Center>
              </div>
              <Text
                style={{
                  fontFamily: "Causten",
                  margin: "1vh",
                }}
              >
                <div className="center_container">
                  {t("spam_warning_message")}
                </div>
              </Text>
              <Center>
                <Button
                  variant="solid"
                  size="lg"
                  colorScheme="whatsapp"
                  style={{
                    width: "50%",
                    marginTop: "2%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    verifyTeacherHandler();
                  }}
                >
                  <span style={{ fontFamily: "Causten" }}>
                    {t("verify_account")}
                  </span>
                </Button>
              </Center>
            </Flex>
          </div>
        </div>
      );
    } else if (TeacherInputPassword) {
      return (
        <div
          className="teacher_main_container"
          style={{
            height: "fit-content",
            boxSizing: "border-box",
            padding: "2%",
          }}
        >
          <motion.div
            className="title_teacher"
            onClick={() => handlePage("teacherLogin")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div className="text_black_small_bold">{t("i_am_a_teacher")}</div>
          </motion.div>
          <div className="form_box_teacher">
            <Flex direction="column" height={"100%"}>
              <div
                style={{
                  display: "flex",
                  marginTop: "1vh",
                  justifyContent: "center",
                  flexDirection: "column",
                  boxShadow: "0px 0px 4px 0px rgb(80, 144, 240,0.5)",
                  borderRadius: "10px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Causten",
                    margin: "1vh",
                    fontWeight: "bold",
                    fontSize: "1.2em",
                    color: "#5090F0",
                  }}
                >
                  <div className="center_container">
                    {t("verification_code")}
                  </div>
                </Text>
                <Center>
                  <HStack mb={2} mt={2}>
                    <PinInput
                      isInvalid={IsErraneousTeacherRegistrationCode}
                      value={verificationCode}
                      onChange={verificationCodeHandler}
                      id="verification_code_input"
                      manageFocus={true}
                    >
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <span style={{ fontFamily: "Causten", margin: "1vh" }}>
                        -
                      </span>
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                      <PinInputField style={{ fontFamily: "Causten" }} />
                    </PinInput>
                  </HStack>
                </Center>
              </div>
              <Text
                style={{
                  fontFamily: "Causten",
                  margin: "1vh",
                }}
              >
                <div className="center_container">
                  {t("spam_warning_message")}
                </div>
              </Text>
              <Input
                isInvalid={IsErraneousTeacherPasswordRegister}
                value={newUserPassword}
                type="password"
                onChange={newPasswordHandler}
                id="new_password_input"
                placeholder={t("password")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Input
                isInvalid={IsErraneousTeacherPasswordRegister}
                value={checkNewUserPassword}
                type="password"
                onChange={newCheckPasswordHandler}
                id="new_password_input"
                placeholder={t("confirm_password")}
                size="lg"
                mb={2}
                style={{ fontFamily: "Causten" }}
                as={motion.input}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.1s ease"
              />
              <Center>
                <Button
                  variant="solid"
                  size="lg"
                  colorScheme="whatsapp"
                  style={{
                    width: "50%",
                    marginTop: "2%",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => {
                    changePasswordHandler();
                  }}
                >
                  <span style={{ fontFamily: "Causten" }}>
                    {t("verify_account")}
                  </span>
                </Button>
              </Center>
            </Flex>
          </div>
        </div>
      );
    } else {
      return (
        <div className="initial_container">
          <Flex direction="column" h={"90%"} w={"90%"}>
            <Center>
              <Button
                variant="solid"
                size="lg"
                colorScheme="blue"
                style={{
                  width: "50%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => handlePage("teacherlogin")}
                as={motion.div}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>
                  {t("i_am_a_teacher")}
                </span>
              </Button>
            </Center>
            <div style={{ height: "2vh" }} />
            <Divider
              borderColor="rgba(188, 209, 239, 1)"
              borderBottomWidth="2px"
              opacity={1}
            />
            <div style={{ height: "2vh" }} />
            <Center>
              <Button
                variant="solid"
                size="lg"
                colorScheme="blue"
                style={{
                  width: "50%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => handlePage("studentlogin")}
                as={motion.div}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition="0.2s ease"
              >
                <span style={{ fontFamily: "Causten" }}>
                  {t("i_am_a_student")}
                </span>
              </Button>
            </Center>
          </Flex>
        </div>
      );
    }
  };
  return (
    <div className="main_container">
      <div className="main_area_container">
        <div className="landing_page_logo_container">
          <img
            alt=""
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/EutopiaLogoText.svg"
            }
            className="landing_page_image"
          ></img>
          <div className="information_detail"> {t("create_a_place")} </div>
        </div>

        <div className="center_container">
          <div className="option_box">{renderPage()}</div>
        </div>
      </div>
      <div className="footer_container">
        <Stack direction={"row"} spacing={2} justify={"center"}>
          <Button
            onClick={() => changeLanguage("en")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            as={motion.div}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition="0.1s ease"
          >
            <span style={{ fontFamily: "Causten" }}>{t("english")}</span>
          </Button>
          <Button
            onClick={() => changeLanguage("es")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            as={motion.div}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition="0.1s ease"
          >
            <span style={{ fontFamily: "Causten" }}>{t("spanish")}</span>
          </Button>
          <Button
            onClick={() => changeLanguage("val")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            as={motion.div}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition="0.1s ease"
          >
            <span style={{ fontFamily: "Causten" }}>{t("valencian")}</span>
          </Button>
          <Button
            onClick={() => changeLanguage("cat")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            as={motion.div}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition="0.1s ease"
          >
            <span style={{ fontFamily: "Causten" }}>{t("catalonian")}</span>
          </Button>
          <Button
            onClick={() => changeLanguage("de")}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            as={motion.div}
            whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
            transition="0.1s ease"
          >
            <span style={{ fontFamily: "Causten" }}>{t("deutsch")}</span>
          </Button>
        </Stack>
      </div>
      <div className="try_eutopia_container">
        <motion.div
          className="information_detail"
          onClick={() => handlePage("teacherregistration")}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          whileHover={{ scale: 1.1, textShadow: "0px 0px 10px #5090F0" }}
          transition={{ type: "spring", stiffness: 300, damping: 12 }}
        >
          {t("create_teacher_account_prompt")}
        </motion.div>
      </div>
    </div>
  );
};

export default LandingPage;
