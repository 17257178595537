import "./ProfessorEvaluationGlobalSubcompetences.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks.ts";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { m, LazyMotion } from "framer-motion";
import _ from "lodash";
import BackArrow from "../../../../assets/exercises/back_arrow.tsx";
import RadarChart from "./RadarChart.tsx";

import Loading from "../../../loading_screen/LoadingDots.tsx";
import {
  setEvaluationMenu,
  updateSelectedScenario,
} from "../../../../reducers/teacherSlice.ts";
import { simpleGlobalCompetences } from "../../../../constants.ts";
import { StudentType } from "../../../../_newapios/user/student.ts";
import {
  GetGlobalCompetenceScenariosAPI,
  GlobalCompetencePoints,
  GlobalCompetenceScenarioType,
} from "../../../../_newapios/progress/student_global_competences.ts";
import { getGlobalCompetenceAggregates } from "../../../utils/evaluation.ts";
import { CourseType } from "../../../../_newapios/content/course.ts";
import { BundleType } from "../../../../_newapios/user/class.ts";
import { GlobalCompetenceType } from "../../../../_newapios/content/unit.ts";
import { setSelectedCourseId } from "../../../../reducers/mainSlice.ts";

const ProfessorEvaluationGlobalCompetences = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const dispatch = useAppDispatch();

  const student = useAppSelector(
    (state) => state.teacher.selectedStudent
  ) as StudentType;
  const selectedClass = useAppSelector((state) => state.teacher.selectedClass);
  const mainState = useAppSelector((state) => state.main);
  const courses = mainState.courses;
  const trueGlobalCompetences = mainState.globalCompetences;

  const [studentBundles, setStudentBundles] = useState<BundleType[]>([]);
  const [studentCourses, setStudentCourses] = useState<CourseType[]>([]);

  const [loadedItems, setLoadedItems] = useState(true);

  const [achievedSubCompetences, setAchievedSubCompetences] = useState<any[]>(
    []
  );

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const selectedCompetence = useAppSelector(
    (state) => state.teacher.selectedCompetence
  );

  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [schoolCompetences, setSchoolCompetences] =
    useState<GlobalCompetencePoints>({});

  const [studentData, setStudentData] = useState<number[]>([]);
  const [schoolData, setSchoolData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [acronyms, setAcronyms] = useState<string[]>([]);
  const [scenarios, setScenarios] = useState<GlobalCompetenceScenarioType[]>(
    []
  );

  const [globalSubcompetences, setGlobalSubcompetences] = useState<
    GlobalCompetenceType[]
  >([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const loadCompetenceScenarios = async () => {
    // This is just to load some scenarios for the frontend, not the actual API, also use ScenarioType instead of ModifiedScenarioType - edit: nvm I think we need a new type that gets the unit
    if (selectedCompetence === null) {
      return;
    }
    const newScenarios = await GetGlobalCompetenceScenariosAPI(
      student.id,
      selectedCompetence
    );
    setScenarios(newScenarios);
  };
  const findStudentCourses = () => {
    if (studentBundles?.length > 0) {
      var newStudentCourses: CourseType[] = [];
      for (let i = 0; i < studentBundles.length; i++) {
        var newStudentCourse = courses.find(
          (course) => course.id === studentBundles[i].unit?.course_id
        );
        if (
          newStudentCourse !== undefined &&
          newStudentCourses.indexOf(newStudentCourse) === -1
        ) {
          newStudentCourses.push(newStudentCourse);
        }
      }
      setStudentCourses(newStudentCourses);
    }
  };

  useEffect(() => {
    findStudentCourses();
    loadCompetenceScenarios();
  }, [studentBundles]);

  useEffect(() => {
    setGlobalCompetences(props.globalCompetences);
    setSchoolCompetences(props.schoolCompetences);
    setStudentBundles(props.studentBundles);
  }, [
    student,
    props.globalCompetences,
    props.schoolCompetences,
    props.studentBundles,
  ]);

  useEffect(() => {
    calcLocalCompetences();
  }, [globalCompetences, schoolCompetences]);

  const calcLocalCompetences = () => {
    const globalCompetenceAggregates =
      getGlobalCompetenceAggregates(globalCompetences);
    const newLabels = !_.isEmpty(globalCompetences)
      ? Object.keys(globalCompetenceAggregates).map((str) =>
        t(str + "_label", { ns: "evaluation" })
      )
      : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_label", { ns: "evaluation" })
      );
    const newAcronyms = !_.isEmpty(globalCompetences)
      ? Object.keys(globalCompetenceAggregates).map((str) => str)
      : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_name", { ns: "evaluation" })
      );
    const newGlobalSubcompetences = trueGlobalCompetences.filter(
      (gc) => gc.acronym === selectedCompetence
    );
    const newStudentData = !_.isEmpty(globalCompetences)
      ? Object.values(globalCompetenceAggregates)
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    const newSchoolData = !_.isEmpty(schoolCompetences)
      ? Object.values(getGlobalCompetenceAggregates(schoolCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    setLabels(newLabels);
    setAcronyms(newAcronyms);
    setGlobalSubcompetences(newGlobalSubcompetences);
    setStudentData(newStudentData);
    setSchoolData(newSchoolData);
  };

  console.log(trueGlobalCompetences)

  useEffect(() => {
    const newAchievedSubCompetences = calcAchievedSubCompetences(
      globalCompetences,
      globalSubcompetences
    );
    setAchievedSubCompetences(newAchievedSubCompetences);
  }, [globalCompetences, globalSubcompetences]);

  const calcAchievedSubCompetences = (
    globalCompetences: GlobalCompetencePoints,
    globalSubcompetences: GlobalCompetenceType[]
  ) => {
    const achievedSubCompetences: string[] = [];
    for (let i = 0; i < globalSubcompetences.length; i++) {
      const subcompetence = globalSubcompetences[i];
      const subcompetenceAggregate =
        globalCompetences[subcompetence.acronym + subcompetence.number];
      if (subcompetenceAggregate >= 5000) {
        // TODO - Check if this number is enough
        achievedSubCompetences.push(subcompetence.id);
      }
    }
    return achievedSubCompetences;
  };

  if (selectedCompetence === null) {
    return null;
  }

  const competenceIndex = acronyms.indexOf(selectedCompetence);

  const backHandler = () => {
    dispatch(setEvaluationMenu("generalInfo"));
  };

  const backHandlerCompetence = () => {
    dispatch(setEvaluationMenu("globalCompetences"));
  };

  const scenarioHandler = (scenario: GlobalCompetenceScenarioType) => {
    dispatch(setEvaluationMenu("scenarioInfo"));
    dispatch(setSelectedCourseId(scenario.unit.course_id));
    dispatch(updateSelectedScenario(scenario.id));
  };

  const renderLeft = () => {
    return (
      <div className="professor_evaluation_specific_competences_left">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_evaluation_specific_competences_title"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => {
              backHandler();
            }}
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div
              className="text_black_small_bold"
              style={{ textDecoration: "underline" }}
            >
              {t("global_competences")}
            </div>
          </m.div>
        </LazyMotion>
        <div className="professor_evaluation_specific_competences_left_octagon">
          <RadarChart
            max={Math.max(12500, ...studentData, ...schoolData)}
            full={false}
            labels={labels}
            studentData={studentData}
            schoolData={schoolData}
          />
        </div>
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div className="professor_evaluation_specific_competences_right">
        {renderRightTitle()}
        {renderRightContent()}
      </div>
    );
  };

  const renderRightTitle = () => {
    const baseline = Math.max(
      12500,
      studentData[competenceIndex],
      schoolData[competenceIndex]
    );
    return (
      <div className="professor_evaluation_specific_competences_right_title">
        <LazyMotion features={loadFeatures}>
          <m.div
            className="professor_evaluation_specific_competences_right_title_back"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => {
              backHandlerCompetence();
            }}
            whileHover={{
              scale: 1.1,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          >
            <BackArrow color={"black"} size={"35"} />
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_black_small_bold"
                  : "text_black_very_small_bold"
              }
            >
              {t(selectedCompetence + "_name", { ns: "evaluation" })}
            </div>
          </m.div>
        </LazyMotion>
        <div className="professor_evaluation_specific_competences_right_element_line_container">
          <div className="professor_evaluation_specific_competences_right_element_line" />
          <div
            className="professor_evaluation_specific_competences_right_element_student"
            style={{
              width: (studentData[competenceIndex] * 100) / baseline + "%",
            }}
          />
          <div
            className="professor_evaluation_specific_competences_right_element_school"
            style={{
              left: (schoolData[competenceIndex] * 100) / baseline + "%",
            }}
          />
        </div>
      </div>
    );
  };

  const renderRightContent = () => {
    return (
      <div className="professor_evaluation_specific_competences_right_content_body">
        <div className="professor_evaluation_specific_competences_right_content_subcompetences">
          <div className="professor_evaluation_specific_competences_right_content_subcompetence_list">
            <div className="left_container">
              <div className="text_black_very_small_bold">{t("strengths")}</div>
            </div>
            <div className="professor_evaluation_specific_competences_right_content_list">
              {globalSubcompetences
                .filter((comp) => achievedSubCompetences.includes(comp.id))
                .map((competence, competenceIndex) => {
                  return (
                    <div
                      key={competence.id}
                      className="professor_evaluation_specific_competences_right_content_list_element"
                    >
                      <div className="professor_evaluation_specific_competences_right_content_list_element_green" />
                      <div
                        className={
                          screenSize.dynamicWidth > 991
                            ? "text_black_very_small"
                            : "text_black_mega_small"
                        }
                      >
                        {t(competence.acronym + competence.number + "_desc", {
                          ns: "evaluation",
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="professor_evaluation_specific_competences_right_content_subcompetence_list">
            <div className="left_container">
              <div className="text_black_very_small_bold">
                {t("weaknesses")}
              </div>
            </div>
            <div className="professor_evaluation_specific_competences_right_content_list">
              {globalSubcompetences
                .filter((comp) => !achievedSubCompetences.includes(comp.id))
                .map((competence, competenceIndex) => {
                  return (
                    <div
                      key={competence.id}
                      className="professor_evaluation_specific_competences_right_content_list_element"
                    >
                      <div className="professor_evaluation_specific_competences_right_content_list_element_red" />
                      <div
                        className={
                          screenSize.dynamicWidth > 991
                            ? "text_black_very_small"
                            : "text_black_mega_small"
                        }
                      >
                        {t(competence.acronym + competence.number + "_desc", {
                          ns: "evaluation",
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {renderScenarios()}
      </div>
    );
  };

  const renderScenarios = () => {
    return (
      <div className="professor_evaluation_specific_competences_right_content">
        <div className="professor_evaluation_general_info_title">
          <div className="left_container">
            <div className="text_black_very_small_bold">
              {t("learning_situations_worked")}
            </div>
          </div>
        </div>
        <div className="professor_evaluation_specific_competences_right_content_list_2">
          {scenarios.map((scenario, scenarioIndex) => {
            const unitSubject = studentCourses.find(
              (course) => course.id === scenario.unit.course_id
            );
            return (
              <LazyMotion features={loadFeatures}>
                <m.div
                  className="professor_evaluation_specific_competences_right_content_scenario"
                  whileHover={{
                    boxShadow: "0px 0px 10px 1px #5090F050",
                    borderRadius: "10px",
                  }}
                  transition={{
                    type: "tween",
                    ease: "easeInOut",
                    duration: 0.2,
                  }}
                  key={scenario.id}
                  onClick={() => scenarioHandler(scenario)}
                >
                  <img
                    className="image"
                    src={scenario.image.url}
                    alt="scenario_image"
                    style={{ borderRadius: "10px", maxHeight: "71px" }}
                  />
                  <div className="professor_evaluation_specific_competences_right_content_scenario_info">
                    <div className="professor_evaluation_curricular_content_left_scenario_info_top">
                      <div className="text_black_really_small">
                        <b>{scenario.name}</b> {" | " + scenario.know_hows[0]}
                      </div>
                    </div>
                    <div className="professor_evaluation_specific_competences_right_content_scenario_info_bottom">
                      <div className="professor_evaluation_specific_competences_right_content_scenario_info_bottom_left">
                        <div className="center_container">
                          <div className="text_black_really_small">
                            {scenario.unit.name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="professor_evaluation_specific_competences_right_content_scenario_info_bottom_course"
                        style={{ background: unitSubject?.color }}
                      >
                        <div className="center_container">
                          <div className="text_white_super_small">
                            {t(unitSubject?.acronym + "_short", {
                              ns: "evaluation",
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </m.div>
              </LazyMotion>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div style={!loadedItems ? { display: "flex" } : {}}>
      {loadedItems ? (
        <div className="professor_evaluation_specific_competences">
          {renderLeft()}
          {renderRight()}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProfessorEvaluationGlobalCompetences;
