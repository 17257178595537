import env from "../../config/env.json";
import { CourseType } from "../content/course";

export interface LicenseType {
    id: string;
    name: string;
    class_number: number;
    access_premium: boolean;
    allow_private: boolean;
    course_evaluate_number: number;
    teacher_number: number;
    allow_global_competences: boolean
}
export interface userLicenseType {
    id: string,
    start_date: string,
    active: boolean,
    paid: boolean,
    school_id: string,
    licensetype_id: string,
    license: LicenseType
    courses_allowed: CourseType[]
}

export const GetMyLicenseAPI = async (): Promise<userLicenseType> => {
    var req = await fetch(env.backend_host + "/me/license/", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    var data = await req.json();
    return data;
}