import "./Challenges.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import closeButton from "../../assets/student/close_button.svg";
import challenge1Image from "../../assets/student/Challenge_1_image.svg";
import challengeLockedImage from "../../assets/student/Challenge_locked_image.svg";
import lockGreen from "../../assets/student/lock-alt-open-green.svg";
import lockRed from "../../assets/student/lock-red.svg";
import starGreen from "../../assets/student/star-green.svg";
import starRed from "../../assets/student/star-red.svg";
import { IscreenSize } from "../../interfaces";
import { menu, setChallenge } from "../../reducers/studentSlice";
import { motion } from "framer-motion";
import {
  ChallengeType,
  GetAllChallengesAPI,
} from "../../_newapios/gamecontent/challenges";
import { GetStudentHouseAllAPI } from "../../_newapios/progress/student_house";
import Alert from "../alerts/Alert";

const Challenges = ({ ...props }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);

  const [challenges, setChallenges] = useState<ChallengeType[]>([]);
  const [houses, setHouses] = useState<number>(0);
  const [alert, setAlert] = useState<string | null>(null);

  const [challenge1Accesible, setChallenge1Accesible] =
    useState<boolean>(false);
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    loadChallenges();
    loadStudentHouses();
  }, []);

  const handleAlert = () => {
    setAlert(null);
  };

  const loadChallenges = async () => {
    const temp_challenges = await GetAllChallengesAPI();
    console.log(temp_challenges);
    setChallenges(temp_challenges);
  };

  const loadStudentHouses = async () => {
    const temp_houses = await GetStudentHouseAllAPI("me");
    setHouses(temp_houses.length);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const calculateEmptyChallenges = () => {
    const challengeEmptyLength = 3 - challenges.length;
    const challengeEmpty: number[] = [];
    for (let i = 0; i < challengeEmptyLength; i++) {
      challengeEmpty.push(1);
    }
    return challengeEmpty;
  };

  const isChallengeCompleted = (challenge: ChallengeType) => {
    if (challenge.student_challenge === undefined) return false;
    return challenge.student_challenge[0]?.done;
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const closeButtonHandler = () => {
    dispatch(menu("globalMap"));
  };

  const challengeButtonHandler = (challenge: ChallengeType) => {
    if (houses >= 6) {
      dispatch(menu("globalMap"));
      dispatch(setChallenge(challenge));
    } else {
      setAlert("challenge_1_criteria_not_met");
    }
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="challenges_close_button"
        onClick={() => closeButtonHandler()}
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="challenges_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">
            {t("research_convention")}
          </div>
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    const challengeEmpty = calculateEmptyChallenges();

    return (
      <div className="challenges_menu">
        {challenges.map((challenge, index) => {
          return renderChallenge(challenge);
        })}
        {challengeEmpty.map((challenge, index) => {
          return renderChallengeLocked(index);
        })}
      </div>
    );
  };

  const renderChallenge = (challenge: ChallengeType) => {
    return (
      <div className="center_container" key={challenge.id}>
        <div className="challenges_menu_box">
          <div style={{ position: "relative", height: "100%" }}>
            <img
              src={challenge.image.url}
              alt="image"
              style={{ height: "80%" }}
            />
          </div>
          <motion.div
            className="challenges_menu_box_challenge_content"
            style={{ fontStyle: "italic" }}
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px " + "#6EE6A7",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={
              isChallengeCompleted(challenge)
                ? () => {
                    setAlert("already_completed");
                  }
                : () => challengeButtonHandler(challenge)
            }
          >
            <div className="challenges_menu_box_challenge_content_top">
              <div className="left_container">
                <div
                  className="text_black_small_bold"
                  style={{ color: "#6EE6A7", fontWeight: "bolder" }}
                >
                  {t(challenge.name)}
                </div>
              </div>
            </div>

            <div className="challenges_menu_box_challenge_content_mid">
              <div className="top_left_container">
                <div
                  className="text_black_very_small"
                  style={{ textAlign: "start" }}
                >
                  {t(challenge.description)}
                </div>
              </div>
            </div>

            <div className="challenges_menu_box_challenge_content_bot">
              <div className="challenges_menu_box_challenge_content_bot_req">
                <div className="top_center_container">
                  <img
                    className="icon"
                    src={lockGreen}
                    alt="lock"
                    style={{
                      maxHeight: "1.5em",
                      maxWidth: "1.5em",
                      marginTop: "0.2em",
                      marginRight: "0.2em",
                    }}
                  />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_really_small"
                    style={{ display: "flex" }}
                  >
                    {t("challenge_1_req_text_1") + ":"}
                    <div
                      style={{
                        color: "#6EE6A7",
                        fontWeight: "bold",
                        marginLeft: "0.5em",
                      }}
                    >
                      {t("challenge_1_req_text_2")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="challenges_menu_box_challenge_content_bot_reward"
                style={{ marginLeft: "2%" }}
              >
                <div className="top_center_container">
                  <img
                    className="icon"
                    src={starGreen}
                    alt="star"
                    style={{
                      maxHeight: "1.5em",
                      maxWidth: "1.5em",
                      marginTop: "0.2em",
                      marginRight: "0.2em",
                    }}
                  />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_really_small"
                    style={{ display: "flex" }}
                  >
                    {t("challenge_1_reward_text_1") + ":"}
                    <div
                      style={{
                        color: "#6EE6A7",
                        fontWeight: "bold",
                        marginLeft: "0.5em",
                      }}
                    >
                      {t("challenge_1_reward_text_2")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  const renderChallengeLocked = (index: number) => {
    return (
      <div className="center_container" key={index}>
        <div className="challenges_menu_box">
          <div style={{ position: "relative", height: "100%" }}>
            <img
              src={challengeLockedImage}
              alt="image"
              style={{ height: "80%" }}
            />
          </div>
          <div
            className="challenges_menu_box_challenge_content_locked"
            style={{ fontStyle: "italic" }}
          >
            <div className="challenges_menu_box_challenge_content_locked_body">
              <div
                className="left_container"
                style={{
                  color: "white",
                  fontSize: "4.5em",
                  fontWeight: "bold",
                }}
              >
                {t("challenge_not_available")}
              </div>
            </div>

            <div className="challenges_menu_box_challenge_content_bot">
              <div className="challenges_menu_box_challenge_content_bot_req">
                <div className="top_center_container">
                  <img
                    className="icon"
                    src={lockRed}
                    alt="lock"
                    style={{
                      maxHeight: "1.5em",
                      maxWidth: "1.5em",
                      marginTop: "0.2em",
                    }}
                  />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_really_small"
                    style={{ display: "flex" }}
                  >
                    {t("challenge_1_req_text_1") + ":"}
                    <div
                      style={{
                        color: "#F0684F",
                        fontWeight: "bold",
                        marginLeft: "0.5em",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="challenges_menu_box_challenge_content_bot_reward">
                <div className="top_center_container">
                  <img
                    className="icon"
                    src={starRed}
                    alt="star"
                    style={{
                      maxHeight: "1.5em",
                      maxWidth: "1.5em",
                      marginTop: "0.2em",
                    }}
                  />
                </div>
                <div className="left_container">
                  <div
                    className="text_black_really_small"
                    style={{ display: "flex" }}
                  >
                    {t("challenge_1_reward_text_1") + ":"}
                    <div
                      style={{
                        color: "#F0684F",
                        fontWeight: "bold",
                        marginLeft: "0.5em",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="challenges">
      <div className="challenges_banner_container">
        <img
          className="challenges_banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_challenges.svg"
          }
          alt="banner"
        />
      </div>
      <div className="challenges_background" />
      {renderCloseButton()}
      {renderTitle()}
      {renderMenu()}
      {alert !== null && (
        <Alert handleAlert={() => handleAlert()} text={t(alert)} />
      )}
    </div>
  );
};

export default Challenges;
