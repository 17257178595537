import env from "../../config/env.json";
import { ExerciseType, ImageType } from "./unit";

// export const getImages = async () => {
//   var imageList = await fetch(env.backend_host + "/image/images", {
//     "method": "GET",
//     "mode": "cors",
//     "credentials": "include"
//   }).then(res => res.json())
//   return imageList
// };

export const putImage = async (file: string, exerciseId: string): Promise<ExerciseType> => {

    var data = new FormData()
    data.append('files', file);
    console.log(data)

    var req = await fetch(env.backend_host + "/image/" + exerciseId, {
        "credentials": "include",
        // headers: {
        //     'Content-Type': 'multipart/mixed',
        //     'Accept': '*/*'
        // },
        "body": data,
        "method": "PUT",
        "mode": "cors",
        
      })

      var json = await req.json()
      

      
    return json
};

export const uploadImage = async (url: string, exerciseId: string): Promise<ExerciseType> => {
    var req = await fetch(env.backend_host + "/image/" + exerciseId, {
        "credentials": "include",
        "body": JSON.stringify({url: url}),
        "method": "POST",
        "mode": "cors",
        headers: {
            'Content-Type': 'application/json'
        },
      })

      var json = await req.json()
      
    return json
};