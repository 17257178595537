import "./ChooseOneEdit.css";
import { useState, useEffect } from "react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import tick from "../../../../assets/professor/ok.svg";
import bin from "../../../../assets/professor/bin.svg";

import ImageSelector from "./ImageSelector/ImageSelector";
import { Input, Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import axios from "axios";
import { useAppSelector } from "../../../../hooks/hooks";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";
const pako = require("pako");

const ChooseOneEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateChooseOne: (newChooseOne: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();
  const animations = useAppSelector((state) => state.main).animations;
  const propSolutions = props.exercise.solutions as number[];

  const [helpOpen, setHelpOpen] = useState(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const image = props.exercise.image?.url;
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [solution, setSolution] = useState(propSolutions[0] as number);
  const [saveToDB, setSaveToDB] = useState(false);
  const animationUrl = props.exercise.animation?.url;
  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.options = options;
      exercise.solutions = solution !== undefined ? [solution] : [];
      props.updateChooseOne(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    const newSolutions = props.exercise.solutions as number[];
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setOptions(props.exercise.options as string[]);
    setSolution(newSolutions[0] as number);
  }, [props.exercise.text, props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const imageHandler = (newExercise: ExerciseType) => {
    props.updateChooseOne(newExercise); // TODO - Bit silly because we just want to set the new exercise but whatever
  };

  const optionHandler = (newOption: string, newOptionIndex: number) => {
    var updatedOptions = [...options];
    // var updatedSolution = solution;
    // Editing option routine
    updatedOptions[newOptionIndex] = newOption;
    // Editing solution routine
    // if (updatedSolution === options[newOptionIndex]) {
    //   updatedSolution = newOption;
    // }

    setOptions(updatedOptions);
    // setSolution(updatedSolution);
  };

  const addOptionHandler = () => {
    var updatedOptions = [...options];
    updatedOptions.push("");
    setOptions(updatedOptions);
  };

  const solutionHandler = (optionIndex: number) => {
    var newSolution = optionIndex;
    setSolution(newSolution);
    setSaveToDB(true);
  };
  const deleteOptionHandler = (optionIndex: number) => {
    var updatedOptions = [...options];

    const deletedOption = updatedOptions.splice(optionIndex, 1);
    setOptions(updatedOptions);
    setSaveToDB(true);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_choose_one_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_choose_one_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_choose_one_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            onClick={() => helpButtonHandler()}
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="edit_choose_one_image">
        <div className="center_container">
          <ImageSelector
            imageHandler={(exercise: ExerciseType) => imageHandler(exercise)}
            animation={animationUrl}
            image={image}
            exerciseId={props.exercise.id}
          />
        </div>
      </div>
    );
  };

  // const renderImage = () => {
  //   {
  //     if (animationUrl !== undefined) {
  //       console.log(image);
  //       return (
  //         <div className="edit_choose_one_image">
  //           <div className="center_container">
  //             <Lottie
  //               animationData={animation}
  //               loop={graphs ? true : false}
  //               autoplay={true}
  //               initialSegment={graphs ? undefined : [60, 60]}
  //             />
  //           </div>
  //         </div>
  //       );
  //     } else if (image !== undefined) {
  //       return (
  //         <div className="edit_choose_one_image">
  //           <div className="center_container">
  //             <img className="image" src={image} alt="image" />
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div className="edit_choose_one_image">
  //           <div className="center_container">
  //             {/* <ImageSelector imageHandler={(image: any) => imageHandler(image)} image={image} /> */}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  // };

  const renderAddButton = (currentOptions: string[]) => {
    if (currentOptions.length < 5) {
      return (
        <motion.div
          className="edit_choose_one_add_option"
          onClick={() => addOptionHandler()}
          layout
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} />
          </div>
        </motion.div>
      );
    }
  };

  const renderOptionsButtons = () => {
    return (
      <motion.div
        className="edit_choose_one_options"
        layoutRoot
        style={{
          gridTemplateColumns:
            options.length < 5
              ? "repeat(" + (options.length + 1) + ", 1fr)"
              : "repeat(" + options.length + ", 1fr)",
        }}
      >
        {options.map((option, optionIndex) => {
          return (
            <motion.div
              layout
              className="edit_choose_one_option_container"
              key={"wrapper" + optionIndex}
            >
              <motion.div
                key={optionIndex}
                className={
                  optionIndex === solution
                    ? "edit_choose_one_option_pressed"
                    : "edit_choose_one_option"
                }
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <div className="center_container">
                  <Input
                    placeholder={t("click_here_to_edit")}
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                      fontFamily: "Causten",
                      fontSize: option.length < 20 ? "1em" : "0.7em",
                      borderRadius: "50px",
                    }}
                    value={option}
                    onChange={(e) => optionHandler(e.target.value, optionIndex)}
                    onBlur={() => setSaveToDB(true)}
                  />
                </div>
              </motion.div>
              <div className="edit_choose_one_option_bottom">
                <div
                  className="edit_choose_one_option_bottom_correct"
                  onClick={() => solutionHandler(optionIndex)}
                >
                  <div className="center_container">
                    <motion.div
                      className={
                        optionIndex === solution
                          ? "edit_choose_one_option_checkbox_marked"
                          : "edit_choose_one_option_checkbox"
                      }
                      whileHover={{
                        scale: 1.1,
                        boxShadow:
                          optionIndex === solution
                            ? "0px 0px 10px 1px #6EE6A7"
                            : "0px 0px 10px 1px #5090F0",
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      style={{
                        cursor:
                          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      }}
                    >
                      <img
                        src={optionIndex === solution ? tick : undefined}
                        style={{ width: "2vw" }}
                      />
                    </motion.div>
                  </div>
                </div>
                <div className="center_container">
                  <motion.div
                    className="edit_choose_one_option_bottom_delete"
                    onClick={() => deleteOptionHandler(optionIndex)}
                    whileHover={{
                      scale: 1.1,
                      boxShadow: "0px 0px 10px 1px #F0684F",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    <div className="center_container">
                      <img src={bin} style={{ height: "2vw" }} />
                    </div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          );
        })}
        {renderAddButton(options)}
      </motion.div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_choose_one_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_choose_one_">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderImage()}
      {renderOptionsButtons()}
    </div>
  );
};

export default ChooseOneEdit;
