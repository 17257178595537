import "./ProfessorSidebar.css";
import "../../index.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Plus from "../../assets/professor/plus.tsx";
import { useEffect, useState } from "react";
import { GetMySchoolAPI } from "../../_newapios/user/school.ts";
import {
  changeStepClassCreate,
  classes,
  setEvaluationMenu,
  menu,
  submenu,
  updateSelectedClass,
} from "../../reducers/teacherSlice";
import { LazyMotion, m } from "framer-motion";
import bin from "../../assets/professor/bin.svg";
import { DeleteClassAPI, GetMyClassesAPI } from "../../_newapios/user/class.ts";
import { ClassType } from "../../_newapios/user/class.ts";
import close from "../../assets/student/close_user_menu.svg";
import _ from "lodash";
import { calculateCreateClass } from "../../utils/licenses.ts";
import { DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import Alert from "../alerts/Alert.tsx";
import VerticalDots from "../../assets/professor/ellypsis-vertical.tsx";

const ProfessorSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const teacherState = useAppSelector((state) => state.teacher);
  const loginState = useAppSelector((state) => state.login);
  const userLicense = teacherState.userLicense;
  const teacherClasses = teacherState.classes;
  const isAdmin = teacherState.isAdmin;
  const selectedClass = teacherState.selectedClass;
  const [deleteMenu, setDeleteMenu] = useState<ClassType | null>(null);
  const [alert, setAlert] = useState<string | null>(null);

  const loadFeatures = () =>
    import("../../hooks/framerMotionHooks.ts").then((res) => res.default);

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = async () => {
    var myClasses = await GetMyClassesAPI();
    if (myClasses === null) {
      myClasses = [];
    }
    dispatch(classes(myClasses));
  };

  const selectClassHandler = async (myclass: ClassType | null) => {
    dispatch(setEvaluationMenu("studentList"));

    if (myclass == null) {
      dispatch(updateSelectedClass(null));
      dispatch(menu("welcome"));
      return;
    }

    dispatch(updateSelectedClass(myclass));
    dispatch(menu("menus"));
    dispatch(submenu("activities"));

    //const classPlots = await getTeacherClassPlots(myclass.id);
    // if (myclass.step === 2) {
    //   dispatch(menu('classCreate'))
    //   dispatch(changeStepClassCreate(2))
    // }
    // if (myclass.step === 1) {
    // }
  };

  const createClassHandler = () => {
    dispatch(menu("classCreate"));
    dispatch(changeStepClassCreate(0));
    dispatch(updateSelectedClass(null));
  };

  const deleteClassHandler = async (class_id: string) => {
    await DeleteClassAPI(class_id);
    getClasses();
    setDeleteMenu(null);
  };

  const deleteMenuHandler = (myclass: ClassType | null = null) => {
    if (!isAdmin) {
      setAlert("only_admin_can_delete");
      return;
    }
    setDeleteMenu(myclass);
  };

  const alertHandler = () => {
    setAlert(null);
  };

  const renderOptionsButton = (myclass: ClassType) => {
    return (
      <div className="center_container">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<VerticalDots />}
            variant="none"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          />
          <Portal>
            <MenuList>
              <MenuItem
                icon={<DeleteIcon />}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => deleteMenuHandler(myclass)}
              >
                {t("delete_class")}
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </div>
    );
  };

  const renderDeleteMenu = (myclass: ClassType) => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_class_text")}
                      <b>{myclass.name}</b>?
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => deleteMenuHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteClassHandler(myclass.id)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteMenuHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderBusinessButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_sidebar_class_container">
          <m.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 10px 2px #5090F0",
            }}
            transition={{
              type: "tween",
              ease: "easeInOut",
              duration: 0.1,
            }}
            className="professor_sidebar_class"
            id="business"
            onClick={() =>
              teacherState.menu === "businessView"
                ? dispatch(menu("welcome"))
                : dispatch(menu("businessView"))
            }
            style={{
              color: "white",
              backgroundColor: "#8DBCFF",
            }}
            layout="size"
          >
            <div className="center_container">Business</div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  return (
    <div className="professor_sidebar_background">
      <div className="professor_sidebar">
        <div
          className="professor_sidebar_top"
          style={
            isAdmin && calculateCreateClass(userLicense, teacherClasses.length)
              ? { gridTemplateRows: "7% 5% 88%" }
              : { gridTemplateRows: "7% 0% 93%" }
          }
        >
          <div className="left_container">
            <div className={t("my_classes").length > 15 ? "text_black_mediumish_bold" : "text_black_medium_bold"}>{t("my_classes")}</div>
          </div>

          {isAdmin &&
          calculateCreateClass(userLicense, teacherClasses.length) ? (
            <div className="professor_sidebar_create_class">
              <div className="center_container">
                <LazyMotion features={loadFeatures}>
                  <m.div
                    whileHover={{
                      scale: 1.2,
                      textShadow: "#5090F0 1px 0 10px",
                    }}
                    transition={{
                      type: "tween",
                      ease: "easeInOut",
                      duration: 0.2,
                    }}
                    className="professor_sidebar_create_class_button"
                    onClick={createClassHandler}
                  >
                    <Plus color={"#5090F0"} />
                    <div className="professor_sidebar_create_class_text">
                      <div className="text_blue_small_bold">
                        {t("create_class")}
                      </div>
                    </div>
                  </m.div>
                </LazyMotion>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="professor_sidebar_bottom">
            {teacherClasses.map((myClass, myClassIndex) => (
              <LazyMotion features={loadFeatures}>
                <div className="professor_sidebar_class_container">
                  <m.div
                    whileHover={{
                      scale: 1.05,
                      boxShadow: "0px 0px 10px 2px #5090F0",
                    }}
                    transition={{
                      type: "tween",
                      ease: "easeInOut",
                      duration: 0.1,
                    }}
                    key={myClass.id}
                    className="professor_sidebar_class"
                    id={myClass.id}
                    onClick={() =>
                      selectClassHandler(
                        selectedClass?.id === myClass.id ? null : myClass
                      )
                    }
                    style={{
                      color:
                        selectedClass?.id === myClass.id
                          ? "#FFFFFF"
                          : "#5090F0",
                      backgroundColor:
                        selectedClass?.id === myClass.id
                          ? "#5090F0"
                          : undefined,
                    }}
                    layout="size"
                  >
                    <div className="center_container">
                      {myClass.year} | {myClass.name}
                    </div>
                  </m.div>
                  {isAdmin && (
                    <div
                      className="professor_sidebar_class_delete"

                      // whileHover={{
                      //   scale: 1.1,
                      // }}
                      // transition={{
                      //   type: "spring",
                      //   stiffness: 400,
                      //   damping: 10,
                      // }}
                    >
                      <div className="center_container">
                        {renderOptionsButton(myClass)}
                      </div>
                    </div>
                  )}
                </div>
              </LazyMotion>
            ))}
            {loginState.id === "teacher_id" && renderBusinessButton()}
            {!_.isNull(deleteMenu) && renderDeleteMenu(deleteMenu)}
          </div>
        </div>
      </div>
      {alert !== null && (
        <Alert text={alert} handleAlert={() => alertHandler()} />
      )}
    </div>
  );
};

export default ProfessorSidebar;
